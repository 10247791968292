import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './CheckboxInput.module.scss';
import { ReactComponent as Check } from '../../../assets/check.svg';

import InputContainer, { InputContainerProps } from './InputContainer';
import { useInputId } from './useInputId';

type CheckboxInputProps = Omit<InputContainerProps, 'className' | 'children'> &
	Omit<JSX.IntrinsicElements['input'], 'className' | 'children'> & {
		label?: ReactNode;
		className?:
			| string
			| {
					wrapper?: string;
					container?: string;
					input?: string;
					label?: string;
					checkbox?: string;
			  };
		before?: ReactNode;
		after?: ReactNode;
	};

function CheckboxInput({
	id: propId,
	label,
	error,
	info,
	className: propClassName,
	before,
	after,
	hideErrorContainer,
	...inputProps
}: CheckboxInputProps) {
	const id = useInputId(propId);
	const className =
		typeof propClassName === 'string' ? { wrapper: propClassName } : propClassName;

	return (
		<InputContainer
			info={info}
			error={error}
			className={{ wrapper: className?.wrapper }}
			hideErrorContainer={hideErrorContainer}
		>
			<label htmlFor={id} className={classNames(styles.container, className?.container)}>
				{before}
				<input {...inputProps} type="checkbox" className={className?.input} id={id} />
				<Check className={classNames(styles.checkbox, className?.checkbox)} />
				<p className={className?.label}>{label}</p>
				{after}
			</label>
		</InputContainer>
	);
}

export default CheckboxInput;
