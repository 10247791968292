import cn from 'classnames';
import { useState } from 'react';
import styles from './Select.module.scss';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';

type SelectProps = {
	selectedOption?: string;
	options?: Array<string>;
	label: string;
	disabled?: boolean;
	onSelect?: (option: string, index: number) => void;
};

const Select = ({
	selectedOption = '',
	label = '',
	options,
	disabled = false,
	onSelect,
}: SelectProps) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className={cn(styles.select, { [styles.isOpen]: isOpen, [styles.disabled]: disabled })}
			onClick={() => setIsOpen(!isOpen)}
		>
			<label>{label}</label>
			<div className={styles.select__header}>
				<p>{selectedOption}</p>
				<div className={styles.arrow}>
					<Arrow />
				</div>
			</div>
			{isOpen && (
				<ul className={styles.select__options}>
					{options.map((item, i) => (
						<li key={i} onClick={() => onSelect?.(item, i)}>
							{item}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Select;
