import styles from './SuccessDelete.module.scss';
import { ReactComponent as Delete } from '../../../assets/success/delete.svg';
import { ReactComponent as Logo } from '../../../assets/logo_full.svg';
import ModalPortal from '../../common/ModalPortal';
import { ModalProps } from '../types';
import { useEffect } from 'react';

const SuccessDelete = ({ handleCloseModal }: ModalProps) => {
	useEffect(() => {
		setTimeout(() => handleCloseModal(), 6000);
	}, [handleCloseModal]);

	return (
		<ModalPortal>
			<div className={styles.successDelete}>
				<div className={styles.successDelete__wrapper}>
					<div className={styles.successDelete__header}>
						<Logo />
					</div>
					<div className={styles.successDelete__content}>
						<Delete />
						<h1 className={styles.successDelete__title}>Vous nous quittez déjà ?</h1>
						<p className={styles.successDelete__info}>
							Nous travaillons sur de nouvelles fonctionnalités et de nombreuses
							ouvertures en France. Nous espérons avoir la chance de vous accueillir à
							nouveau très prochainement !
							<span>Promis, vous ne le regretterez pas !</span>
						</p>
					</div>
					<div className={styles.successDelete__footer}>
						<p onClick={handleCloseModal}>Retour à la page principale</p>
					</div>
				</div>
				<div className={styles.successDelete__bird} />
			</div>
		</ModalPortal>
	);
};

export default SuccessDelete;
