import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useLocationToken(path: string) {
	const regex = useMemo(() => {
		return new RegExp(
			path.replace('*', '(?<token>[a-zA-Z0-9-]+)').replace('/', '\\/') + '\\/?$'
		);
	}, [path]);
	const location = useLocation();
	const store = useRef<string>();
	const parseToken = useCallback(() => {
		if (store.current) return store.current;
		const match = regex.exec(location.pathname);
		const token = match?.groups?.token;
		store.current = token;
		if (token) {
			history.replaceState({}, '', path.replace('/*', ''));
		}
		return token;
	}, [location.pathname, path, regex]);

	useEffect(() => {
		try {
			parseToken();
		} catch {
			//
		}
	}, [parseToken]);

	return parseToken;
}
