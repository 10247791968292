import { createSlice } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

const initialState = {
	loading: false,
	token: null,
	info: {
		firstName: '',
		lastName: '',
		email: '',
		id: '',
		phone: '',
		externalProfiles: [],
	},
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsFetching: state => {
			state.loading = true;
		},
		setToken: {
			prepare(token: string, meta?: { navigate?: NavigateFunction; from?: string }) {
				localStorage.setItem('token', token);
				return { payload: token, meta, error: undefined };
			},
			reducer(state, { payload }) {
				state.token = payload;
			},
		},
		setUserInfo: (state, { payload }) => {
			state.info = {
				...state.info,
				...payload,
			};
		},
		setUserEmail: (state, { payload }) => {
			state.info.email = payload;
		},
		clearUserInfo: state => {
			state.loading = false;
			state.token = null;
			state.info = {
				firstName: '',
				lastName: '',
				email: '',
				id: '',
				phone: '',
				externalProfiles: [],
			};
		},
	},
});

export const { setIsFetching, setUserInfo, setUserEmail, setToken, clearUserInfo } =
	userSlice.actions;

export default userSlice.reducer;
