import { animated, useSpring } from '@react-spring/web';
import ReactDOM from 'react-dom';
import styles from './TermsModal.module.scss';
import { ReactComponent as Arrow } from '../../../assets/back_arrow.svg';
import { ReactComponent as Logo } from '../../../assets/logo_full_colored.svg';
import Wrapper from '../Wrapper/Wrapper';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';

const TermsModal = ({ modalVisible, onClick }) => {
	const { opacity, transform, ...rest } = useSpring({
		from: { opacity: 0, transform: `translateX(100%)` },
		to: {
			opacity: modalVisible ? 1 : 0,
			transform: modalVisible ? `translateX(0%)` : `translateX(100%)`,
		},
	});

	return ReactDOM.createPortal(
		<animated.div
			style={{ ...rest, opacity: opacity, transform: transform }}
			className={styles.modal}
		>
			<Wrapper>
				<div className={styles.modal__header}>
					<div className={styles.arrow}>
						<Arrow onClick={onClick} />
					</div>
					<Link to={ROUTES.CO_WORKINGS}>
						<Logo />
					</Link>
				</div>
				<h1 className={styles.modal__title}>
					Conditions générales d’utilisation <br />
					De l’application Work&Go
				</h1>

				<div className={styles.modal__content}>
					<h3>Bienvenue chez Work&Go !</h3>
					<p>Préambule</p>
					Les présentes conditions générales d’utilisation sont conclues entre :
					<br />
					- le gérant du site internet, ci-après désigné « l’Éditeur »,
					<br />
					- toute personne souhaitant accéder au site et à ses services, ci-après appelé «
					l’Utilisateur ».
					<br />
					<p>Principes</p>
					Les présentes conditions générales d’utilisation ont pour objet l’encadrement
					juridique de l’utilisation du site workandgo et de ses services.
					<br />
					Le site Internet{' '}
					<a href="https://www.workandgo.eu/">https://www.workandgo.eu/</a> est un service
					de :
					<ul>
						<li>La société Work&Go </li>
						<li>située 40 rue Alexandre Dumas 75011 Paris , France</li>
						<li>
							adresse URL du site :{' '}
							<a href="https://reserve.workandgo.eu/">
								https://reserve.workandgo.eu/
							</a>
						</li>
						<li>
							e-mail : <a href="mailto:info@workandgo.eu">info@workandgo.eu</a>
						</li>
					</ul>
					<br />
					Les conditions générales d’utilisation doivent être acceptées par tout
					Utilisateur, et son accès au site vaut acceptation de ces conditions. Veuillez
					lire attentivement ces conditions avant d’utiliser notre site Web/application.
					<br />
					<p>Acceptation des conditions</p>
					En accédant ou en utilisant notre site Web/application, vous acceptez d’être lié
					par les présentes Conditions, notre Politique de confidentialité et toute autre
					politique ou directive pouvant être publiée sur notre site Web/application. Si
					vous n’êtes pas d’accord avec l’une de ces conditions, vous ne devez pas accéder
					ou utiliser notre site Web/application.
					<br />
					<p>Services de réservation de lieux de coworking</p>
					Notre site Web/application fournit des services de réservation de lieux de
					coworking pour les particuliers et les entreprises qui ont besoin d’espaces de
					coworking.
					<br />
					<p>Utilisation de notre site Web/application</p>
					Vous ne pouvez utiliser notre site Web/application qu’à des fins licites et
					conformément aux présentes Conditions. Vous vous engagez à ne pas utiliser notre
					site/application :
					<ul>
						<li>D’une manière qui enfreint toute loi ou réglementation applicable ;</li>
						<li>
							Dans le but d’exploiter, de nuire ou de tenter d’exploiter ou de nuire à
							des mineurs de quelque manière que ce soit en les exposant à un contenu
							inapproprié, en demandant des informations personnellement identifiables
							ou autrement ;
						</li>
						<li>
							Pour transmettre ou obtenir l’envoi de tout matériel publicitaire ou
							promotionnel, y compris tout &quot;courrier indésirable&quot;,
							&quot;chaîne de lettres&quot; ou &quot;spam&quot; ou toute autre
							sollicitation similaire ;
						</li>
						<li>
							Se faire passer pour ou tenter de se faire passer pour Work&Go, un
							employé de Work&Go, un autre utilisateur ou toute autre personne ou
							entité ;
						</li>
						<li>
							Pour s’engager dans toute autre conduite qui restreint ou empêche
							l’utilisation ou la jouissance de notre site Web/application, ou qui,
							comme nous le déterminons, peut nuire à Work&Go ou aux utilisateurs de
							notre site Web/application ou les exposer à une responsabilité ;
						</li>
					</ul>
					<br />
					<p>Comptes utilisateur</p>
					Afin d’utiliser certaines des fonctionnalités de notre site Web/application,
					vous devrez peut-être créer un compte client. Vous acceptez de fournir des
					informations exactes et complètes lorsque vous créez un compte et de mettre à
					jour les informations de votre compte si nécessaire pour qu’elles restent
					exactes et complètes. Vous êtes responsable du maintien de la confidentialité de
					votre compte et de votre mot de passe, et de la restriction de l’accès à votre
					ordinateur ou appareil mobile.
					<br />
					<p>Droits de propriété intellectuelle</p>
					Notre site Web/application et l’intégralité de son contenu, de ses
					caractéristiques et de ses fonctionnalités (y compris, mais sans s’y limiter,
					toutes les informations, logiciels, textes, affichages, images, vidéos et audio,
					ainsi que la conception, la sélection et l’agencement de ceux-ci), sont la
					propriété de Work&Go , ses concédants de licence ou d’autres fournisseurs de ce
					matériel et sont protégés par le droit d’auteur, les marques de commerce, les
					brevets, le secret commercial et d’autres lois sur la propriété intellectuelle
					ou les droits de propriété.
					<br />
					<p>Exclusion de garanties</p>
					Nous ne garantissons pas que notre site Web/application, ou tout contenu qu’il
					contient, sera toujours disponible ou ininterrompu. Nous ne serons pas
					responsables si, pour une raison quelconque, notre site Web/application est
					indisponible à tout moment ou pour toute période. Nous ne garantissons pas non
					plus que le site Web/l’application, son contenu ou tout service fourni via le
					site Web/l’application seront exacts, fiables, sans erreur ou ininterrompus.
					<br />
					<p>Limitation de responsabilité</p>
					Dans la mesure maximale autorisée par la loi applicable, en aucun cas Work&Go,
					ses sociétés affiliées ou leurs administrateurs, dirigeants, employés, agents,
					sous-traitants ou concédants respectifs ne seront responsables de tout dommage
					direct, indirect, punitif, accessoire, spécial ou consécutif. résultant de ou
					lié de quelque manière que ce soit à votre utilisation ou à votre incapacité à
					utiliser le site Web / l’application, tout site Web lié à celui-ci, tout contenu
					sur le site Web / l’application ou ces autres sites Web, y compris, mais sans
					s’y limiter, toute erreur ou omission dans tout contenu, ou toute perte ou
					dommage de quelque nature que ce soit résultant de l’utilisation du site Web/de
					l’application ou de tout contenu publié, transmis ou autrement mis à disposition
					via le site Web/l’application, même s’il est informé de leur possibilité.
					<br />
					<p>Indemnité</p>
					Vous acceptez d’indemniser, de défendre et d’exonérer Work&Go, ses sociétés
					affiliées et leurs administrateurs, dirigeants, employés, agents, sous-traitants
					et concédants respectifs de et contre toutes les réclamations, dommages,
					responsabilités, coûts et dépenses, y compris les avocats raisonnables. ’ frais
					et coûts, découlant de ou liés de quelque manière que ce soit à votre
					utilisation de notre site Web/application ou à votre violation des présentes
					Conditions.
					<br />
					<p>Modification des conditions</p>
					Nous pouvons modifier ces Conditions à tout moment sans préavis. Votre
					utilisation continue de notre site Web/application après de telles modifications
					constitue votre accord avec les conditions modifiées.
					<br />
					<p>Droit applicable et juridiction</p>
					Les présentes Conditions sont régies et interprétées conformément aux lois
					françaises, sans donner effet à aucun principe de conflit de lois. Vous acceptez
					que toute action en justice ou en équité découlant de ou liée à ces Conditions
					ne soit déposée que devant les tribunaux situés à Paris, France, et vous
					consentez et vous soumettez par la présente à la compétence personnelle de ces
					tribunaux aux fins de plaider toute telle action.
					<br />
					<p>Contactez-nous</p>
					Si vous avez des questions ou des préoccupations concernant ces Conditions,
					veuillez nous contacter à{' '}
					<a href="mailto:info@workandgo.eu">info@workandgo.eu</a>.
				</div>
			</Wrapper>
		</animated.div>,
		document.getElementById(`root`)
	);
};

export default TermsModal;
