import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../assets/back_arrow.svg';
import styles from './ReservationClose.module.scss';

import ActivityIndicator from '../../components/common/ActivityIndicator/ActivityIndicator';
import Button from '../../components/common/Button/Button';
import CheckboxInput from '../../components/common/Input/CheckboxInput';
import { ReservationDateTime } from '../../components/reservations/ReservationDateTime';
import { ReservationRoomInfo } from '../../components/reservations/ReservationRoomInfo';
import { FINISH_RESERVATION } from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { useReservationLoader } from '../../hooks/loaders/useReservationLoader';
import { useForm } from '../../hooks/useForm';
import { Method, request } from '../../network/request';
import { parseDuration } from '../../utils/formatTime';
import { checkedValidator } from '../../utils/validators';

export function ReservationClosePage() {
	const navigate = useNavigate();

	const { reservationId } = useParams();
	const { data: reservation } = useReservationLoader(reservationId);
	const duration = useMemo(() => parseDuration(reservation), [reservation]);

	const {
		values,
		handleCheckboxChange,
		handleSubmit,
		submitAction: [submitState],
		disableSubmit,
	} = useForm({
		initialValues: { careful: false, clean: false },
		validator: { careful: checkedValidator, clean: checkedValidator },
		onSubmit: async (data, signal) => {
			await request(FINISH_RESERVATION(reservation.id), { method: Method.POST, signal });
			navigate(ROUTES.RESERVATION_THANKS);
		},
	});

	return (
		<div className={styles.reservation}>
			<div className={styles.header}>
				<button onClick={() => navigate(ROUTES.RESERVATION())}>
					<Arrow />
				</button>
				<p>Finalisation de la réservation</p>
				<div className={styles.header_empty} />
			</div>
			<ReservationRoomInfo reservation={reservation} duration={duration} />
			<ReservationDateTime reservation={reservation} className={styles.dateTime} />
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.form_container}>
					<p>
						Pour réussir votre réservation, complétez et cochez la check-list ci-dessous
						:
					</p>
					<CheckboxInput
						label="J’ai fais attention à ne pas oublier d’effets personnels dans la cabine."
						name="careful"
						checked={values.careful}
						onChange={handleCheckboxChange}
					/>
					<CheckboxInput
						label="Je laisse la cabine dans un bon état de propreté et de fonctionnement."
						name="clean"
						checked={values.clean}
						onChange={handleCheckboxChange}
					/>
				</div>
				<Button
					attention
					text={
						submitState.status === 'pending' ? (
							<ActivityIndicator contrast />
						) : (
							'Completer'
						)
					}
					type="submit"
					disabled={disableSubmit}
				/>
			</form>
		</div>
	);
}
