import { Dispatch, SetStateAction } from 'react';

export enum LoginSteps {
	LOGIN = 'LOGIN',
	FORGOT_PASSWORD = 'forgot_pass',
	RESET_SUCCESS = 'reset_success',
	NEW_PASSWORD = 'new_password',
}

export type LoginProps = {
	loginStep: string;
	setLoginStep: Dispatch<SetStateAction<LoginSteps>>;
};
