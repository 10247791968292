import { ComponentType, ReactNode } from 'react';

import { Socials, UserType } from './constants';

import MainLayout from '../layouts/MainLayout/MainLayout';

import Callback from '../pages/callback/Callback';
import CoWorkingPage from '../pages/coworkings/coworking/CoWorking';
import CoWorkingRoomPage from '../pages/coworkings/coworking/room/CoWorkingRoom';
import CoWorkingsPage from '../pages/coworkings/CoWorkings';
import DeletePage from '../pages/delete/DeletePage';
import FaqPage from '../pages/faq/FaqPage';
import Login from '../pages/login/Login';
import LoginEmailPage from '../pages/loginEmail/LoginEmail';
import ProfilePage from '../pages/profile/ProfilePage';
import QRHandlerPage from '../pages/qr/QRHandlerPage';
import QrReaderPage from '../pages/qr/QrReaderPage';
import { ReservationPage } from '../pages/reservations/Reservation';
import { ReservationClosePage } from '../pages/reservations/ReservationClose';
import ReservationsPage from '../pages/reservations/Reservations';
import ReservationsThanksPage from '../pages/reservationsThanks/ReservationsThanks';
import ReservationSuccessPage from '../pages/reservationSuccess/ReservationSuccess';
import RestorePasswordPage from '../pages/restorePassword/RestorePassword';
import SignUpPage from '../pages/signUp/SignUp';
import { SignUpVerificationInfoPage } from '../pages/signUp/SignUpVerificationInfoPage';
import TermsPage from '../pages/terms/TermsPage';
import VerifyEmail from '../pages/verifyEmail/VerifyEmail';

export const ROUTES = {
	QR: (coWorkingId: string | number = ':coWorkingId', roomId: string | number = ':roomId') =>
		`/q/${coWorkingId}/${roomId}` as const,
	QR_READER: `/qr-reader` as const,

	CO_WORKINGS: '/coworkings',
	CO_WORKING: (coWorkingId: string | number = ':coWorkingId') =>
		`/coworkings/${coWorkingId}` as const,
	CO_WORKING_ROOM: (
		coWorkingId: string | number = ':coWorkingId',
		roomId: string | number = ':roomId'
	) => `/coworkings/${coWorkingId}/room/${roomId}` as const,
	CO_WORKING_BOOK_SUCCESS: (reservationId = ':reservationId') =>
		`/coworkings/book-success/${reservationId}`,

	RESERVATIONS: '/reservations',
	RESERVATION: (reservationId = ':reservationId') => `/reservations/${reservationId}` as const,
	RESERVATION_CLOSE: (reservationId = ':reservationId') =>
		`/reservations/${reservationId}/close` as const,
	RESERVATION_THANKS: '/reservations/complete-thanks',

	PROFILE: '/profile',
	TERMS: '/terms',
	FAQ: '/faq',
	DELETE: '/delete',
	LOGIN: '/login',
	SIGNUP: '/signup',
	SIGNUP_VERIFICATION: '/signup-requested',
	LOGIN_EMAIL: '/login/email',
	VERIFY_EMAIL: (token = '*') => `/verify-email/${token}`,
	RESTORE_PASSWORD: (token = '*') => `/restore-password/${token}`,
	LINKEDIN_CALLBACK_PAGE: '/auth/linkedin-callback',
	GOOGLE_CALLBACK_PAGE: '/auth/google-callback',
	FACEBOOK_CALLBACK_PAGE: '/auth/facebook-callback',

	/** @deprecated */
	RESERVATION_PAGE: (reservationId = ':reservationId') => ROUTES.RESERVATION(reservationId),
} as const;

export const routes: {
	path: string;
	name: string;
	Layout?: ComponentType<{ children: ReactNode }>;
	exact?: boolean;
	public?: boolean;
	access?: UserType[];
	component: ComponentType<Record<string, never>>;
}[] = [
	{
		path: ROUTES.QR(),
		name: ROUTES.QR(),
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: QRHandlerPage,
	},
	{
		path: ROUTES.QR_READER,
		name: ROUTES.QR_READER,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: QrReaderPage,
	},
	{
		path: ROUTES.LOGIN,
		name: ROUTES.LOGIN,
		access: [UserType.UNAUTHORISED],
		component: Login,
	},
	{
		path: ROUTES.CO_WORKING_BOOK_SUCCESS(),
		name: ROUTES.CO_WORKING_BOOK_SUCCESS(),
		exact: true,
		access: [UserType.AUTHORISED],
		component: ReservationSuccessPage,
	},
	{
		path: ROUTES.SIGNUP,
		name: ROUTES.SIGNUP,
		exact: true,
		public: true,
		access: [UserType.UNAUTHORISED],
		component: SignUpPage,
	},
	{
		path: ROUTES.SIGNUP_VERIFICATION,
		name: ROUTES.SIGNUP_VERIFICATION,
		access: [UserType.UNAUTHORISED],
		component: SignUpVerificationInfoPage,
	},
	{
		path: ROUTES.CO_WORKINGS,
		name: ROUTES.CO_WORKINGS,
		exact: true,
		Layout: MainLayout,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: CoWorkingsPage,
	},
	{
		path: ROUTES.CO_WORKING(),
		name: ROUTES.CO_WORKING(),
		Layout: MainLayout,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: CoWorkingPage,
	},
	{
		path: ROUTES.CO_WORKING_ROOM(),
		name: ROUTES.CO_WORKING_ROOM(),
		Layout: MainLayout,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: CoWorkingRoomPage,
	},
	{
		path: ROUTES.LOGIN_EMAIL,
		name: ROUTES.LOGIN_EMAIL,
		exact: true,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: LoginEmailPage,
	},
	{
		path: ROUTES.RESERVATIONS,
		name: ROUTES.RESERVATIONS,
		exact: true,
		Layout: MainLayout,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: ReservationsPage,
	},
	{
		path: ROUTES.RESERVATION(),
		name: ROUTES.RESERVATION(),
		exact: true,
		Layout: MainLayout,
		access: [UserType.AUTHORISED],
		component: ReservationPage,
	},
	{
		path: ROUTES.RESERVATION_CLOSE(),
		name: ROUTES.RESERVATION_CLOSE(),
		Layout: MainLayout,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: ReservationClosePage,
	},
	{
		path: ROUTES.RESERVATION_THANKS,
		name: ROUTES.RESERVATION_THANKS,
		access: [UserType.AUTHORISED],
		component: ReservationsThanksPage,
	},
	{
		path: ROUTES.TERMS,
		name: ROUTES.TERMS,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: TermsPage,
	},
	{
		path: ROUTES.PROFILE,
		name: ROUTES.PROFILE,
		exact: true,
		Layout: MainLayout,
		access: [UserType.AUTHORISED],
		component: ProfilePage,
	},
	{
		path: ROUTES.FAQ,
		name: ROUTES.FAQ,
		exact: true,
		Layout: MainLayout,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: FaqPage,
	},
	{
		path: ROUTES.DELETE,
		name: ROUTES.DELETE,
		exact: true,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: DeletePage,
	},
	{
		path: ROUTES.VERIFY_EMAIL(),
		name: ROUTES.VERIFY_EMAIL(),
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: VerifyEmail,
	},
	{
		path: ROUTES.RESTORE_PASSWORD(),
		name: ROUTES.RESTORE_PASSWORD(),
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: RestorePasswordPage,
	},
	{
		path: ROUTES.LINKEDIN_CALLBACK_PAGE,
		name: ROUTES.LINKEDIN_CALLBACK_PAGE,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: Callback(Socials.LINKEDIN),
	},
	{
		path: ROUTES.GOOGLE_CALLBACK_PAGE,
		name: ROUTES.GOOGLE_CALLBACK_PAGE,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: Callback(Socials.GOOGLE),
	},
	{
		path: ROUTES.FACEBOOK_CALLBACK_PAGE,
		name: ROUTES.FACEBOOK_CALLBACK_PAGE,
		access: [UserType.UNAUTHORISED, UserType.AUTHORISED],
		component: Callback(Socials.FACEBOOK),
	},
];
