import styles from './ConfirmEmail.module.scss';
import { ReactComponent as LogoSuccess } from '../../../assets/success/message.svg';
import { ReactComponent as LogoColored } from '../../../assets/logo_full_colored.svg';
import BottomModal from './BottomModal/BottomModal';
import { useState } from 'react';
import { Method, request } from '../../../network/request';
import { RESEND_EMAIL } from '../../../config/endpoints';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../redux/selectors/userSelector';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = () => {
	const { email } = useSelector(userSelector);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const navigate = useNavigate();

	const handleResendEmail = () => {
		request(RESEND_EMAIL, { method: Method.POST, data: { email } }).then(() => {
			setShowConfirmModal(true);
		});
	};

	return (
		<div className={styles.confirmEmail}>
			<div className={styles.confirmEmail__header}>
				<LogoColored />
			</div>
			<div className={styles.confirmEmail__content}>
				<LogoSuccess />
				<h1 className={styles.confirmEmail__title}>Veuillez confirmer votre e-mail</h1>
				<div className={styles.confirmEmail__wrapper}>
					<p className={styles.confirmEmail__info}>
						Pour activer votre compte, veuillez suivre les instructions envoyées sur
						<br />
						<span>{email}</span>
						<br />
					</p>
					<p className={styles.confirmEmail__info}>
						Pensez à vérifier votre spam si vous ne trouvez pas notre mail
					</p>
					<p className={styles.confirmEmail__info}>
						Toujours rien reçu ? Renvoyer un e-mail
						<br />
						<a onClick={handleResendEmail}>Envoyer à nouveau un e-mail</a>
					</p>
				</div>
				<p className={styles.confirmEmail__return} onClick={() => navigate(-1)}>
					Retour
				</p>
			</div>
			<BottomModal
				modalVisible={showConfirmModal}
				setModalVisible={() => setShowConfirmModal(false)}
			/>
		</div>
	);
};

export default ConfirmEmail;
