import { animated, useSpring } from '@react-spring/web';
import ReactDOM from 'react-dom';
import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './BottomModal.module.scss';

import { CancelButton } from './CancelButton';
import { CloseButton } from './CloseButton';

type BottomModalProps = Omit<JSX.IntrinsicElements['div'], 'ref' | 'children'> & {
	modalVisible: boolean;
	title?: ReactNode;
	children: ReactNode;
};

export function BottomModal({
	modalVisible,
	title,
	children,
	style,
	className,
	...divProps
}: BottomModalProps) {
	const { opacity, transform, ...rest } = useSpring({
		from: { opacity: 0, transform: `translateY(100%)` },
		to: {
			opacity: modalVisible ? 1 : 0,
			transform: modalVisible ? `translateY(0%)` : `translateY(100%)`,
		},
	});

	return ReactDOM.createPortal(
		<animated.div
			{...divProps}
			style={{ ...rest, opacity, transform, ...style }}
			className={classNames(styles.bottomModal, className)}
		>
			{title ? <p className={styles.title}>{title}</p> : null}
			<div className={styles.content}>{children}</div>
		</animated.div>,
		document.getElementById('root')
	);
}

BottomModal.CancelButton = CancelButton;
BottomModal.CloseButton = CloseButton;
