import styles from './RestorePassword.module.scss';
import { Dispatch, SetStateAction, useState } from 'react';
import { passwordValidator } from '../../utils/validators';
import { ReactComponent as LogoBlack } from '../../assets/logo_black.svg';
import Button from '../common/Button/Button';
import TermsModal from '../common/TermsModal/TermsModal';
import PasswordInput from '../common/Input/PasswordInput';
import ActivityIndicator from '../common/ActivityIndicator/ActivityIndicator';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config/routes';

type NewPasswordProps = {
	password: string;
	passwordError: string;
	onResetError: () => void;
	onSetNewPassword: () => void;
	setPassword: Dispatch<SetStateAction<string>>;
	loading?: boolean;
};

const RestorePassword = ({
	password,
	passwordError,
	onResetError,
	setPassword,
	onSetNewPassword,
	loading = false,
}: NewPasswordProps) => {
	const [showTermsModal, setShowTermsModal] = useState(false);

	const isValid = passwordValidator(password);

	const passwordHelpText = () => {
		if (passwordError) return passwordError;
		if (!isValid)
			return 'Veuillez respecter les exigences minimales pour le mot de passe : minimum 8 symboles, y compris des lettres minuscules et majuscules, un chiffre et un caractère spécial.';
		return '';
	};

	return (
		<>
			<div className={styles.root}>
				<Link to={ROUTES.CO_WORKINGS}>
					<LogoBlack />
				</Link>
				<form
					className={styles.form}
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
						e.preventDefault();
						onSetNewPassword();
					}}
				>
					<div className={styles.form__header}>
						<h1>Créer un nouveau mot de passe</h1>
						<p className={styles.form__info}>
							Écrivez un nouveau mot de passe différent de ceux déjà utilisés.
						</p>
					</div>
					<PasswordInput
						className={styles.form__password}
						label="Créer un mot de passe"
						placeholder="Écrivez votre mot de passe"
						autoComplete="new-password"
						name="password"
						error={passwordHelpText()}
						onBlur={onResetError}
						onChange={event => setPassword(event.target.value)}
					/>
					<div className={styles.form__submit}>
						<Button
							text={loading ? <ActivityIndicator contrast /> : 'Confirmer'}
							type="submit"
							disabled={loading || !isValid || !!passwordError}
						/>
					</div>
				</form>
				<p className={styles.conditions} onClick={() => setShowTermsModal(true)}>
					Conditions générales d`utilisation
				</p>
			</div>
			<TermsModal modalVisible={showTermsModal} onClick={() => setShowTermsModal(false)} />
		</>
	);
};

export default RestorePassword;
