import styles from './SuccessReset.module.scss';
import { ReactComponent as LogoSuccess } from '../../../assets/success/message.svg';
import { ReactComponent as Logo } from '../../../assets/logo_black.svg';
import ModalPortal from '../../common/ModalPortal';
import { ModalProps } from '../types';
import BottomModal from './BottomModal/BottomModal';
import { useState } from 'react';

const SuccessReset = ({ email, onResetPassword, handleCloseModal }: ModalProps) => {
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	return (
		<ModalPortal>
			<div className={styles.successReset}>
				<div className={styles.successReset__header}>
					<Logo />
				</div>
				<div className={styles.successReset__content}>
					<LogoSuccess />
					<h1 className={styles.successReset__title}>Vérifiez votre boîte mail</h1>
					<p className={styles.successReset__info}>
						Pour activer votre compte, veuillez-suivre les instructions envoyées sur
						<br />
						<span>{email}</span>
						<br />
						<br />
						Pensez à vérifier votre spam si vous ne trouvez pas notre mail.
						<br />
						<br />
					</p>
					<p className={styles.successReset__info}>
						Vous n`avez rien reçu ?
						<a
							onClick={() => {
								onResetPassword();
								setShowConfirmModal(true);
							}}
						>
							Envoyer à nouveau un e-mail
						</a>
					</p>
					<p className={styles.successReset__retour} onClick={handleCloseModal}>
						Retour
					</p>
				</div>
			</div>
			<BottomModal
				modalVisible={showConfirmModal}
				setModalVisible={() => setShowConfirmModal(false)}
			/>
		</ModalPortal>
	);
};

export default SuccessReset;
