import { useCallback, useEffect, useRef, useState } from 'react';

export type AsyncState<T> =
	| { status: 'empty' }
	| { status: 'pending'; promise: Promise<T>; cleanup?: () => void }
	| { status: 'resolved'; value: T; cleanup?: () => void }
	| { status: 'rejected'; error: unknown; cleanup?: () => void };

export function useAsyncState<T>(initial: AsyncState<T> = { status: 'empty' }) {
	const state = useRef<AsyncState<T>>(initial);
	const forceUpdate = useState({})[1];

	const update = useCallback(
		(next: AsyncState<T>, rerender = true) => {
			if ('cleanup' in state.current && state.current.cleanup) {
				state.current.cleanup();
			}

			state.current = next;
			if (rerender) forceUpdate({});
		},
		[forceUpdate]
	);

	useEffect(
		() => () => {
			if ('cleanup' in state.current && state.current.cleanup) {
				state.current.cleanup();
			}
		},
		[]
	);

	return [state.current, update] as const;
}
