import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FORGOT_PASSWORD } from '../../../config/endpoints';
import { useCancelableActionCallback } from '../../../hooks/useActionCallback';
import { Method, request } from '../../../network/request';
import { userSelector } from '../../../redux/selectors/userSelector';
import ChangePasswordSentModal from './ChangePasswordSentModal';

type ChangePasswordButtonProps = Omit<JSX.IntrinsicElements['a'], 'href'>;

export function ChangePasswordButton({ onClick, children, ...aProps }: ChangePasswordButtonProps) {
	const { email } = useSelector(userSelector);

	const [showChangePasswordSent, setShowChangePasswordSent] = useState(false);
	const [requestNewPassword, requestState] = useCancelableActionCallback(signal => async () => {
		await request(FORGOT_PASSWORD, { method: Method.POST, data: { email }, signal });
		setShowChangePasswordSent(true);
	});
	const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		onClick?.(event);
		if (event.isDefaultPrevented()) return;
		if (requestState.status === 'pending') return;
		requestNewPassword();
	};

	return (
		<>
			<a {...aProps} href="#" onClick={handleClick}>
				{children ?? 'Changer mon mot de passe'}
			</a>
			{showChangePasswordSent ? (
				<ChangePasswordSentModal
					onResetPassword={requestNewPassword}
					handleCloseModal={() => setShowChangePasswordSent(false)}
				/>
			) : null}
		</>
	);
}
