import { PayloadAction, createSlice, createAction } from '@reduxjs/toolkit';
import { IReservation, IPaginatedResponse, IPaginationState } from '../../network/types';
import { Tabs } from '../../network/types';
import { storeEntities, storePage } from './utils';

type ReservationsState = {
	items: Record<string, IReservation>;
	pagination: { [T in Tabs]?: IPaginationState<string> };
};

const initialState: ReservationsState = {
	items: {},
	pagination: {},
};

export const handleReservationsPage = createAction(
	'reservations/handleReservationsPage',
	(tab: Tabs, response: IPaginatedResponse<IReservation>) => {
		return { payload: response, meta: { tab }, error: null };
	}
);

const reservationsSlice = createSlice({
	name: 'reservations',
	initialState,
	reducers: {
		clearReservations: state => {
			state.items = initialState.items;
		},
		handleReservation: (state, { payload }: PayloadAction<IReservation>) => {
			state.items[payload.id] = payload;
		},
		updateReservation: {
			prepare(id: string, updates: Partial<Omit<IReservation, 'id'>>) {
				return { payload: { id, updates }, meta: undefined, error: undefined };
			},
			reducer(state, { payload: { id, updates } }) {
				state.items[id] = { ...state.items[id], ...updates };
			},
		},
	},
	extraReducers: builder =>
		builder.addCase(handleReservationsPage, (state, { payload, meta: { tab } }) => {
			state.items = storeEntities(payload.data, state.items);
			state.pagination[tab] = storePage(
				state.pagination[tab],
				payload.metadata,
				i => payload.data[i].id
			);
		}),
});

export const { clearReservations, handleReservation, updateReservation } =
	reservationsSlice.actions;

export const transformReservationsIn = (state: ReservationsState) => {
	const reservations: Record<string, IReservation> = {};
	for (const id in state.items) {
		reservations[id] = {
			...state.items[id],
			user: { ...state.items[id].user },
			coworking: { ...state.items[id].coworking },
			room: { ...state.items[id].room, photos: undefined },
		};
	}
	return { ...state, items: reservations, pagination: {} };
};

export default reservationsSlice.reducer;
