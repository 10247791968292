import { animated, useSpring } from '@react-spring/web';
import ReactDOM from 'react-dom';
import styles from './BottomModal.module.scss';
import Button from '../../common/Button/Button';

const BottomModal = ({ modalVisible, onDiscardChanges, onSaveChanges }) => {
	const { opacity, transform, ...rest } = useSpring({
		from: { opacity: 0, transform: `translateY(100%)` },
		to: {
			opacity: modalVisible ? 1 : 0,
			transform: modalVisible ? `translateY(0%)` : `translateY(100%)`,
		},
	});

	return ReactDOM.createPortal(
		<animated.div
			style={{ ...rest, opacity: opacity, transform: transform }}
			className={styles.modal}
		>
			<p className={styles.modal__title}>Voulez-vous enregistrer vos modifications ?</p>
			<Button text="sauvegarder" onClick={onSaveChanges} />
			<a
				className={styles.modal__cancel}
				onClick={() => {
					onDiscardChanges();
				}}
			>
				Annuler
			</a>
		</animated.div>,
		document.getElementById('root')
	);
};

export default BottomModal;
