import { Moment } from 'moment';

export type ICoWorkingRoom = {
	id: number;
	title: string;
	description: string;
	isActive: boolean;
	capacity: number;
	price: number;
	services: string[];
	photos?: string[];
};

export interface TimeRange {
	from: string;
	to: string;
}

export interface MinutesTimeRange {
	from: number;
	to: number;
}

export interface MomentsTimeRange {
	from: Moment;
	to: Moment;
}

export interface TimeDuration {
	hours: number;
	minutes: number;
}

export type ICoWorking = {
	id: number;
	title: string;
	description: string;
	address: string;
	addressLink?: string;
	workHours: TimeRange;
	rooms: ICoWorkingRoom[];
	photos?: string[];
	openspace?: ICoWorkingRoom;
};

export type IUser = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
};

export type IReservation = {
	id: string;
	createdAt: string;
	from: string;
	to: string;
	status: 'booked' | 'payed' | 'sessionStarted' | 'sessionEnded' | 'done' | 'canceled';
	sessionStartedAt: string | null;
	sessionEndedAt: string | null;
	price: number;
	user: IUser;
	room: ICoWorkingRoom;
	coworking: IReservationCoWorking;
};
export type IReservationCoWorking = {
	id: number;
	title: string;
	description: string;
	address: string;
	addressLink?: string;
	workHours: TimeRange;
};

export type IPaginationParams = { limit?: number; skip?: number };
export type IPagination = {
	skip: number;
	limit: number;
	count: number;
	totalCount: number;
};
export type IPaginationState<T extends number | string> = IPagination & {
	ids: T[];
};
export type IPaginatedResponse<T> = {
	data: T[];
	metadata: IPagination;
};
export type IPaginatedState<T extends { id: number | string }> =
	| { items: T[]; pagination: IPaginationState<T['id']> }
	| undefined;

export enum Tabs {
	ACTIVE = 'active',
	PASSED = 'passed',
}
