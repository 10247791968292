import { RESERVATION } from '../../config/endpoints';
import { Method } from '../../network/request';
import { reservationSelector } from '../../redux/selectors/reservationsSelector';
import { handleReservation } from '../../redux/slice/reservationsSlice';
import { useDataLoader } from '../useDataLoader';

export function useReservationLoader(reservationId: string) {
	return useDataLoader(
		RESERVATION(reservationId),
		state => reservationSelector(state, reservationId),
		{ url: RESERVATION(reservationId), method: Method.GET },
		({ data }) => handleReservation(data)
	);
}
