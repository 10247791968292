import { Middleware } from '@reduxjs/toolkit';
import { setToken, setUserInfo } from '../slice/userSlice';
import { Method, request } from '../../network/request';
import { USER_INFO } from '../../config/endpoints';

export const userLoaderMiddleware: Middleware = store => next => async action => {
	if (!setToken.match(action)) {
		return next(action);
	}

	const result = next(action);

	try {
		const response = await request(USER_INFO, { method: Method.GET });
		store.dispatch(setUserInfo(response.data));

		return result;
	} catch (error) {
		return result;
	}
};
