import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../../redux/slice/userSlice';
import Input from '../../common/Input/Input';
import BottomModal from '../BottomModal/BottomModal';
import styles from './Profile.module.scss';
import { logOut } from '../../../utils/logOut';
import { ROUTES } from '../../../config/routes';
import { useNavigate } from 'react-router-dom';
import { userSelector } from '../../../redux/selectors/userSelector';
import { Method, request } from '../../../network/request';
import { LOG_OUT, USER_EMAIL_CHANGE, USER_INFO } from '../../../config/endpoints';
import ConnectSocilas from '../../common/ConnectSocilas/ConnectSocilas';
import { emailValidator, nameValidator, phoneValidator } from '../../../utils/validators';
import Header from '../../common/Header/Header';
import ActivityIndicator from '../../common/ActivityIndicator/ActivityIndicator';
import { ChangePasswordButton } from '../../modals/ChangePasswordSentModal/ChangePasswordButton';

const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const info = useSelector(userSelector);
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [userData, setUserData] = useState(info);

	useEffect(() => {
		if (!info.id) {
			setLoading(true);
			request(USER_INFO, { method: Method.GET })
				.then(res => {
					dispatch(setUserInfo(res.data));
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [dispatch, info.id]);

	useEffect(() => {
		setUserData(info);
	}, [info]);

	const isEmailChanged = info.email !== userData.email;

	const handleSaveChanges = () => {
		if (isEmailChanged) {
			const updatedEmail = { email: userData.email };

			return request(USER_EMAIL_CHANGE, {
				method: Method.POST,
				data: updatedEmail,
			}).then(() => {
				dispatch(setUserInfo(updatedEmail));
				setModalVisible(false);
				navigate(ROUTES.SIGNUP_VERIFICATION);
			});
		}
		const updatedData = {
			firstName: userData.firstName,
			lastName: userData.lastName,
			phone: userData.phone,
		};
		request(USER_INFO, {
			method: Method.PATCH,
			data: updatedData,
		}).then(() => {
			dispatch(setUserInfo(updatedData));
			setModalVisible(false);
		});
	};

	const isEmailValid = emailValidator(userData.email);
	const isFirstNameError = !nameValidator(userData.firstName) || userData.firstName.length === 0;
	const isLastNameError = !nameValidator(userData.lastName) || userData.lastName.length === 0;

	const handleCheckChanges = () => {
		if (isFirstNameError || isLastNameError) return;
		setModalVisible(isEmailValid && JSON.stringify(info) !== JSON.stringify(userData));
	};

	const handleLogOut = () => {
		request(LOG_OUT, {
			method: Method.GET,
		}).finally(() => {
			logOut();
			window.location.reload();
		});
	};

	return (
		<div className={styles.profile}>
			<Header />
			<h1 className={styles.profile__title}>Informations personnelles</h1>
			<div className={styles.profile__wrapper}>
				{loading ? (
					<ActivityIndicator absolute />
				) : (
					<>
						<div className={styles.profile__form}>
							<Input
								type="text"
								autoComplete="given-name"
								label="Prénom"
								placeholder="Prénom"
								className={styles.profile__input}
								name="firstName"
								value={userData.firstName}
								onChange={event =>
									setUserData(current => ({
										...current,
										firstName: event.target.value,
									}))
								}
								onBlur={!isFirstNameError ? handleCheckChanges : undefined}
								error={isFirstNameError && 'Merci de remplir les champs demandés'}
							/>
							<Input
								type="text"
								autoComplete="family-name"
								label="Nom"
								placeholder="Nom"
								className={styles.profile__input}
								name="lastName"
								value={userData.lastName}
								onChange={event =>
									setUserData(current => ({
										...current,
										lastName: event.target.value,
									}))
								}
								onBlur={!isLastNameError ? handleCheckChanges : undefined}
								error={isLastNameError && 'Merci de remplir les champs demandés'}
							/>
							<Input
								type="text"
								autoComplete="tel"
								label="Numéro de téléphone"
								placeholder="Votre numéro de téléphone"
								className={styles.profile__input}
								name="phone"
								value={userData.phone}
								onChange={event => {
									const phone = event.target.value;
									if (!phoneValidator(phone)) return;
									setUserData(current => ({ ...current, phone }));
								}}
								onBlur={handleCheckChanges}
							/>
							<div className={styles.profile__divider} />
							<Input
								className={styles.profile__input}
								label="E-mail"
								type="email"
								autoComplete="email"
								placeholder="examplemail@gmail.com"
								error={!isEmailValid}
								value={userData.email}
								onChange={event =>
									setUserData(current => ({
										...current,
										email: event.target.value.toLowerCase(),
									}))
								}
								onBlur={handleCheckChanges}
							/>
						</div>
						<div className={styles.profile__actions}>
							<div className={styles.profile__socials}>
								<p className={styles.profile__socials_title}>
									Votre profil est synchronisé avec les réseaux sociaux suivants
								</p>
								<div className={styles.profile__socials_buttons}>
									<ConnectSocilas />
								</div>
							</div>
							<p className={styles.info}>Fonctions supplémentaires</p>
							<ChangePasswordButton />
							<a className={styles.logout} onClick={handleLogOut}>
								Me déconnecter
							</a>
						</div>
					</>
				)}
			</div>

			<BottomModal
				modalVisible={modalVisible}
				onDiscardChanges={() => {
					setUserData(info);
					setModalVisible(false);
				}}
				onSaveChanges={handleSaveChanges}
			/>
		</div>
	);
};

export default Profile;
