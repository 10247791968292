import cn from 'classnames';
import styles from './ButtonTab.module.scss';

type ButtonTabProps = {
	text: string;
	active?: boolean;
	type?: 'button' | 'submit' | 'reset';
	onClick?: () => void;
};

const ButtonTab = ({ text = '', type = 'button', active = false, onClick }: ButtonTabProps) => {
	return (
		<button
			type={type}
			onClick={onClick}
			className={cn(styles.button, {
				[styles.active]: active,
			})}
		>
			{text}
		</button>
	);
};

export default ButtonTab;
