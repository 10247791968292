import { ForwardedRef, useCallback, useRef } from 'react';

export function useForwardedRef<T>(forwardedRef: ForwardedRef<T | null>) {
	const innerRef = useRef<T>(null);
	const onRef = useCallback(
		(value: T | null) => {
			innerRef.current = value;
			if (forwardedRef) {
				if (typeof forwardedRef === 'function') {
					forwardedRef(value);
				} else {
					forwardedRef.current = value;
				}
			}
		},
		[forwardedRef]
	);

	return [innerRef, onRef] as const;
}
