import { ComponentProps, ReactNode } from 'react';
import Button from './Button';
import { Action, ActionProps } from '../Action/Action';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import { AsyncState } from '../../../hooks/useAsyncState';

type ActionButtonProps<T> = Omit<ComponentProps<typeof Button>, 'text'> &
	Omit<ActionProps<T, React.MouseEvent<HTMLButtonElement, MouseEvent>>, 'children'> & {
		text: ReactNode | ((state: AsyncState<T>) => ReactNode);
	};

export function ActionButton<T>({
	action,
	onClick,
	onResolved,
	onRejected,
	text: propText,
	disabled = false,
	...buttonProps
}: ActionButtonProps<T>) {
	return (
		<Action {...{ action, onClick, onResolved, onRejected }}>
			{(state, handleClick) => {
				const loading = state.status === 'pending';

				let text: ReactNode;
				if (typeof propText === 'function') {
					text = propText(state);
				} else if (loading) {
					text = <ActivityIndicator contrast />;
				} else {
					text = propText;
				}

				return (
					<Button
						{...buttonProps}
						onClick={handleClick}
						disabled={disabled || loading}
						text={text}
					/>
				);
			}}
		</Action>
	);
}
