import styles from './LoginEmail.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import LoginEmail from '../../components/loginEmail/LoginEmail';
import LoginTabs from '../../components/common/LoginTabs/LoginTabs';

const LoginEmailPage = () => {
	const navigate = useNavigate();

	return (
		<>
			<div className={styles.main}>
				<LoginTabs />
				<LoginEmail />
				<p className={styles.conditions} onClick={() => navigate(ROUTES.TERMS)}>
					Conditions générales d`utilisation
				</p>
			</div>
		</>
	);
};

export default LoginEmailPage;
