import { useNavigate } from 'react-router-dom';

import { ReactComponent as QrCode } from '../../../assets/qr_code.svg';

import styles from './QrReaderButton.module.scss';
import { ROUTES } from '../../../config/routes';

const QrReaderButton = () => {
	const navigate = useNavigate();
	const handlePress = async () => {
		const stream = await navigator.mediaDevices.getUserMedia({ video: true });
		stream.getTracks().forEach(track => track.stop());
		navigate(ROUTES.QR_READER);
	};

	return (
		<button className={styles.button} onClick={handlePress}>
			<QrCode />
		</button>
	);
};

export default QrReaderButton;
