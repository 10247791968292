import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../../assets/back_arrow.svg';
import { ReactComponent as Light } from '../../../assets/spaces/light.svg';
import styles from './CoWorking.module.scss';

import { formatWorkingHours } from '../../../utils/formatTime';
import PhotosCarousel from '../../../components/common/PhotosCarousel/PhotosCarousel';
import AddressLink from '../../../components/common/AddressLink/AddressLink';
import { formatPlaces } from '../../../utils/formatters';
import { ROUTES } from '../../../config/routes';
import { useCoWorkingLoader } from '../../../hooks/loaders/useCoWorkingLoader';
import { Image } from '../../../components/common/Image/Image';

const colors = [styles.yellow, styles.green, styles.blue, styles.red];

function CoWorkingPage() {
	const { coWorkingId } = useParams();
	const { data: coWorking } = useCoWorkingLoader(coWorkingId);

	const navigate = useNavigate();

	const services = useMemo(() => {
		const services = new Set<string>();
		coWorking.openspace?.services.forEach(service => services.add(service));
		coWorking.rooms.forEach(room => room.services.forEach(service => services.add(service)));

		return Array.from(services).sort();
	}, [coWorking]);

	// FIXME: use react-markdown in future, maybe
	const richDescription = useMemo(() => {
		const ps = coWorking.description.split('\n\n').map((text, index) => ({
			key: `${text.substring(0, 10)}-${index}`,
			text,
		}));
		return ps.map(({ key, text }) => <p key={key}>{text}</p>);
	}, [coWorking.description]);

	return (
		<div className={styles.container}>
			<button className={styles.arrow}>
				<Arrow onClick={() => navigate(ROUTES.CO_WORKINGS)} />
			</button>
			<div className={styles.wrapper}>
				<PhotosCarousel
					photos={[
						coWorking.photos ?? [],
						coWorking.openspace?.photos ?? [],
						...coWorking.rooms.map(r => r.photos ?? []),
					].flat()}
					height="318px"
					className={styles.carousel}
				/>
				<div className={styles.content}>
					<div className={styles.header}>
						<p className={styles.name}>{coWorking.title}</p>
						<AddressLink
							className={styles.address}
							coWorkingId={coWorking.id}
							link={coWorking.addressLink}
							address={coWorking.address}
						/>
						<div className={styles.hours}>
							<div className={styles.hours__item}>
								<Light />
								<span>Horaires d&apos;ouverture: </span>
								{formatWorkingHours(coWorking.workHours)}
							</div>
						</div>
					</div>
					<div className={styles.available}>
						<h2>Espaces disponibles</h2>
						<ul className={styles.spaces}>
							{coWorking.openspace ? (
								<li className={styles.space}>
									<Image
										className={styles.space__image}
										src={coWorking.openspace.photos?.[0]}
									/>
									<div className={styles.space__info}>
										<p className={styles.space__info_title}>
											{coWorking.openspace.title},{' '}
											{formatPlaces(coWorking.openspace.capacity)}
										</p>
										<p className={styles.space__info_reservation}>
											<span>Gratuit</span> sans réservation
										</p>
									</div>
								</li>
							) : null}
							{coWorking.rooms.map(room => (
								<li key={room.id} className={styles.space}>
									<Image className={styles.space__image} src={room.photos?.[0]} />
									<div className={styles.space__info}>
										<p className={styles.space__info_title}>
											{room.title} pour {room.capacity} personnes
										</p>
										{room.price > 0 ? (
											<p className={styles.space__info_reservation}>
												<span>Besoin</span> de réservation
											</p>
										) : (
											<p className={styles.space__info_reservation}>
												<span>Gratuit</span> avec ou sans réservation
											</p>
										)}
									</div>
									<div className={styles.space__select}>
										<button
											onClick={() =>
												navigate(
													ROUTES.CO_WORKING_ROOM(coWorkingId, room.id)
												)
											}
										>
											Сhoisir
										</button>
									</div>
								</li>
							))}
						</ul>
					</div>
					<div className={styles.description}>
						<h2>Description</h2>
						{richDescription}
					</div>
					<div className={styles.services}>
						<h2>Services</h2>
						<div className={styles.chips}>
							{services.map((service, index) => (
								<div key={service} className={colors[index % colors.length]}>
									{service}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CoWorkingPage;
