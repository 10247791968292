import cn from 'classnames';
import styles from './BottomMenu.module.scss';
import { useLocation, NavLink } from 'react-router-dom';
import { ReactComponent as SpacesIcon } from '../../../assets/bottomMenu/spaces.svg';
import { ReactComponent as ReservationIcon } from '../../../assets/bottomMenu/reservation.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/bottomMenu/profile.svg';
import { ReactComponent as FaqIcon } from '../../../assets/bottomMenu/faq.svg';
import { ReactComponent as SpacesIconActive } from '../../../assets/bottomMenu/spaces_active.svg';
import { ReactComponent as ReservationIconActive } from '../../../assets/bottomMenu/reservation_active.svg';
import { ReactComponent as ProfileIconActive } from '../../../assets/bottomMenu/profile_active.svg';
import { ReactComponent as FaqIconActive } from '../../../assets/bottomMenu/faq_active.svg';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../../redux/selectors/userSelector';
import { reservationsOnlyOneSelector } from '../../../redux/selectors/reservationsSelector';
import { ROUTES } from '../../../config/routes';

const BOTTOM_MENU = (isSingleReservation: boolean) => [
	{
		name: 'Espaces',
		nameUserAuthenticated: 'Espaces',
		path: ROUTES.CO_WORKINGS,
		icon: <SpacesIcon />,
		iconActive: <SpacesIconActive />,
	},
	{
		name: 'Réservation',
		nameUserAuthenticated: isSingleReservation ? 'Réservation' : 'Réservations',
		path: '/reservations',
		icon: <ReservationIcon />,
		iconActive: <ReservationIconActive />,
	},
	{
		name: 'Profil',
		nameUserAuthenticated: 'Mon profil',
		path: '/profile',
		icon: <ProfileIcon />,
		iconActive: <ProfileIconActive />,
	},
	{
		name: 'Aide',
		nameUserAuthenticated: 'Aide',
		path: '/faq',
		icon: <FaqIcon />,
		iconActive: <FaqIconActive />,
	},
];

const BottomMenu = ({ className, ...ulProps }: JSX.IntrinsicElements['ul']) => {
	const token = useSelector(tokenSelector);
	const isAuthenticated = token && token.length !== 0;
	const isSingleReservation = useSelector(reservationsOnlyOneSelector);
	const { pathname } = useLocation();

	return (
		<ul {...ulProps} className={cn(styles.menu, className)}>
			{BOTTOM_MENU(isSingleReservation).map(item => {
				const isActive = pathname.startsWith(item.path);

				return (
					<NavLink key={item.name} to={item.path}>
						<li className={styles.menu__item}>
							<div className={styles.menu__icon}>
								{isActive ? item.iconActive : item.icon}
							</div>
							<p className={cn(styles.menu__name, { [styles.active]: isActive })}>
								{isAuthenticated ? item.nameUserAuthenticated : item.name}
							</p>
						</li>
					</NavLink>
				);
			})}
		</ul>
	);
};

export default BottomMenu;
