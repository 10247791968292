import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Profile from '../../components/profile/Profile/Profile';
import { ROUTES } from '../../config/routes';
import { tokenSelector } from '../../redux/selectors/userSelector';

const ProfilePage = () => {
	const token = useSelector(tokenSelector);
	const isAuthenticated = token && token.length !== 0;
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) navigate(ROUTES.LOGIN);
	}, [isAuthenticated]);

	return <Profile />;
};

export default ProfilePage;
