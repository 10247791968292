import { RootState } from '../store';

export const coWorkingsPageSelector = (state: RootState) => {
	if (!state.coWorkings.pagination) return undefined;
	const items = state.coWorkings.pagination?.ids.map(id => state.coWorkings.items[id]);
	return { items, pagination: state.coWorkings.pagination };
};

export const coWorkingSelector = (state: RootState, id: string | number) => {
	return state.coWorkings.items[id];
};
