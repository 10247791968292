import { ReactNode, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

type RealTimeProps = {
	interval?: number;
	children: (now: Moment) => ReactNode;
};

export function RealTime({ interval = 500, children }: RealTimeProps) {
	const [now, setNow] = useState<Moment>(moment);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setNow(moment());
		}, interval);
		return () => {
			clearInterval(intervalId);
		};
	}, [interval]);

	return <>{children(now)}</>;
}
