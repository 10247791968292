import { COWORKING } from '../../config/endpoints';
import { Method } from '../../network/request';
import { coWorkingSelector } from '../../redux/selectors/coWorkingsSelector';
import { handleCoWorking } from '../../redux/slice/coWorkingsSlice';
import { useDataLoader } from '../useDataLoader';

export function useCoWorkingLoader(coWorkingId: string) {
	return useDataLoader(
		COWORKING(coWorkingId),
		state => coWorkingSelector(state, coWorkingId),
		{ url: COWORKING(coWorkingId), method: Method.GET },
		handleCoWorking
	);
}
