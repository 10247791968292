import styles from './LoginEmail.module.scss';
import Button from '../common/Button/Button';
import Input from '../common/Input/Input';
import { useEffect, useState } from 'react';
import { Method, request } from '../../network/request';
import { FACEBOOK_LOGIN } from '../../config/endpoints';
import { emailValidator } from '../../utils/validators';
import SuccessReset from '../modals/SuccessReset/SuccessReset';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';

const LoginEmail = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [token, setToken] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [loginFormData, setLoginFormData] = useState('');

	const [emailError, setEmailError] = useState(false);

	useEffect(() => {
		setToken(localStorage.getItem('fbToken'));
		localStorage.removeItem('fbToken');
	}, []);

	const checkIsEmailValid = () => {
		setEmailError(!emailValidator(loginFormData));
	};

	const handleConfirmEmail = () => {
		request(FACEBOOK_LOGIN, { method: Method.POST, data: { token, email: loginFormData } })
			.then(() => {
				setShowSuccessModal(true);
			})
			.catch(() => {
				let from: string | undefined = location.state?.from;
				from = typeof from === 'string' ? from : undefined;
				navigate(ROUTES.LOGIN, { state: { from } });
			});
	};

	return (
		<div className={styles.root}>
			<div className={styles.login}>
				<div className={styles.login__header}>
					<h1>Renseignez votre e-mail pour terminer l`inscription</h1>
				</div>
				<Input
					type="email"
					autoComplete="email"
					label="E-mail"
					placeholder="Votre e-mail"
					className={styles.login__email}
					name="email"
					value={loginFormData}
					onChange={event => setLoginFormData(event.target.value.toLowerCase())}
					onBlur={checkIsEmailValid}
					error={emailError && 'Merci de remplir les champs demandés'}
				/>
				<div className={styles.login__submit}>
					<Button type="button" text="Envoyer une lettre" onClick={handleConfirmEmail} />
				</div>
			</div>
			{showSuccessModal && (
				<SuccessReset
					email={loginFormData}
					handleCloseModal={() => setShowSuccessModal(false)}
					onConfirmEmail={handleConfirmEmail}
				/>
			)}
		</div>
	);
};

export default LoginEmail;
