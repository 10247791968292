import styles from './FailedRegister.module.scss';
import { ReactComponent as LogoFailed } from '../../../assets/success/message_yellow.svg';
import { ReactComponent as LogoColored } from '../../../assets/logo_full_colored.svg';
import ModalPortal from '../../common/ModalPortal';
import { useState } from 'react';
import { Method, request } from '../../../network/request';
import { RESEND_EMAIL } from '../../../config/endpoints';
import BottomModal from './BottomModal/BottomModal';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../redux/selectors/userSelector';

const FailedRegister = () => {
	const { email } = useSelector(userSelector);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const handleResendEmail = () => {
		request(RESEND_EMAIL, { method: Method.POST, data: { email } }).then(() => {
			setShowConfirmModal(true);
		});
	};

	return (
		<ModalPortal>
			<div className={styles.failedRegister}>
				<div className={styles.failedRegister__header}>
					<LogoColored />
				</div>
				<div className={styles.failedRegister__content}>
					<LogoFailed />
					<h1 className={styles.failedRegister__title}>Ce lien a expiré</h1>
					<p className={styles.failedRegister__info}>
						Merci de cliquer sur le bouton ci-dessous pour générer un nouveau lien de
						vérification.
					</p>
				</div>
				<p className={styles.failedRegister__footer}>
					<a onClick={handleResendEmail}>Envoyer à nouveau un e-mail</a>
				</p>
			</div>
			<BottomModal
				modalVisible={showConfirmModal}
				setModalVisible={() => setShowConfirmModal(false)}
			/>
		</ModalPortal>
	);
};

export default FailedRegister;
