import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Delete from '../../components/delete/Delete/Delete';
import SuccessDelete from '../../components/modals/SuccessDelete/SuccessDelete';
import { USER_INFO } from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { request, Method } from '../../network/request';
import { userSelector } from '../../redux/selectors/userSelector';
import { logOut } from '../../utils/logOut';

const DeletePage = () => {
	const info = useSelector(userSelector);
	const navigate = useNavigate();

	const [confirmEmail, setConfirmEmail] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const handleCompareEmail = () => setEmailError(info.email !== confirmEmail);

	const handleCloseModal = () => {
		setShowSuccessModal(false);
		navigate(ROUTES.CO_WORKINGS);
	};

	const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value.toLowerCase();
		setConfirmEmail(value);
	};

	const confirmDelete = async (signal: AbortSignal) => {
		await request(USER_INFO, { method: Method.DELETE, signal });
		setShowSuccessModal(true);
		logOut();
	};

	return (
		<>
			<Delete
				confirmEmail={confirmEmail}
				emailError={emailError}
				onCompareEmail={handleCompareEmail}
				onChangeEmail={onChangeEmail}
				confirmDelete={confirmDelete}
			/>
			{showSuccessModal && <SuccessDelete handleCloseModal={handleCloseModal} />}
		</>
	);
};

export default DeletePage;
