import styles from './SignUp.module.scss';
import SignUp from '../../components/signUp/SignUp';
import { useState } from 'react';
import LoginTabs from '../../components/common/LoginTabs/LoginTabs';
import { animated, useSpring } from '@react-spring/web';
import TermsModal from '../../components/common/TermsModal/TermsModal';
import PrivacyModal from '../../components/common/PrivacyModal/PrivacyModal';

const SignUpPage = () => {
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [showPrivacyModal, setShowPrivacyModal] = useState(false);

	const { opacity, transform } = useSpring({
		config: { duration: 300 },
		from: { opacity: 0, transform: 'rotateX(10deg)' },
		to: {
			opacity: 1,
			transform: 'rotateX(0)',
		},
	});

	return (
		<>
			<div className={styles.main}>
				<LoginTabs />
				<animated.div style={{ opacity: opacity, transform: transform }}>
					<SignUp
						setShowTermsModal={setShowTermsModal}
						setShowPrivacyModal={setShowPrivacyModal}
					/>
				</animated.div>
			</div>
			<TermsModal modalVisible={showTermsModal} onClick={() => setShowTermsModal(false)} />
			<PrivacyModal
				modalVisible={showPrivacyModal}
				onClick={() => setShowPrivacyModal(false)}
			/>
		</>
	);
};

export default SignUpPage;
