import { tokenSelector } from '../redux/selectors/userSelector';
import store from '../redux/store';

export const getAuthToken = () => {
	const token = tokenSelector(store.getState());
	if (token && token.length !== 0) {
		return token;
	}
	return undefined;
};
