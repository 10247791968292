import { atcb_action } from 'add-to-calendar-button';
import classNames from 'classnames';
import moment from 'moment';
import { ComponentProps, useCallback } from 'react';

import styles from './ReservationCalendarButton.module.scss';
import { ReactComponent as Calendar } from '../../../assets/reservations/calendar-big.svg';

import { IReservation } from '../../../network/types';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

type ReservationCalendarButtonProps = Omit<ComponentProps<typeof ButtonIcon>, 'text' | 'icon'> &
	Partial<Pick<ComponentProps<typeof ButtonIcon>, 'text' | 'icon'>> & {
		reservation: IReservation;
		contrast?: boolean;
	};

export function ReservationCalendarButton({
	reservation,
	className,
	icon,
	text,
	contrast,
	onClick,
	...buttonProps
}: ReservationCalendarButtonProps) {
	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			onClick?.(event);
			if (event.isDefaultPrevented()) return;
			const from = moment(reservation.from);
			const to = moment(reservation.to);
			atcb_action(
				{
					name: `Reservation at ${reservation.coworking.title}`,
					options: ['Apple', 'Google', 'iCal'],
					timeZone: 'currentBrowser',
					startDate: from.format('YYYY-MM-DD'),
					startTime: from.format('HH:mm'),
					endDate: to.format('YYYY-MM-DD'),
					endTime: to.format('HH:mm'),
					location: reservation.coworking.address,
					availability: 'free',
					created: moment(reservation.createdAt).format('YYYYMMDDTHHMMSS\\Z'),
					status: 'CONFIRMED',
				},
				event.currentTarget
			);
		},
		[onClick, reservation]
	);

	return (
		<div className={classNames(styles.container, className)}>
			<ButtonIcon
				{...buttonProps}
				className={contrast ? styles.contrast : styles.normal}
				onClick={handleClick}
				icon={icon ?? <Calendar />}
				text={text ?? 'Ajouter un événement de calendrier'}
			/>
		</div>
	);
}
