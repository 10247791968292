import { useCallback } from 'react';

import { RESERVATIONS_LIST } from '../../config/endpoints';
import { Method } from '../../network/request';
import { Tabs } from '../../network/types';
import { reservationsPageSelector } from '../../redux/selectors/reservationsSelector';
import { handleReservationsPage } from '../../redux/slice/reservationsSlice';
import { usePaginatedDataLoader } from '../usePaginatedDataLoader';

export function useReservationsLoader(activeTab: Tabs, pageSize?: number) {
	return usePaginatedDataLoader(
		`${RESERVATIONS_LIST}?tab=${activeTab}`,
		state => reservationsPageSelector(state, activeTab),
		{
			url: RESERVATIONS_LIST,
			method: Method.GET,
			params: { type: activeTab, limit: pageSize },
		},
		useCallback(response => handleReservationsPage(activeTab, response), [activeTab]),
		false
	);
}
