import { useSelector } from 'react-redux';

import styles from './ReservationsList.module.scss';

import { useReservationsLoader } from '../../hooks/loaders/useReservationsLoader';
import { Tabs } from '../../network/types';
import { tokenSelector } from '../../redux/selectors/userSelector';
import { DataScrollView } from '../DataScrollView/DataScrollView';
import { FetchMoreIndicator } from '../DataScrollView/FetchMoreIndicator';
import ReservationItem from './ReservationItem';
import { ReservationsEmpty } from './ReservationsEmpty';

type ReservationsListProps = {
	activeTab: Tabs;
};

export function ReservationsList({ activeTab }: ReservationsListProps) {
	const isAuthenticated = !!useSelector(tokenSelector);
	const { data, canFetchMore, onFetchMore } = useReservationsLoader(activeTab, 2);

	if (data?.items.length === 0 || !isAuthenticated) {
		return <ReservationsEmpty />;
	}

	return (
		<DataScrollView
			className={styles.reservationsList}
			canFetchMore={canFetchMore}
			onFetchMore={onFetchMore}
			fetchIndicator={<FetchMoreIndicator />}
		>
			{data?.items.map(reservation => (
				<ReservationItem
					key={reservation.id}
					className={styles.item}
					reservation={reservation}
					archived={activeTab === Tabs.PASSED}
				/>
			))}
		</DataScrollView>
	);
}
