import { ComponentProps, ReactNode } from 'react';

import { BottomModal } from '../BottomModal/BottomModal';
import Button from '../Button/Button';

type ConfirmationModalProps = Omit<ComponentProps<typeof BottomModal>, 'children'> & {
	children?: ReactNode;
	confirmButton?: (props: { onClick?: () => void }) => ReactNode;
	onConfirm?: () => void;
	cancelButton?: (props: { onClick?: () => void }) => ReactNode;
	onCancel?: () => void;
};

export function ConfirmationModal({
	confirmButton,
	onConfirm,
	cancelButton,
	onCancel,
	children,
	...modalProps
}: ConfirmationModalProps) {
	return (
		<BottomModal {...modalProps}>
			{children}
			{confirmButton ? (
				confirmButton({ onClick: onConfirm })
			) : (
				<Button text="Oui, je confirme" onClick={onConfirm} />
			)}
			{cancelButton ? (
				cancelButton({ onClick: onCancel })
			) : (
				<BottomModal.CancelButton text="Non, j’ai changé d’avis" onClick={onCancel} />
			)}
		</BottomModal>
	);
}
