import { ComponentProps, ReactElement } from 'react';

import styles from './DataScrollView.module.scss';

import { useCancelableActionCallback } from '../../hooks/useActionCallback';
import { EndReachedEvent, EndReachedHandler } from '../../hooks/useEndReached';
import { useStableCallback } from '../../hooks/useStableCallback';
import { ScrollView } from './ScrollView';

type DataScrollViewProps = ComponentProps<typeof ScrollView> & {
	canFetchMore?: boolean;
	fetchIndicator?: ReactElement | null;
	onFetchMore?: (event: EndReachedEvent<HTMLDivElement>, signal: AbortSignal) => Promise<void>;
};

export function DataScrollView({
	canFetchMore,
	onFetchMore,
	fetchIndicator,
	endPosition = 'bottom',
	onEndReached,
	children,
	...scrollProps
}: DataScrollViewProps) {
	const [fetchMore, fetchMoreState] = useCancelableActionCallback(
		signal => (event: EndReachedEvent<HTMLDivElement>) => onFetchMore?.(event, signal)
	);
	const handleEndReached = useStableCallback<EndReachedHandler<HTMLDivElement>>(event => {
		onEndReached?.(event);
		if (event.isDefaultPrevented()) return;
		if (canFetchMore) {
			fetchMore(event);
		}
	});

	const showIndicator = canFetchMore || fetchMoreState.status === 'pending';

	return (
		<ScrollView {...scrollProps} onEndReached={handleEndReached}>
			{showIndicator && endPosition === 'top' ? (
				<>
					{fetchIndicator}
					<div className={styles.spacer} />
				</>
			) : null}
			{children}
			{showIndicator && endPosition === 'bottom' ? (
				<>
					<div className={styles.spacer} />
					{fetchIndicator}
				</>
			) : null}
		</ScrollView>
	);
}
