import axios, { ResponseType, AxiosRequestConfig } from 'axios';

import { Endpoint, REFRESH } from '../config/endpoints';
import { LOG_OUT } from '../config/endpoints';
import { tokenSelector } from '../redux/selectors/userSelector';
import { setToken } from '../redux/slice/userSlice';
import store, { persistor } from '../redux/store';
import { getRequestHeader } from '../utils/getRequestHeader';
import { logOut } from '../utils/logOut';

export enum Method {
	GET = 'GET',
	POST = 'POST',
	DELETE = 'DELETE',
	PUT = 'PUT',
	PATCH = 'PATCH',
}

export interface RequestOptions<P = object> {
	method?: Method;
	data?: object;
	params?: P;
	headers?: AxiosRequestConfig['headers'];
	responseType?: ResponseType;
	refreshRetry?: number;
	signal?: AbortSignal;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const request = async <P = object, T = any>(
	url: string | Endpoint<string, P, T>,
	requestOptions: RequestOptions<P>
): Promise<T> => {
	const API_ENDPOINT = process.env.REACT_APP_API;
	const requestUrl = `${API_ENDPOINT}${url}`;
	const headers = getRequestHeader(requestOptions?.headers);

	const options: AxiosRequestConfig = {
		url: requestUrl,
		headers,
		withCredentials: true,
		method: requestOptions?.method,
		responseType: requestOptions?.responseType,
		data: requestOptions?.data,
		params: requestOptions?.params,
		signal: requestOptions?.signal,
	};

	const retry = requestOptions?.refreshRetry ?? 1;

	try {
		// FIXME: do not use cookie inside book api
		// if (url === LOGIN || url.startsWith('/auth')) {
		options.withCredentials = true;
		// }
		const response = await axios(options);

		return response.data;
	} catch (error) {
		if (
			axios.isAxiosError(error) &&
			error.response &&
			error.response.status === 403 &&
			error.response.data.title === 'Access token expired' &&
			url !== LOG_OUT &&
			retry > 0
		) {
			await refreshToken();
			return request(url, { ...requestOptions, refreshRetry: retry - 1 });
		}

		throw error;
	}
};

const oneAtATime = run => {
	let promise;
	return () => {
		if (promise) {
			return promise;
		}
		promise = run().finally(() => {
			promise = undefined;
		});
		return promise;
	};
};

export const refreshToken = oneAtATime(async () => {
	const API_ENDPOINT = process.env.REACT_APP_API;

	try {
		const token = tokenSelector(store.getState());
		if (!token) throw new Error('There is no token is store');
		const response = await axios.post<{ data: { access_token: string } }>(
			`${API_ENDPOINT}${REFRESH}`,
			{ token },
			{ withCredentials: true }
		);
		store.dispatch(setToken(response.data.data.access_token));
		await persistor.flush();
	} catch (error) {
		console.error('Failed to refresh access token', error);
		logOut();
		window.location.reload();
		return;
	}
});
