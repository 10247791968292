import moment from 'moment';
import { TIME_FORMAT } from '../config/constants';
import { setZeroBefore } from './setZeroBefore';
import { MinutesTimeRange, MomentsTimeRange, TimeDuration, TimeRange } from '../network/types';
import { DAY_END } from '../components/availability-select/config';

export function parseTime(time: string) {
	const [hours, minutes] = time.split(':').map(Number);
	return [hours, minutes] as const;
}

export function parseTimeToMinutes(time: string) {
	const [hours, minutes] = parseTime(time);
	return hours * 60 + minutes;
}

export function timeRange<T>(wrapper: (value: T) => string, range?: { from: T; to: T }) {
	return range ? `${wrapper(range.from)} — ${wrapper(range.to)}` : '--:-- — --:--';
}

export function formatMinutesAsTime(minutes: number) {
	return `${setZeroBefore(Math.floor(minutes / 60))}:${setZeroBefore(minutes % 60)}`;
}

export function formatMinutesRangeAsTime(range?: MinutesTimeRange) {
	return timeRange(formatMinutesAsTime, range);
}

export function formatMomentsRange(range?: MomentsTimeRange) {
	return timeRange(m => m.format(TIME_FORMAT), range);
}

export function formatTimeParts(hours: number, minutes: number) {
	return `${setZeroBefore(hours)}:${setZeroBefore(minutes)}`;
}

export function formatTime(time: string) {
	const [hours, minutes] = parseTime(time);
	return formatTimeParts(hours, minutes);
}

export function formatTimeRange(range?: TimeRange) {
	return timeRange(formatTime, range);
}

export function formatServerTimeRange(range?: TimeRange) {
	return timeRange(t => moment(t).format(TIME_FORMAT), range);
}

function addOffset(time: string) {
	const offset = moment().utcOffset();
	const minutes = parseTimeToMinutes(time);

	return formatMinutesAsTime((DAY_END + minutes + offset) % DAY_END);
}

export function formatWorkingHours(workHours: TimeRange) {
	const diff = moment.duration(
		moment(workHours.to, TIME_FORMAT).diff(moment(workHours.from, TIME_FORMAT))
	);
	const hours = Math.round(diff.asHours());

	if (hours >= 24) return '24/24h';

	return formatTimeRange({ from: addOffset(workHours.from), to: addOffset(workHours.to) });
}

export function parseDuration({ from, to }: TimeRange): TimeDuration {
	const ms = moment.duration(moment(to).diff(from));
	const hours = Math.trunc(ms.asHours());
	const minutes = ms.minutes();
	return { hours, minutes };
}

export const timeProlongated = (time: string) => moment(time).add(15, 'minutes').toISOString();
