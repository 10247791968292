import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Preloader from '../../components/common/Preloader/Preloader';
import { Socials } from '../../config/constants';
import {
	CONNECT_ACCOUNT,
	FACEBOOK_LOGIN,
	GOOGLE_LOGIN,
	LINKEDIN_LOGIN,
} from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { Method, request } from '../../network/request';
import { tokenSelector } from '../../redux/selectors/userSelector';
import { setToken } from '../../redux/slice/userSlice';

import styles from './Callback.module.scss';

export default function CallbackFabric(social: Socials) {
	const bySocial = {
		[Socials.GOOGLE]: {
			basePath: ROUTES.GOOGLE_CALLBACK_PAGE,
			tokenParam: 'code',
			requestPath: GOOGLE_LOGIN,
		},
		[Socials.FACEBOOK]: {
			basePath: ROUTES.FACEBOOK_CALLBACK_PAGE,
			tokenParam: 'code',
			requestPath: FACEBOOK_LOGIN,
		},
		[Socials.LINKEDIN]: {
			basePath: ROUTES.LINKEDIN_CALLBACK_PAGE,
			tokenParam: 'code',
			requestPath: LINKEDIN_LOGIN,
		},
	} satisfies Record<Socials, { basePath: string; tokenParam: string; requestPath: string }>;
	const { basePath, tokenParam, requestPath } = bySocial[social];

	return function Callback() {
		const isAuthenticatedRef = useRef(false);
		isAuthenticatedRef.current = !!useSelector(tokenSelector);

		const location = useLocation();
		const navigate = useNavigate();
		const dispatch = useDispatch();

		const token = useMemo(() => {
			const search = new URLSearchParams(location.search);
			const token = search.get(tokenParam);
			return decodeURIComponent(token);
		}, [location.search]);
		const tokenRef = useRef('');
		tokenRef.current = token;

		useEffect(() => {
			if (!tokenRef.current) return;
			navigate(basePath, { replace: true });

			if (isAuthenticatedRef.current) {
				request(CONNECT_ACCOUNT(social), {
					method: Method.POST,
					data: { token: tokenRef.current },
				}).then(() => {
					navigate(ROUTES.PROFILE);
				});
			} else {
				request(requestPath, { method: Method.POST, data: { token: tokenRef.current } })
					.then(res => {
						if (res.data.access_token) {
							const from = localStorage.getItem('from');
							localStorage.removeItem('from');
							dispatch(setToken(res.data.access_token, { navigate, from }));
						}
					})
					.catch(() => navigate(ROUTES.LOGIN));
			}
		}, [dispatch, navigate]);

		return (
			<div className={styles.container}>
				<Preloader />
			</div>
		);
	};
}
