import { useNavigate } from 'react-router-dom';

import styles from './SuccessRegister.module.scss';
import { ReactComponent as LogoHands } from '../../../assets/success/hands.svg';
import { ReactComponent as Logo } from '../../../assets/logo_full.svg';

import ModalPortal from '../../common/ModalPortal';
import { ROUTES } from '../../../config/routes';
import { Navigate } from '../../common/Navigate/Navigate';

const SuccessRegister = () => {
	const navigate = useNavigate();

	return (
		<ModalPortal>
			<Navigate to={ROUTES.CO_WORKINGS} timeout={3000} />
			<div className={styles.successRegister}>
				<div className={styles.successRegister__wrapper}>
					<div className={styles.successRegister__header}>
						<Logo />
					</div>
					<div className={styles.successRegister__content}>
						<LogoHands />
						<h1 className={styles.successRegister__title}>Félicitations !</h1>
						<p className={styles.successRegister__info}>
							Bienvenue dans la communauté Work&Go, nous avons hâte de vous accueillir
							dans nos espaces.
						</p>
					</div>
					<div
						className={styles.successRegister__footer}
						onClick={() => navigate(ROUTES.CO_WORKINGS)}
					>
						Découvrir les espaces
					</div>
				</div>
				<div className={styles.successRegister__bird} />
			</div>
		</ModalPortal>
	);
};

export default SuccessRegister;
