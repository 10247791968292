import cn from 'classnames';
import { Suspense, useState } from 'react';

import styles from './Reservations.module.scss';

import Header from '../../components/common/Header/Header';
import ActivityIndicator from '../../components/common/ActivityIndicator/ActivityIndicator';
import { Tabs } from '../../network/types';
import { ReservationsList } from '../../components/reservations/ReservationsList';
import { ErrorBoundary } from '../../components/common/ErrorBoundary/ErrorBoundary';
import { ReservationsEmpty } from '../../components/reservations/ReservationsEmpty';
import axios from 'axios';
import { Navigate } from '../../components/common/Navigate/Navigate';
import { ROUTES } from '../../config/routes';

const ReservationsPage = () => {
	const [activeTab, setActiveTab] = useState(Tabs.ACTIVE);

	return (
		<div className={styles.reservations}>
			<Header />

			<h1 className={styles.reservations__title}>Mes réservations</h1>
			<ErrorBoundary
				fallback={error => {
					if (
						axios.isAxiosError(error) &&
						error.response?.status >= 400 &&
						error.response?.status < 500
					) {
						return <ReservationsEmpty />;
					}
					return (
						<>
							<ActivityIndicator absolute />
							<Navigate to={ROUTES.FAQ} timeout={1000} />
						</>
					);
				}}
			>
				<div className={styles.reservations__tabs}>
					<button
						onClick={() => setActiveTab(Tabs.ACTIVE)}
						className={cn(
							styles.reservations__button,
							activeTab === Tabs.ACTIVE && styles.active
						)}
						type="button"
					>
						En cours
					</button>
					<button
						onClick={() => setActiveTab(Tabs.PASSED)}
						className={cn(
							styles.reservations__button,
							activeTab === Tabs.PASSED && styles.active
						)}
						type="button"
					>
						Passées
					</button>
				</div>
				<div className={styles.reservations__wrapper}>
					<Suspense fallback={<ActivityIndicator absolute />}>
						<ReservationsList key={activeTab} activeTab={activeTab} />
					</Suspense>
				</div>
			</ErrorBoundary>
		</div>
	);
};

export default ReservationsPage;
