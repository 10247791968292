import styles from './Notification.module.scss';
import { ReactComponent as Close } from '../../../assets/close.svg';

type NotificationProps = {
	text: string;
	onClick?: () => void;
};

const Notification = ({ text = '', onClick }: NotificationProps) => {
	return (
		<div className={styles.background}>
			<div className={styles.container}>
				<button onClick={onClick}>
					<Close />
				</button>
				{text}
			</div>
		</div>
	);
};

export default Notification;
