import classNames from 'classnames';

import styles from './ReservationRoomInfo.module.scss';
import { ReactComponent as Light } from '../../assets/spaces/light.svg';
import { ReactComponent as ChevronUp } from '../../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';

import { IReservation, TimeDuration } from '../../network/types';
import { setZeroBefore } from '../../utils/setZeroBefore';
import AddressLink from '../common/AddressLink/AddressLink';
import { formatWorkingHours } from '../../utils/formatTime';
import { useState } from 'react';
import { Image } from '../common/Image/Image';

type ReservationRoomInfoProps = Omit<JSX.IntrinsicElements['div'], 'children'> & {
	reservation: IReservation;
	duration: TimeDuration;
};

export function ReservationRoomInfo({
	reservation,
	duration,
	className,
	...divProps
}: ReservationRoomInfoProps) {
	const [showInfo, setShowInfo] = useState(false);

	return (
		<div {...divProps} className={classNames(styles.container, className)}>
			<div className={styles.content}>
				<div className={styles.image}>
					{/* FIXME: should never happen */}
					<Image src={reservation.room.photos?.[0]} />
				</div>
				<div className={styles.info}>
					<p className={styles.info_name}>{reservation.room.title}</p>
					<div className={styles.info_total}>
						{reservation.price > 0 ? (
							<p className={styles.info_total_cost}>{reservation.price} €</p>
						) : (
							<p
								className={classNames(
									styles.info_total_cost,
									styles.info_total_cost_free
								)}
							>
								Gratuit
							</p>
						)}
						<div className={styles.info_total_divider}></div>
						<p className={styles.info_total_time}>
							<span>{setZeroBefore(+duration.hours)}</span>
							h. <span>{duration.minutes}</span> min.
						</p>
					</div>
				</div>
			</div>
			<div
				className={classNames(styles.separator, showInfo && 'open')}
				onClick={() => setShowInfo(!showInfo)}
			>
				<div className={styles.chevron}>{showInfo ? <ChevronUp /> : <ChevronDown />}</div>
			</div>
			{showInfo ? (
				<div className={styles.about}>
					<p className={styles.about_title}>{reservation.coworking.title}</p>
					<AddressLink
						className={styles.about_address}
						coWorkingId={reservation.coworking.id}
						address={reservation.coworking.address}
					/>
					<div className={styles.about_availability}>
						<div className={styles.about_availability_item}>
							<Light />
							<p>
								<span>Horaires d&apos;ouverture: </span>
								{formatWorkingHours(reservation.coworking.workHours)}
							</p>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}
