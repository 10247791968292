import { Action } from '@reduxjs/toolkit';
import { CanceledError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';

import { Endpoint, EndpointParams, EndpointResult } from '../config/endpoints';
import { Method, request, RequestOptions } from '../network/request';
import { RootState } from '../redux/store';
import { useStableCallback } from './useStableCallback';

export type TypedRequestOptions<E extends string | Endpoint<string, any, any>> = Omit<
	RequestOptions,
	'params' | 'data' | 'signal'
> & { url: E } & (void extends EndpointParams<E>
		? { params?: EndpointParams<E> }
		: { params: EndpointParams<E> });

type SuspenseStatePending = {
	status: 'pending';
	promise: Promise<unknown>;
	controller: AbortController;
	waiters: Set<() => void>;
};
type SuspenseStateResolved = { status: 'resolved'; response: unknown };
type SuspenseStateRejected = { status: 'rejected'; error: unknown };
type SuspenseState = SuspenseStatePending | SuspenseStateResolved | SuspenseStateRejected;
const suspenseCache = new Map<string, SuspenseState>();

export function useDataLoader<D, E extends string | Endpoint<string, any, any>>(
	key: string,
	dataSelector: (state: RootState) => D,
	optionsOrCreator: TypedRequestOptions<E> | { (): TypedRequestOptions<E> },
	handlerAction: (response: EndpointResult<E>) => Action,
	usePreloaded = true
): {
	loading: boolean;
	data: D;
	fetch(params?: Partial<EndpointParams<E>>, signal?: AbortSignal): Promise<void>;
} {
	const store = useStore<RootState>();
	const data = useSelector(dataSelector);
	const createOptions = useStableCallback(() =>
		typeof optionsOrCreator === 'function' ? optionsOrCreator() : optionsOrCreator
	);
	const handlerActionStable = useStableCallback(handlerAction);

	const fetch = useCallback(
		(additionalParams?: Partial<EndpointParams<E>>, signal?: AbortSignal) => {
			const waiters = new Set<() => void>();
			const controller = new AbortController();
			if (signal) {
				signal.addEventListener('abort', () => {
					controller.abort(signal.reason);
				});
			}
			const { params: _params, ...options } = createOptions();
			const params = { ..._params, ...additionalParams };
			const method = options.method ?? Method.GET;
			const promise = request(options.url, {
				...options,
				method,
				...(method === Method.GET ? { params } : { data: params }),
				signal: controller.signal,
			}).then(
				response => {
					suspenseCache.set(key, { status: 'resolved', response });
					store.dispatch(handlerActionStable(response));
					waiters.forEach(waiter => waiter());
				},
				error => {
					suspenseCache.set(key, { status: 'rejected', error });
					waiters.forEach(waiter => waiter());
				}
			);
			suspenseCache.set(key, { status: 'pending', promise, controller, waiters });
			return promise;
		},
		[createOptions, handlerActionStable, key, store]
	);

	const _forceRender = useState({})[1];
	const forceRender = useCallback(() => _forceRender({}), [_forceRender]);

	useEffect(() => {
		return () => {
			const suspense = suspenseCache.get(key);
			if (suspense) {
				if (suspense.status === 'pending') {
					suspense.waiters.delete(forceRender);
					suspense.controller.abort(
						new Error('CanceledError: data loader was unmounted')
					);
				}
				suspenseCache.delete(key);
			}
		};
	}, [forceRender, key]);

	let suspense = suspenseCache.get(key);
	const shouldFetch =
		!suspense || (suspense.status === 'rejected' && suspense.error instanceof CanceledError);
	if (shouldFetch) {
		const promise = fetch();
		if (usePreloaded && data !== undefined) {
			suspense = suspenseCache.get(key) as SuspenseStatePending;
			suspense.waiters.add(forceRender);
			return { loading: true, data, fetch };
		} else {
			throw promise;
		}
	}

	switch (suspense.status) {
		case 'pending':
			if (usePreloaded && data !== undefined) {
				suspense.waiters.add(forceRender);
				return { loading: true, data, fetch };
			} else {
				throw suspense.promise;
			}
		case 'resolved':
			return { loading: false, data, fetch };
		case 'rejected':
			throw Object.assign(suspense.error, { onReset: () => suspenseCache.delete(key) });
	}
}
