import classNames from 'classnames';
import AliceCarousel, { Props as AliceCarouselProps } from 'react-alice-carousel';
import { ReactElement } from 'react';

import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './PhotosCarousel.module.scss';
import { Image } from '../Image/Image';

type PhotosCarouselProps = {
	photos?: string[];
	height?: JSX.IntrinsicElements['div']['style']['height'];
	renderImage?: (photo: string, index: number) => ReactElement;
	className?: string;
	style?: JSX.IntrinsicElements['div']['style'];
} & AliceCarouselProps;

const PhotosCarousel = ({
	photos,
	renderImage = photo => <Image src={photo} role="presentation" draggable={false} />,
	height,
	className,
	style,
	...carouselProps
}: PhotosCarouselProps) => {
	return (
		<div className={classNames(styles.container, className)} style={{ height, ...style }}>
			<AliceCarousel
				items={photos?.map((photo, index) => (
					<div key={photo} className={styles.photo} style={{ height }}>
						{renderImage(photo, index)}
					</div>
				))}
				{...carouselProps}
				animationDuration={600}
				animationEasingFunction="ease-in"
				// autoPlay
				autoPlayInterval={2000}
				infinite
				mouseTracking
				touchTracking
			/>
		</div>
	);
};

export default PhotosCarousel;
