import classNames from 'classnames';
import styles from './ActivityIndicator.module.scss';

const SIZES: unknown[] = ['large', 'medium', 'small'];

type ActivityIndicatorProps = JSX.IntrinsicElements['div'] & {
	size?: 'large' | 'medium' | 'small' | number;
	pause?: boolean;
	absolute?: boolean;
	contrast?: boolean;
	background?: boolean | string;
	indicatorProps?: JSX.IntrinsicElements['span'];
};

function ActivityIndicator({
	size = 'medium',
	pause = false,
	absolute = false,
	contrast = false,
	background,
	indicatorProps,
	...divProps
}: ActivityIndicatorProps) {
	const isPredefinedSize = SIZES.includes(size);
	const backgroundColor = background && background !== true ? background : undefined;
	const sizeVar = isPredefinedSize
		? undefined
		: typeof size === 'number'
		? { '--size': `${size}px` }
		: { '--size': size };

	return (
		<div
			{...divProps}
			className={classNames(
				styles.container,
				absolute && styles.container_absolute,
				pause && styles.paused,
				background && styles.background,
				contrast && styles.contrast,
				divProps.className
			)}
			style={{ ...divProps.style, backgroundColor }}
		>
			<span
				{...indicatorProps}
				className={classNames(
					styles.indicator,
					isPredefinedSize && styles[`indicator_${size}`],
					indicatorProps?.className
				)}
				style={{ ...sizeVar, ...indicatorProps?.style }}
			/>
		</div>
	);
}

export default ActivityIndicator;
