import { Link, useParams } from 'react-router-dom';

import styles from './ReservationSuccess.module.scss';
import { ReactComponent as Logo } from '../../assets/logo_full.svg';
import { ReactComponent as Heart } from '../../assets/success/heart.svg';

import { ROUTES } from '../../config/routes';
import { Navigate } from '../../components/common/Navigate/Navigate';

const ReservationSuccessPage = () => {
	const { reservationId } = useParams();
	// const reservation = useDataLoader(
	// 	state => reservationSelector(state, reservationId),
	// 	RESERVATION(reservationId),
	// 	{ method: Method.GET },
	// 	response => handleReservation(response.data)
	// );

	return (
		<div className={styles.container}>
			<div className={styles.background} />
			<Navigate to={ROUTES.RESERVATION(reservationId)} timeout={3000} />
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<Logo />
				</div>
				<div className={styles.content}>
					<Heart />
					<h1 className={styles.title}>Votre réservation est confirmée</h1>
					<p className={styles.info}>
						Nous avons hâte de vous accueillir. Vous recevrez très prochainement un
						email de confirmation pour votre réservation. Vous pouvez consulter les
						détails de votre réservation en cliquant sur le bouton ci-dessous.
					</p>
					{/* <ReservationCalendarButton contrast reservation={reservation} /> */}
				</div>
				<Link to={ROUTES.RESERVATIONS} className={styles.footer}>
					Mes réservations
				</Link>
			</div>
		</div>
	);
};

export default ReservationSuccessPage;
