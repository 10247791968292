import styles from './LoginTabs.module.scss';
import cn from 'classnames';
import { ReactComponent as LogoBlack } from '../../../assets/logo_black.svg';
import ButtonTab from '../ButtonTab/ButtonTab';
import { LoginSteps } from '../../../pages/login/types';
import { ROUTES } from '../../../config/routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';

type LoginTabsProps = {
	loginStep?: string;
};

const LoginTabs = ({ loginStep }: LoginTabsProps) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const location = useLocation();
	let from: string | undefined = location.state?.from;
	from = typeof from === 'string' ? from : undefined;

	const isLoginPage = pathname.includes(ROUTES.LOGIN);

	return (
		<div className={styles.tabs}>
			<Link to={ROUTES.CO_WORKINGS}>
				<LogoBlack />
			</Link>
			{loginStep !== LoginSteps.NEW_PASSWORD && (
				<div
					className={cn(styles.tabs__actions, {
						[styles.signUp]: !isLoginPage,
					})}
				>
					<ButtonTab
						active={isLoginPage}
						text="Se connecter"
						onClick={() => navigate(ROUTES.LOGIN, { state: { from } })}
					/>
					<ButtonTab
						active={!isLoginPage}
						text="S'inscrire"
						onClick={() => navigate(ROUTES.SIGNUP, { state: { from } })}
					/>
				</div>
			)}
		</div>
	);
};

export default LoginTabs;
