import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Delete.module.scss';

import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as Logo } from '../../../assets/logo_black.svg';
import { ROUTES } from '../../../config/routes';
import { ActionCb } from '../../common/Action/Action';
import { ActionButton } from '../../common/Button/ActionButton';
import Input from '../../common/Input/Input';

type DeleteProps = {
	confirmEmail: string;
	emailError: boolean;
	onCompareEmail: () => void;
	onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
	confirmDelete: ActionCb<void>;
};

const Delete = ({
	confirmEmail,
	emailError,
	onChangeEmail,
	confirmDelete,
	onCompareEmail,
}: DeleteProps) => {
	const navigate = useNavigate();

	return (
		<div className={styles.delete}>
			<Logo />
			<div className={styles.info}>
				<DeleteIcon />
				<h1>Suppression de compte</h1>
				<p>
					Si vous souhaitez vraiment supprimer votre compte, veuillez saisir votre e-mail
					pour confirmer cette action.
				</p>
			</div>
			<div className={styles.container}>
				<Input
					type="email"
					autoComplete="email"
					label="E-mail"
					placeholder="Votre e-mail"
					className={styles.email}
					name="email"
					value={confirmEmail}
					onChange={onChangeEmail}
					onBlur={onCompareEmail}
					error={emailError && 'L`e-mail ne correspond pas'}
				/>
			</div>
			<div className={styles.delete__footer}>
				<ActionButton
					text="Confirmer"
					attention
					disabled={emailError}
					action={confirmDelete}
				/>
				<a className={styles.cancel} onClick={() => navigate(ROUTES.FAQ)}>
					Annuler
				</a>
			</div>
		</div>
	);
};

export default Delete;
