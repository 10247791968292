import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import './App.css';

import { ROUTES, routes } from './config/routes';
import { UserType } from './config/constants';
import { tokenSelector } from './redux/selectors/userSelector';
import { Navigate } from './components/common/Navigate/Navigate';
import AnimatedRoutes from './components/AnimatedRoutes/AnimatedRoutes';
import { ErrorBoundary } from './components/common/ErrorBoundary/ErrorBoundary';
import Preloader from './components/common/Preloader/Preloader';
import { ErrorPage } from './pages/error';

const App = () => {
	const isAuthenticated = !!useSelector(tokenSelector);

	return (
		<BrowserRouter>
			<Suspense fallback={<Preloader id="suspense" />}>
				<ErrorBoundary fallback={<ErrorPage />}>
					<AnimatedRoutes>
						{routes.map(({ name, path, Layout, component: Component, access }) => {
							const protectedRoute = !access.includes(UserType.UNAUTHORISED);

							let element = <Component />;
							if (Layout) {
								element = <Layout>{element}</Layout>;
							}
							if (protectedRoute && !isAuthenticated) {
								element = <Navigate to={ROUTES.LOGIN} state={{ from: path }} />;
							}

							if (
								isAuthenticated &&
								(path === ROUTES.LOGIN || path === ROUTES.SIGNUP)
							) {
								element = <Navigate to={ROUTES.PROFILE} />;
							}

							return <Route key={name} path={path} element={element} />;
						})}

						<Route
							index
							path="*"
							element={
								<Navigate
									to={ROUTES.CO_WORKINGS}
									replace
									state={{ immediate: true }}
								/>
							}
						/>
					</AnimatedRoutes>
				</ErrorBoundary>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
