import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../assets/back_arrow.svg';
import styles from './Reservation.module.scss';

import { BottomModal } from '../../components/common/BottomModal/BottomModal';
import { RealTime } from '../../components/common/RealTime/RealTime';
import { ReservationControls } from '../../components/reservations/ReservationControls';
import { ReservationDateTime } from '../../components/reservations/ReservationDateTime';
import { ReservationRoomInfo } from '../../components/reservations/ReservationRoomInfo';
import {
	CANCEL_RESERVATION,
	CLOSE_ROOM_DOOR,
	END_SESSION,
	OPEN_ROOM_DOOR,
	START_SESSION,
} from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { useReservationLoader } from '../../hooks/loaders/useReservationLoader';
import { useCancelableActionCallback } from '../../hooks/useActionCallback';
import { AsyncState } from '../../hooks/useAsyncState';
import { Method, request } from '../../network/request';
import { updateReservation } from '../../redux/slice/reservationsSlice';
import { parseDuration } from '../../utils/formatTime';

export function ReservationPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { reservationId } = useParams();
	const { data: reservation } = useReservationLoader(reservationId);
	const {
		coworking: { id: coWorkingId },
		room: { id: roomId },
		sessionStartedAt,
		sessionEndedAt,
	} = reservation;
	const duration = useMemo(() => parseDuration(reservation), [reservation]);

	useEffect(() => {
		if (sessionEndedAt) {
			navigate(ROUTES.RESERVATION_CLOSE(reservationId));
		}
	}, [navigate, reservationId, sessionEndedAt]);

	const finish = useCallback(async () => {
		if (reservation.sessionEndedAt) {
			return;
		}
		await request(OPEN_ROOM_DOOR(coWorkingId, roomId), {
			method: Method.POST,
			params: { orderId: reservationId },
		}).catch(() => undefined);
		navigate(ROUTES.RESERVATION_CLOSE(reservationId));
	}, [coWorkingId, navigate, reservation.sessionEndedAt, reservationId, roomId]);

	const stop = useCallback(async () => {
		if (!sessionStartedAt) {
			await request(START_SESSION(coWorkingId, roomId), { method: Method.POST });
			dispatch(
				updateReservation(reservationId, { sessionStartedAt: moment().toISOString() })
			);
		}
		if (!sessionEndedAt) {
			await request(END_SESSION(coWorkingId, roomId), { method: Method.POST });
			dispatch(updateReservation(reservationId, { sessionEndedAt: moment().toISOString() }));
		}
		await finish();
	}, [sessionStartedAt, sessionEndedAt, finish, coWorkingId, roomId, dispatch, reservationId]);

	const cancel = useCallback(async () => {
		await request(CANCEL_RESERVATION(reservationId), { method: Method.POST });
		navigate(ROUTES.CO_WORKINGS);
	}, [navigate, reservationId]);

	const prolongate = useCallback(async () => {
		// await request(PROLONGATE(reservationId), {
		// 	method: Method.POST,
		// 	data: { to: timeProlongated(reservation.to) },
		// });
		navigate(ROUTES.CO_WORKING_ROOM(coWorkingId, roomId) + '#time');
	}, [coWorkingId, navigate, roomId]);

	const [openDoor, openDoorState, updateOpenDoorState] = useCancelableActionCallback(
		signal => async () => {
			if (!sessionStartedAt) {
				const sessionStartedAt = moment().toISOString();
				await request(START_SESSION(coWorkingId, roomId), {
					method: Method.POST,
					signal,
				}).then(
					() => dispatch(updateReservation(reservationId, { sessionStartedAt })),
					() => undefined
				);
			}
			await request(OPEN_ROOM_DOOR(coWorkingId, roomId), { method: Method.POST, signal });
		}
	);
	const [closeDoor, closeDoorState, updateCloseDoorState] = useCancelableActionCallback(
		signal => async () => {
			await request(CLOSE_ROOM_DOOR(coWorkingId, roomId), { method: Method.POST, signal });
		}
	);
	const doorFailed = doorFailedTest(openDoorState) || doorFailedTest(closeDoorState);
	const closeDoorFailedTip = useCallback(() => {
		updateOpenDoorState({ status: 'empty' });
		updateCloseDoorState({ status: 'empty' });
	}, [updateCloseDoorState, updateOpenDoorState]);
	useEffect(() => {
		const timeout = setTimeout(closeDoorFailedTip, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [closeDoorFailedTip]);

	return (
		<div className={styles.reservation}>
			<div className={styles.header}>
				<button onClick={() => navigate(ROUTES.RESERVATIONS)}>
					<Arrow />
				</button>
				<p>Votre reservation</p>
				<div className={styles.header_empty} />
				{/* <QrReaderButton /> */}
			</div>
			<ReservationRoomInfo reservation={reservation} duration={duration} />
			<ReservationDateTime reservation={reservation} className={styles.dateTime} />
			<RealTime>
				{now => (
					<ReservationControls
						reservation={reservation}
						now={now}
						stop={stop}
						cancel={cancel}
						prolongate={prolongate}
						finish={finish}
						openDoor={openDoor}
						closeDoor={closeDoor}
					/>
				)}
			</RealTime>
			<BottomModal modalVisible={doorFailed} title="Oops...">
				<BottomModal.CloseButton onClick={closeDoorFailedTip} />
				{doorClosePhysicalImpairmentFailedTest(closeDoorState) ? (
					<p>
						Oops... Il semble qu&apos;il y a un problème. Vérifiez que les portes soient
						bien fermées et essayez de nouveau
					</p>
				) : (
					<p>Oops... Il semble qu&apos;il y a un problème merci de réessayer plus tard</p>
				)}
			</BottomModal>
		</div>
	);
}

function doorFailedTest(state: AsyncState<void>) {
	return (
		state.status === 'rejected' &&
		axios.isAxiosError(state.error) &&
		state.error.response &&
		(state.error.response.data.status === 502 ||
			state.error.response.data.title === 'Device unreachable')
	);
}

function doorClosePhysicalImpairmentFailedTest(state: AsyncState<void>) {
	return (
		state.status === 'rejected' &&
		axios.isAxiosError(state.error) &&
		state.error.response &&
		state.error.response.data.status === 502 &&
		state.error.response.data.title === 'Physical impairment'
	);
}
