import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorBoundary } from '../../components/common/ErrorBoundary/ErrorBoundary';
import FailedRegister from '../../components/modals/FailedRegister/FailedRegister';
import SuccessRegister from '../../components/modals/SuccessRegister/SuccessRegister';
import { VERIFY_EMAIL } from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { useDataLoader } from '../../hooks/useDataLoader';
import { useLocationToken } from '../../hooks/useLocationToken';
import { Method } from '../../network/request';
import { setToken } from '../../redux/slice/userSlice';

type VerifyEmailActionProps = {
	children: ReactNode;
};

const VerifyEmailAction = ({ children }: VerifyEmailActionProps) => {
	const parseToken = useLocationToken(ROUTES.VERIFY_EMAIL());
	const navigate = useNavigate();

	useDataLoader(
		VERIFY_EMAIL,
		() => undefined,
		() => ({ url: VERIFY_EMAIL, method: Method.POST, params: { token: parseToken() } }),
		response => setToken(response.data.access_token, { navigate })
	);

	return <>{children}</>;
};

const VerifyEmail = () => {
	return (
		<div style={{ background: 'white' }}>
			<ErrorBoundary fallback={<FailedRegister />}>
				<VerifyEmailAction>
					<SuccessRegister />
				</VerifyEmailAction>
			</ErrorBoundary>
		</div>
	);
};

export default VerifyEmail;
