import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

const ModalPortal = ({ children }) => {
	return ReactDOM.createPortal(
		<div className={styles.portal}>
			<div className={styles.modal}>{children}</div>
		</div>,
		document.body
	);
};

export default ModalPortal;
