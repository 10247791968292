import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../components/common/ErrorBoundary/ErrorBoundary';

import { Navigate } from '../../components/common/Navigate/Navigate';
import { ROUTES } from '../../config/routes';
import { useReservationsLoader } from '../../hooks/loaders/useReservationsLoader';
import { Tabs } from '../../network/types';
import { tokenSelector } from '../../redux/selectors/userSelector';

function QRAuthenticatedHandler() {
	const { coWorkingId, roomId } = useParams();
	const { data } = useReservationsLoader(Tabs.ACTIVE, 1);
	const reservationId = data?.items[0]?.id;

	return reservationId ? (
		<Navigate to={ROUTES.RESERVATION(reservationId)} />
	) : (
		<Navigate to={ROUTES.CO_WORKING_ROOM(coWorkingId, roomId)} />
	);
}

function QRHandlerPage() {
	const { coWorkingId, roomId } = useParams();
	const isAuthenticated = !!useSelector(tokenSelector);

	return isAuthenticated ? (
		<ErrorBoundary fallback={<Navigate to={ROUTES.CO_WORKING_ROOM(coWorkingId, roomId)} />}>
			<QRAuthenticatedHandler />
		</ErrorBoundary>
	) : (
		<Navigate to={ROUTES.CO_WORKING_ROOM(coWorkingId, roomId)} />
	);
}

export default QRHandlerPage;
