import { COWORKINGS_LIST } from '../../config/endpoints';
import { Method } from '../../network/request';
import { coWorkingsPageSelector } from '../../redux/selectors/coWorkingsSelector';
import { handleCoWorkingsPage } from '../../redux/slice/coWorkingsSlice';
import { usePaginatedDataLoader } from '../usePaginatedDataLoader';

export function useCoWorkingsLoader(pageSize?: number) {
	return usePaginatedDataLoader(
		COWORKINGS_LIST,
		coWorkingsPageSelector,
		{
			url: COWORKINGS_LIST,
			method: Method.GET,
			params: { limit: pageSize },
			// headers: { ['Cache-Control']: 'no-cache' },
		},
		handleCoWorkingsPage
	);
}
