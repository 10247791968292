import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import moment, { Moment } from 'moment';
import styles from './AvailabilitySelect.module.scss';
import { animated, useSpring } from '@react-spring/web';
import { TimeSlots } from './TimeSlots';
import { ReactComponent as Arrow } from '../../assets/back_arrow.svg';
import { ReactComponent as Info } from '../../assets/reservations/info.svg';
import { DateSelect } from './DateSelect';
import Button from '../common/Button/Button';
import { firstAvailableTimeSlot, isTimeSlotDisabled, loadAvailableSlots } from './lib';
import Notification from '../common/Notification/Notification';
import { DATE_FORMAT } from '../../config/constants';
import { setZeroBefore } from '../../utils/setZeroBefore';
import { MinutesTimeRange, TimeRange } from '../../network/types';
import { formatMinutesRangeAsTime, parseTimeToMinutes } from '../../utils/formatTime';
import { DAY_END } from './config';

type AvailabilitySelectProps = {
	coWorkingId: number;
	roomId: number;
	workHours: TimeRange;
	onSelect: (date: string, slot: MinutesTimeRange) => void;
	modalVisible: boolean;
	onCloseModal: () => void;
};

export function AvailabilitySelect({
	coWorkingId,
	roomId,
	workHours,
	onSelect,
	modalVisible,
	onCloseModal,
}: AvailabilitySelectProps) {
	const initialDate = useRef<string>(undefined as never);
	if (!initialDate.current) {
		const date = moment();
		const dateMs = Math.trunc(date.valueOf() / 1000 / 60) % DAY_END;
		const to = parseTimeToMinutes(workHours.to);
		if (to < dateMs) {
			date.add(1, 'day');
		}
		initialDate.current = date.format(DATE_FORMAT);
	}

	const [selectedDate, setSelectedDate] = useState<string>(initialDate.current);
	const [selectedSlot, setSelectedSlot] = useState<MinutesTimeRange>({ from: 0, to: 15 });
	const [availableSlots, setAvailableSlots] = useState<MinutesTimeRange[]>([]);
	const [showInfo, setShowInfo] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		let waiting = true;
		setLoading(true);
		loadAvailableSlots(coWorkingId, roomId, selectedDate).then(available => {
			if (!waiting) return;

			const first = firstAvailableTimeSlot(available);

			if (first) {
				setSelectedSlot(first);
			} else if (selectedDate === initialDate.current) {
				setSelectedDate(current => {
					const next = moment(current, DATE_FORMAT);
					next.add(1, 'day');
					return next.format(DATE_FORMAT);
				});
			}
			// if (same day && !available.length) setSelectedDate(tomorrow);
			setAvailableSlots(available);
			setLoading(false);
		});
		return () => {
			waiting = false;
			setLoading(false);
		};
	}, [coWorkingId, roomId, selectedDate]);

	const { opacity, transform, ...rest } = useSpring({
		from: { opacity: 0, transform: `translateX(100%)` },
		to: {
			opacity: modalVisible ? 1 : 0,
			transform: modalVisible ? `translateX(0%)` : `translateX(100%)`,
		},
	});

	const formattedTimeDuration = useMemo(() => {
		const minutesTotal = selectedSlot.to - selectedSlot.from;
		const hours = Math.trunc(minutesTotal / 60);
		const minutes = Math.trunc(minutesTotal % 60);

		return (
			<>
				<span>{setZeroBefore(hours)}</span> h <span>{setZeroBefore(minutes)}</span> min.
			</>
		);
	}, [selectedSlot]);

	const isSelectedSlotDisabled = useMemo(
		() => isTimeSlotDisabled(selectedSlot, availableSlots),
		[availableSlots, selectedSlot]
	);
	const disableConfirm = isSelectedSlotDisabled || loading;

	return ReactDOM.createPortal(
		<animated.div
			style={{ ...rest, opacity: opacity, transform: transform }}
			className={styles.modal}
		>
			{showInfo && (
				<Notification
					text="Sélectionnez la date et l`heure de la réservation. Le temps minimum de réservation
				est de 15 minutes. Vous ne pouvez sélectionner que les heures disponibles, soyez
				prudent."
					onClick={() => setShowInfo(false)}
				/>
			)}
			<div className={styles.modal__wrapper}>
				<div className={styles.modal__header}>
					<button>
						<Arrow onClick={onCloseModal} />
					</button>
					<h1 className={styles.modal__title}>Date et heure de réservation</h1>
					<button className={styles.modal__info}>
						<Info onClick={() => setShowInfo(true)} />
					</button>
				</div>
				<DateSelect selectedDate={selectedDate} onChange={setSelectedDate} />
				<TimeSlots
					className={styles.timeSlots}
					loading={loading}
					availableSlots={availableSlots}
					disabled={isSelectedSlotDisabled}
					timeSlot={selectedSlot}
					onSelect={setSelectedSlot}
				/>
				<div className={styles.modal__footer}>
					<div className={styles['modal__footer--selected-time']}>
						Créneau sélectionné <div className={styles.divider} />{' '}
						<strong>
							{formatMinutesRangeAsTime(disableConfirm ? undefined : selectedSlot)}
						</strong>
					</div>
					<div className={styles['modal__footer--info']}>
						<div>
							<span className={styles['modal__footer--info__title']}>Gratuit</span>
							<span className={styles['modal__footer--info__summary']}>
								{disableConfirm ? (
									<>
										<span>--</span> h
									</>
								) : (
									formattedTimeDuration
								)}
							</span>
						</div>
						<Button
							text="Confirmer"
							disabled={disableConfirm}
							onClick={() => onSelect(selectedDate, selectedSlot)}
						/>
					</div>
				</div>
			</div>
		</animated.div>,
		document.body
	);
}
