import classNames from 'classnames';
import moment, { Moment } from 'moment';

import { ReactComponent as Cancel } from '../../assets/reservations/cancel.svg';
import { ReactComponent as Time } from '../../assets/reservations/time.svg';
import styles from './ReservationControls.module.scss';

import { useCallback, useState } from 'react';
import { IReservation } from '../../network/types';
import { setZeroBefore } from '../../utils/setZeroBefore';
import { ActionCb } from '../common/Action/Action';
import { ActionButton } from '../common/Button/ActionButton';
import Button from '../common/Button/Button';
import { ActionButtonIcon } from '../common/ButtonIcon/ActionButtonIcon';
import { useConfirmationModal } from '../common/ConfirmationModal/useConfirmationModal';
import { ReservationCalendarButton } from '../common/ReservationCalendarButton/ReservationCalendarButton';

const CONTROLLABLE_STATUSES: IReservation['status'][] = ['payed', 'sessionStarted', 'sessionEnded'];

type ReservationControlsProps = {
	now: Moment;
	reservation: IReservation;
	stop: ActionCb<void>;
	cancel: ActionCb<void>;
	prolongate: ActionCb<void>;
	finish: ActionCb<void>;
	openDoor: ActionCb<void>;
	closeDoor: ActionCb<void>;
};

export function ReservationControls({
	now,
	reservation,
	stop,
	cancel,
	prolongate,
	finish,
	openDoor,
	closeDoor,
}: ReservationControlsProps) {
	const [{ onClick: confirmStop }, stopModal] = useConfirmationModal(stop, {
		title: 'Êtes-vous sûr de vouloir terminer la session maintenant ?',
		children: <p>Votre réservation à été déplacée vers l’onglet réservations passées</p>,
		confirmButton: props => <Button {...props} attention text="Oui, j’annule" />,
	});
	const [{ onClick: confirmCancel }, cancelModal] = useConfirmationModal(cancel, {
		title: 'Vous êtes sûr de vouloir annuler votre réservation ?',
		children: <p>Votre réservation sera déplacée dans vos réservations passées</p>,
		confirmButton: props => <Button {...props} attention text="Oui, j’annule" />,
	});

	const [doorOpened, setDoorOpened] = useState(false);
	const handleDoorOpen = useCallback(
		async (signal: AbortSignal) => {
			await openDoor(signal);
			setDoorOpened(true);
		},
		[openDoor]
	);
	const handleDoorClose = useCallback(
		async (signal: AbortSignal) => {
			await closeDoor(signal);
			setDoorOpened(false);
		},
		[closeDoor]
	);

	let to: Moment;

	const sessionStarted = now.isAfter(reservation.from);
	const sessionFinished = now.isAfter(reservation.to) || !!reservation.sessionEndedAt;

	if (!sessionStarted) {
		to = moment(reservation.from);
	} else if (!sessionFinished) {
		to = moment(reservation.to);
	} else {
		to = now;
	}

	const timeLeft = moment.duration(to.diff(now));
	const hours = Math.trunc(timeLeft.asHours());
	const minutes = timeLeft.minutes();
	const seconds = timeLeft.seconds();
	const lessThanFive = timeLeft.asMinutes() < 5;

	let formattedValue: string;
	let level: string;
	if (hours > 0) {
		formattedValue = `${setZeroBefore(hours)}:${setZeroBefore(minutes)}`;
		level = 'h.';
	} else {
		formattedValue = `${setZeroBefore(minutes)}:${setZeroBefore(seconds)}`;
		level = 'm.';
	}

	const disableAll = !CONTROLLABLE_STATUSES.includes(reservation.status);

	if (reservation.status === 'payed' && sessionFinished && !reservation.sessionStartedAt) {
		return null;
	}

	return (
		<>
			{sessionStarted ? (
				<p className={styles.rules}>
					A la fin de votre session pensez à verrouiller la porte en cliquant sur
					“Terminer la session”
				</p>
			) : null}
			{sessionStarted ? (
				<div className={styles.timer}>
					<p>Temps restant</p>
					<p className={classNames(styles.counter, lessThanFive && styles.isEnding)}>
						{formattedValue}
						<span> {level}</span>
					</p>
				</div>
			) : null}
			{!sessionStarted ? (
				<ReservationCalendarButton
					className={styles.reservationCalendar}
					reservation={reservation}
				/>
			) : null}
			<div className={styles.actions}>
				<div className={styles.buttons}>
					{sessionFinished ? (
						<ActionButtonIcon
							text="Terminer la session"
							icon={<Cancel />}
							disabled={disableAll}
							action={finish}
						/>
					) : sessionStarted ? (
						<ActionButtonIcon
							text="Terminer la session"
							className={styles.buttons_item}
							icon={<Cancel />}
							disabled={sessionFinished || disableAll}
							action={confirmStop}
						/>
					) : (
						<ActionButtonIcon
							text="Annuler"
							className={styles.buttons_item}
							icon={<Cancel />}
							disabled={disableAll}
							action={confirmCancel}
						/>
					)}
					<ActionButtonIcon
						text="Prolonger"
						className={styles.buttons_item}
						icon={<Time />}
						disabled={!sessionStarted || sessionFinished || disableAll}
						action={prolongate}
					/>
				</div>
				{!sessionStarted ? (
					<ActionButton text="Ouvrir la porte" disabled />
				) : sessionFinished ? (
					<ActionButton
						text="Ouvrir la porte"
						attention
						disabled={disableAll}
						action={finish}
					/>
				) : !doorOpened ? (
					<ActionButton
						text="Ouvrir la porte"
						disabled={disableAll}
						action={handleDoorOpen}
					/>
				) : (
					<ActionButton
						text="Fermer la porte"
						disabled={disableAll}
						action={handleDoorClose}
					/>
				)}
				{!sessionStarted ? (
					<p>Il est encore trop tôt pour démarrer votre réservation</p>
				) : null}
				{stopModal}
				{cancelModal}
			</div>
		</>
	);
}
