export const DATE_FORMAT = 'DD / MM / YYYY';
export const TIME_FORMAT = 'H:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const startOfDay = '00:00';
export const endOfDay = '23:59';

export enum UserType {
	AUTHORISED = 'AUTHORISED',
	UNAUTHORISED = 'UNAUTHORISED',
}

export enum Socials {
	LINKEDIN = 'LinkedIn',
	FACEBOOK = 'Facebook',
	GOOGLE = 'Google',
}
