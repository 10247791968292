import { RefObject, useEffect, useRef } from 'react';

import { useStableCallback } from './useStableCallback';
import { useStableValue } from './useStableValue';

export type ResizeEntryObserverCallback<T extends Element> = (
	entry: Omit<ResizeObserverEntry, 'target'> & { target: T },
	entries: ResizeObserverEntry[],
	observer: ResizeObserver
) => void;

export function useResizeObserver(onResize?: ResizeObserverCallback) {
	const stableOnResize = useStableCallback<ResizeObserverCallback>((entries, observer) =>
		onResize?.(entries, observer)
	);

	const handlersMap = useRef<Map<Element, Set<ResizeEntryObserverCallback<Element>>>>();
	if (!handlersMap.current) {
		handlersMap.current = new Map();
	}

	const resizeObserver = useRef<ResizeObserver | undefined>();
	if (!resizeObserver.current) {
		resizeObserver.current = new ResizeObserver((entries, observer) => {
			stableOnResize?.(entries, observer);
			for (const entry of entries) {
				const handlers = handlersMap.current.get(entry.target);
				handlers?.forEach(handler => handler(entry, entries, observer));
			}
		});
	}
	useEffect(
		() => () => {
			resizeObserver.current.disconnect();
			resizeObserver.current = undefined;
		},
		[]
	);

	return function useTarget<T extends Element>(
		targetOrRef: Element | null | RefObject<Element | null>,
		onResize?: ResizeEntryObserverCallback<T>,
		options?: ResizeObserverOptions
	) {
		const stableResizeHandler = useStableCallback(onResize);
		const stableOptions = useStableValue(options);
		useEffect(() => {
			if (!targetOrRef) {
				return;
			}

			const target = targetOrRef instanceof Element ? targetOrRef : targetOrRef.current;
			let handlers = handlersMap.current.get(target);
			if (!handlers) {
				handlers = new Set();
				handlersMap.current.set(target, handlers);
			}
			handlers.add(stableResizeHandler);

			resizeObserver.current.observe(target, stableOptions);
			return () => {
				resizeObserver.current?.unobserve(target);
				handlers.delete(stableResizeHandler);
			};
		}, [stableOptions, stableResizeHandler, targetOrRef]);
	};
}
