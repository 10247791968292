import { useEffect, useRef } from 'react';
import { NavigateProps as OrigNavigateProps, useNavigate } from 'react-router-dom';

type NavigateProps = OrigNavigateProps & {
	doNotRetry?: boolean;
	retryInterval?: number;
	timeout?: number;
};

// FIXME: https://github.com/remix-run/react-router/issues/9116#issuecomment-1209771121
export function Navigate({
	to,
	relative,
	replace,
	state: propState,
	doNotRetry,
	retryInterval = 1000,
	timeout,
}: NavigateProps) {
	const navigate = useNavigate();

	const state = useRef();
	state.current = propState;

	useEffect(() => {
		const running = !doNotRetry;
		let timeoutId: ReturnType<typeof setTimeout>;

		function loop() {
			if (!running) return;
			navigate(to, { relative, replace, state: state.current });
			timeoutId = setTimeout(loop, retryInterval);
		}
		if (timeout) {
			timeoutId = setTimeout(loop, timeout);
		} else {
			loop();
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [doNotRetry, navigate, relative, replace, retryInterval, timeout, to]);

	return null;
}
