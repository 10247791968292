import axios from 'axios';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LoginForm.module.scss';

import { LOGIN } from '../../config/endpoints';
import { FormValidatorScheme, useForm } from '../../hooks/useForm';
import { Method, request } from '../../network/request';
import { setToken } from '../../redux/slice/userSlice';
import { emailValidator, passwordValidator } from '../../utils/validators';
import ActivityIndicator from '../common/ActivityIndicator/ActivityIndicator';
import Button from '../common/Button/Button';
import Input from '../common/Input/Input';
import PasswordInput from '../common/Input/PasswordInput';
import LoginSocials from '../common/LoginSocials/LoginSocials';

type LoginFormValues = {
	email: string;
	password: string;
};
const loginFormValidator: FormValidatorScheme<LoginFormValues> = {
	email: email =>
		(emailValidator(email) && !!email) || 'Merci d’indiquer une adresse e-mail valide',
	password: password =>
		(passwordValidator(password) && !!password) || 'Mot de passe invalide, réessayez',
};

type LoginFormProps = {
	onRecoverPassword?: () => void;
};

export function LoginForm({ onRecoverPassword }: LoginFormProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from;

	const dispatch = useDispatch();
	const {
		values,
		renderError,
		handleInputChange,
		handleInputFocus,
		handleInputBlur,
		handleSubmit,
		submitAction: [submitState],
		disableForm,
		disableSubmit,
	} = useForm<LoginFormValues>({
		initialValues: { email: '', password: '' },
		validator: loginFormValidator,
		onSubmit: async (data, signal) => {
			const response = await request(LOGIN, { method: Method.POST, data, signal });
			const token = response.data.access_token;
			if (!token) throw new Error('Internal server error, try again later');
			dispatch(setToken(token, { navigate, from }));
		},
	});

	const errorMessage = useMemo(() => {
		if (submitState.status !== 'rejected') return undefined;
		if (axios.isAxiosError(submitState.error) && submitState.error.response.data?.detail) {
			return submitState.error.response.data?.detail;
		}
		if (submitState.error instanceof Error) return submitState.error.message;
		return 'Internal server error, try again later';
	}, [submitState]);

	return (
		<div className={styles.container}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<Input
					type="email"
					autoComplete="username"
					label="E-mail"
					placeholder="Votre e-mail"
					className={styles.email}
					name="email"
					value={values.email}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('email')}
				/>
				<PasswordInput
					autoComplete="current-password"
					label="Indiquez votre mot de passe"
					placeholder="Écrivez votre mot de passe"
					className={styles.password}
					name="password"
					value={values.password}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('password') || !!errorMessage}
				/>
				<a className={styles.recoverLink} onClick={onRecoverPassword}>
					Mot de passe oublié?
				</a>
				<Button
					type="submit"
					className={styles.submit}
					disabled={disableSubmit}
					text={
						submitState.status === 'pending' ? (
							<ActivityIndicator contrast />
						) : (
							'Se connecter'
						)
					}
				/>
				<div className={styles.socials}>
					<p className={styles.socials_title}>Se connecter avec</p>
					<LoginSocials />
				</div>
			</form>
		</div>
	);
}
