import FacebookLogin from '@greatsumini/react-facebook-login';
import { ReactComponent as Facebook } from '../../../assets/socials/facebook.svg';
import { ReactComponent as Google } from '../../../assets/socials/google.svg';
import { ReactComponent as Linkedin } from '../../../assets/socials/linkedin.svg';
import styles from './ConnectSocilas.module.scss';
import { ROUTES } from '../../../config/routes';
import { useNavigate } from 'react-router-dom';
import { Method, request } from '../../../network/request';
import { CONNECT_ACCOUNT } from '../../../config/endpoints';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../redux/selectors/userSelector';
import { Socials } from '../../../config/constants';

import cn from 'classnames';

const ConnectSocilas = () => {
	const { externalProfiles } = useSelector(userSelector);
	const navigate = useNavigate();

	const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
	const linkedinClientId = process.env.REACT_APP_LINKEDIN_APP_ID;
	const googleClientId = process.env.REACT_APP_GOOGLE_APP_ID;

	const GOOGLE_AUTH = `https://accounts.google.com/o/oauth2/auth?client_id=${googleClientId}&redirect_uri=${window.location.origin}${ROUTES.GOOGLE_CALLBACK_PAGE}&scope=openid+profile+email&response_type=code`;
	const LINKEDIN_AUTH = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedinClientId}&redirect_uri=${window.location.origin}${ROUTES.LINKEDIN_CALLBACK_PAGE}&scope=openid,profile,email`;

	return (
		<>
			<div className={styles.login__buttons}>
				<FacebookLogin
					appId={facebookAppId}
					scope="public_profile,email"
					onSuccess={response => {
						const token = response.accessToken;
						request(CONNECT_ACCOUNT(Socials.FACEBOOK), {
							method: Method.POST,
							data: { token },
						}).then(() => {
							navigate(ROUTES.PROFILE);
							window.location.reload();
						});
					}}
					render={({ onClick }) => (
						<button
							type="button"
							onClick={onClick}
							className={cn({
								[styles.active]: externalProfiles.includes(Socials.FACEBOOK),
							})}
						>
							<Facebook />
						</button>
					)}
				/>
				<button
					type="button"
					onClick={() => window.location.replace(GOOGLE_AUTH)}
					className={cn({
						[styles.active]: externalProfiles.includes(Socials.GOOGLE),
					})}
				>
					<Google />
				</button>
				<button
					type="button"
					onClick={() => window.location.replace(LINKEDIN_AUTH)}
					className={cn({
						[styles.active]: externalProfiles.includes(Socials.LINKEDIN),
					})}
				>
					<Linkedin />
				</button>
			</div>
		</>
	);
};

export default ConnectSocilas;
