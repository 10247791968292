import { animated, useSpring } from '@react-spring/web';
import { useState } from 'react';

import styles from './Login.module.scss';

import LoginTabs from '../../components/common/LoginTabs/LoginTabs';
import TermsModal from '../../components/common/TermsModal/TermsModal';
import { HoldInfoModal } from '../../components/login/HoldInfoModal';
import Login from '../../components/login/Login';
import { LoginSteps } from './types';

const LoginPage = () => {
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [loginStep, setLoginStep] = useState(LoginSteps.LOGIN);

	const { opacity } = useSpring({
		config: { duration: 300 },
		from: { opacity: 0 },
		to: {
			opacity: 1,
		},
	});

	return (
		<>
			<div className={styles.main}>
				<LoginTabs loginStep={loginStep} />
				<animated.div
					style={{
						opacity: opacity,
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Login loginStep={loginStep} setLoginStep={setLoginStep} />
					<p className={styles.conditions} onClick={() => setShowTermsModal(true)}>
						Conditions générales d`utilisation
					</p>
				</animated.div>
			</div>
			<TermsModal modalVisible={showTermsModal} onClick={() => setShowTermsModal(false)} />
			<HoldInfoModal />
		</>
	);
};

export default LoginPage;
