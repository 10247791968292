import { useState } from 'react';

import styles from './ChangePasswordSentModal.module.scss';
import { ReactComponent as LogoSuccess } from '../../../assets/success/message.svg';
import { ReactComponent as Logo } from '../../../assets/logo_black.svg';

import ModalPortal from '../../common/ModalPortal';
import { ModalProps } from '../types';
import { BottomModal } from '../../common/BottomModal/BottomModal';

function ChangePasswordSentModal({ onResetPassword, handleCloseModal }: ModalProps) {
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	return (
		<ModalPortal>
			<div className={styles.container}>
				<div className={styles.header}>
					<Logo />
				</div>
				<div className={styles.content}>
					<LogoSuccess />
					<h1 className={styles.title}>Vérifiez votre boîte mail</h1>
					<p className={styles.info}>
						Nous vous avons envoyé un e-mail avec des instructions supplémentaires pour
						créer un nouveau mot de passe. S&apos;il n&apos;y a pas de message, pensez à
						vérifier vos spams.
					</p>
					<p className={styles.info}>
						Vous n`avez rien reçu ?
						<a
							onClick={() => {
								onResetPassword();
								setShowConfirmModal(true);
							}}
						>
							Envoyer à nouveau un e-mail
						</a>
					</p>
					<p className={styles.return} onClick={handleCloseModal}>
						Retour
					</p>
				</div>
			</div>
			<BottomModal modalVisible={showConfirmModal} title="Envoyer à nouveau un e-mail">
				<BottomModal.CancelButton
					text="J'ai compris"
					onClick={() => setShowConfirmModal(false)}
				/>
			</BottomModal>
		</ModalPortal>
	);
}

export default ChangePasswordSentModal;
