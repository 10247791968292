import classNames from 'classnames';
import { forwardRef, ReactNode, UIEventHandler } from 'react';

import styles from './ScrollView.module.scss';

import { EndReachedHandler, useEndReached } from '../../hooks/useEndReached';
import { useForwardedRef } from '../../hooks/useForwardedRef';
import { useStableCallback } from '../../hooks/useStableCallback';

type ScrollViewProps = Omit<JSX.IntrinsicElements['div'], 'children' | 'ref'> & {
	onEndReached?: EndReachedHandler<HTMLDivElement>;
	endThreshold?: number;
	endPosition?: 'bottom' | 'top';
	children: ReactNode;
};

export const ScrollView = forwardRef<HTMLDivElement, ScrollViewProps>(function ScrollView(
	{ onEndReached, endThreshold, endPosition, onScroll, className, children, ...divProps },
	ref
) {
	const [divRef, setDivRef] = useForwardedRef(ref);
	const { onScroll: detectEnd } = useEndReached(divRef, onEndReached, endThreshold, endPosition);
	const handleScroll = useStableCallback<UIEventHandler<HTMLDivElement>>(event => {
		onScroll?.(event);
		if (event.isDefaultPrevented()) return;
		detectEnd(event);
	});

	return (
		<div
			{...divProps}
			ref={setDivRef}
			className={classNames(styles.container, className)}
			onScroll={handleScroll}
		>
			{children}
		</div>
	);
});
