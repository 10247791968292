import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BirdYellow } from '../../../assets/spaces/bird_yellow.svg';
import { ReactComponent as BirdGreen } from '../../../assets/spaces/bird_green.svg';
import { ReactComponent as GoButton } from '../../../assets/go_button.svg';
import styles from './CoWorkingItem.module.scss';

import { formatPlaces } from '../../../utils/formatters';
import { ICoWorking } from '../../../network/types';
import AddressLink from '../../common/AddressLink/AddressLink';
import { ROUTES } from '../../../config/routes';
import { Image } from '../../common/Image/Image';

type CoWorkingItemProps = {
	coWorking: ICoWorking;
};

const CoWorkingItem = ({ coWorking }: CoWorkingItemProps) => {
	const navigate = useNavigate();
	const handleOpenCoWorking = useCallback(() => {
		navigate(ROUTES.CO_WORKING(coWorking.id));
	}, [coWorking.id, navigate]);

	return (
		<div className={styles.coworking} onClick={handleOpenCoWorking}>
			<div className={styles.coworking__image}>
				<Image src={coWorking.photos?.[0]} />
				<GoButton className={styles.coworking__goButton} />
			</div>
			<p className={styles.coworking__name}>{coWorking.title}</p>
			<AddressLink
				className={styles.coworking__address}
				coWorkingId={coWorking.id}
				link={coWorking.addressLink}
				address={coWorking.address}
			/>
			<ul className={styles.coworking__info}>
				{coWorking.openspace?.capacity ? (
					<li>
						<BirdYellow />
						<span className={styles.yellow}>Open-space:</span>&nbsp;
						{formatPlaces(coWorking.openspace.capacity)}
					</li>
				) : null}
				{coWorking.rooms.map(room => (
					<li key={room.id}>
						<BirdGreen /> <span className={styles.green}>{room.title}:</span>
						&nbsp;Pour {room.capacity} personnes
					</li>
				))}
				{/* <li>
						<BirdBlue />
						<span className={styles.blue}>Coworking:</span>&nbsp;X place
					</li> */}
			</ul>
		</div>
	);
};

export default CoWorkingItem;
