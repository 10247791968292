/* eslint-disable no-useless-escape */
export const passwordValidator = (password: string) => {
	if (!password.length) return true;
	//password must contain at least 8 characters, number, lower and uppercase letter, special character
	//^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'*+\-\/=?^_`{|.])[A-Za-z\d!#$%&'*+\-\/=?^_`{|.]{8,64}$
	const regex =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'*+-\/=?^_`±§{}|.,\[\]@\(\);:"\\~<>€”₴£•])[A-Za-z\d!#$%&'*+-\/=?^_`±§{}|.,\[\]@\(\);:"\\~<>€”₴£•]{8,30}$/;
	return regex.test(password);
};

export const emailValidator = (email: string) => {
	if (!email.length) return true;
	const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

	return regex.test(email);
};

export const nameValidator = (firstName: string) => {
	if (!firstName.length) return true;
	const regex = /^(?=.*[a-zA-Z])[a-zA-Z-]{0,50}$/;

	return regex.test(firstName);
};

export const phoneValidator = (phone: string) => {
	const regex = /^[0-9]{0,11}$/;

	return regex.test(phone);
};

export const checkedValidator = (value: boolean) => !!value;
