import classNames from 'classnames';
import { CSSProperties } from 'react';

import styles from './FetchMoreIndicator.module.scss';

type FetchMoreIndicatorProps = Omit<JSX.IntrinsicElements['span'], 'children' | 'ref'> & {
	size?: CSSProperties['width'];
	firstColor?: CSSProperties['color'];
	secondColor?: CSSProperties['color'];
};

export function FetchMoreIndicator({
	size = 10,
	firstColor = '#82A268',
	secondColor = '#82A26866',
	...spanProps
}: FetchMoreIndicatorProps) {
	return (
		<div
			className={styles.container}
			style={
				{
					'--size': typeof size === 'number' ? `${size}px` : size,
					'--first-color': firstColor,
					'--second-color': secondColor,
				} as never
			}
		>
			<span {...spanProps} className={classNames(styles.loader)}></span>
		</div>
	);
}
