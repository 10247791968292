import { useState } from 'react';

import styles from './RecoveryForm.module.scss';

import { FORGOT_PASSWORD } from '../../config/endpoints';
import { FormValidatorScheme, useForm } from '../../hooks/useForm';
import { Method, request } from '../../network/request';
import { emailValidator } from '../../utils/validators';
import ActivityIndicator from '../common/ActivityIndicator/ActivityIndicator';
import Button from '../common/Button/Button';
import Input from '../common/Input/Input';
import SuccessReset from '../modals/SuccessReset/SuccessReset';

type RecoveryFormValues = {
	email: string;
};
const recoveryFormValidator: FormValidatorScheme<RecoveryFormValues> = {
	email: email =>
		(emailValidator(email) && !!email) || 'Merci d’indiquer une adresse e-mail valide',
};

export function RecoveryForm() {
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const {
		values,
		renderError,
		handleInputChange,
		handleInputFocus,
		handleInputBlur,
		handleSubmit,
		submit,
		submitAction: [submitState],
		disableForm,
		disableSubmit,
	} = useForm<RecoveryFormValues>({
		initialValues: { email: '' },
		validator: recoveryFormValidator,
		onSubmit: async (data, signal) => {
			await request(FORGOT_PASSWORD, { method: Method.POST, data, signal });
			setShowSuccessModal(true);
		},
	});

	return (
		<div className={styles.container}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.header}>
					<h1>Modification du mot de passe</h1>
				</div>
				<Input
					type="email"
					autoComplete="username"
					label="E-mail"
					placeholder="Écrivez votre e-mail"
					className={styles.email}
					name="email"
					value={values.email}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('email')}
				/>
				<div className={styles.submit}>
					<Button
						type="submit"
						disabled={disableSubmit}
						text={
							submitState.status === 'pending' ? (
								<ActivityIndicator contrast />
							) : (
								'Confirmer'
							)
						}
					/>
				</div>
			</form>
			{showSuccessModal && (
				<SuccessReset
					email={values.email}
					handleCloseModal={() => setShowSuccessModal(false)}
					onResetPassword={submit}
				/>
			)}
		</div>
	);
}
