import classNames from 'classnames';

import styles from './ReservationDateTime.module.scss';

import { IReservation } from '../../network/types';
import { formatServerTimeRange } from '../../utils/formatTime';
import moment from 'moment';
import { DATE_FORMAT } from '../../config/constants';

type ReservationDateTimeProps = Omit<JSX.IntrinsicElements['div'], 'children'> & {
	reservation: IReservation;
};

export function ReservationDateTime({
	reservation,
	className,
	...divProps
}: ReservationDateTimeProps) {
	return (
		<div {...divProps} className={classNames(styles.container, className)}>
			<div>
				<p className={styles.title}>Date</p>
				<p className={styles.value}>{moment(reservation.from).format(DATE_FORMAT)}</p>
			</div>
			<div className={styles.divider} />
			<div>
				<p className={styles.title}>Horaires</p>
				<p className={styles.value}>
					<span>{formatServerTimeRange(reservation)}</span>
				</p>
			</div>
		</div>
	);
}
