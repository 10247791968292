import classNames from 'classnames';

import { ReactComponent as Map } from '../../../assets/spaces/map.svg';

import styles from './AddressLink.module.scss';

type AddressLinkProps = {
	coWorkingId: number;
	link?: string;
	address?: string;
} & JSX.IntrinsicElements['p'];

const links = {
	1: 'https://goo.gl/maps/WyVqMizS7De39VJY9',
	5: 'https://maps.app.goo.gl/uBoUunNQiw2RgzJU8',
};

const AddressLink = ({ coWorkingId, link, address, className, ...pProps }: AddressLinkProps) => {
	const href =
		link ??
		(coWorkingId !== undefined ? links[coWorkingId] : undefined) ??
		`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

	return (
		<p {...pProps} className={classNames(styles.addressLink, className)}>
			<Map />
			<a onClick={e => e.stopPropagation()} href={href} target="_blank" rel="noreferrer">
				{address}
			</a>
		</p>
	);
};

export default AddressLink;
