import { IPagination, IPaginationState } from '../../network/types';

export function storeEntities<T extends { id: string | number }>(
	entities: T[],
	state: Record<string, T>
): Record<string, T> {
	return entities.reduce((map, entity) => {
		map[entity.id] = entity;
		return map;
	}, state);
}

export function storePage<T extends string | number>(
	state: IPaginationState<T> | undefined,
	pagination: IPagination,
	getId: (index: number) => T
): IPaginationState<T> {
	const ids = state?.ids?.slice(0, pagination.skip) ?? [];
	for (let i = 0; i < pagination.count; i += 1) {
		ids[pagination.skip + i] = getId(i);
	}

	return { ...state, ...pagination, ids };
}
