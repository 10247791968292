import { IPaginatedState, IReservation, Tabs } from '../../network/types';
import { RootState } from '../store';

export const reservationsPageSelector = (
	state: RootState,
	tab: Tabs
): IPaginatedState<IReservation> => {
	const {
		pagination: { [tab]: pagination },
	} = state.reservations;
	const items = pagination?.ids.map(id => state.reservations.items[id]) ?? [];
	return { items, pagination };
};

export const reservationSelector = (state: RootState, id: string) => state.reservations.items[id];

export const reservationsOnlyOneSelector = (state: RootState) => {
	return Object.keys(state.reservations.items).length === 1;
};
