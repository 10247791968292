import { RootState } from '../store';

export const userSelector = (state: RootState) => {
	const {
		id = '',
		email = '',
		firstName = '',
		lastName = '',
		phone = '',
		externalProfiles = [],
	} = state.user.info ?? {};
	return { id, email, firstName, lastName, phone, externalProfiles };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const tokenSelector = (_state: RootState) => {
	const token = localStorage.getItem('token');
	return token;
};
