import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
	createMigrate,
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
	createTransform,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { bookHoldingMiddleware } from './middlewares/bookHoldingMiddleware';
import { userLoaderMiddleware } from './middlewares/userLoaderMiddleware';
import coWorkings, { transformCoWorkingsIn } from './slice/coWorkingsSlice';
import reservations, { transformReservationsIn } from './slice/reservationsSlice';
import user from './slice/userSlice';

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
const migrations = createMigrate({
	1: state => ({ ...state }),
	2: state => ({ ...state, reservationsReducer: { reservations: {} } }),
	3: state => ({ ...state, reservationsReducer: { reservations: {} } }),
	4: state => ({ ...state, coworkingReducer: {} }),
	5: state => ({
		...state,
		coworkingReducer: undefined,
		coWorkingReducer: { coWorkings: {} },
	}),
	6: ({ loadingReducer, ...state }: any) => state,
	7: state => ({ ...state, reservationsReducer: { reservations: {} } }),
	8: state => ({
		_persist: state._persist,
		user: { info: {} },
		coWorkings: { coWorkings: {} },
		reservations: { reservations: {} },
	}),
	9: state => ({
		_persist: state._persist,
		user: { info: {} },
		coWorkings: { coWorkings: {} },
		reservations: { reservations: {} },
	}),
	10: state => ({
		_persist: state._persist,
		user: { info: {} },
		coWorkings: { items: {} },
		reservations: { reservations: {} },
	}),
	11: state => ({
		_persist: state._persist,
		...state,
		reservations: { items: (state as any).reservations.reservations },
	}),
	12: state => ({ ...state, reservations: { ...(state as any).reservations, pagination: {} } }),
});
/* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

const transformUrls = createTransform(
	(state: unknown, key) => {
		switch (key) {
			case 'coWorkings':
				return transformCoWorkingsIn(state as never) as never;
			case 'reservations':
				return transformReservationsIn(state as never) as never;
			default:
				return state;
		}
	},
	state => state,
	{ whitelist: ['coWorkings', 'reservations'] }
);

const rootReducer = persistReducer(
	{
		key: 'root',
		version: 12,
		storage,
		migrate: migrations,
		writeFailHandler: error => {
			console.error('Failed to store persistent state:', error);
		},
		transforms: [transformUrls as never],
	},
	combineReducers({
		user,
		coWorkings,
		reservations,
	})
);

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		})
			.concat(bookHoldingMiddleware)
			.concat(userLoaderMiddleware),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export default store;
