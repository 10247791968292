import moment from 'moment';
import { DAY_END } from './config';
import { Method, request } from '../../network/request';
import { GET_ROOM_RESERVATIONS } from '../../config/endpoints';
import { parseTimeToMinutes } from '../../utils/formatTime';
import { MinutesTimeRange } from '../../network/types';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../config/constants';
import axios from 'axios';

export function isTimeSlotDisabled(range: MinutesTimeRange, availableSlots: MinutesTimeRange[]) {
	for (let i = 0, length = availableSlots.length; i < length; i += 1) {
		const available = availableSlots[i];
		if (range.from >= available.from && range.to <= available.to) {
			return false;
		}
	}
	return true;
}

export function firstAvailableTimeSlot(available: MinutesTimeRange[]): MinutesTimeRange {
	for (let i = 0, length = available.length; i < length; i += 1) {
		const slot = available[i];
		const from = Math.max(slot.from, 0);
		const to = from + 15;
		if (to <= slot.to) {
			return { from, to };
		}
	}
	return undefined;
}

async function getRoomAvailability(
	coWorkingId: number,
	roomId: number,
	date: string,
	offset: number
) {
	try {
		const response = await request(GET_ROOM_RESERVATIONS(coWorkingId, roomId), {
			method: Method.GET,
			params: { date },
		});
		const available = response.data.map(slot => {
			const from = slot.from === '23:59' ? DAY_END : parseTimeToMinutes(slot.from);
			const to = slot.from === '23:59' ? DAY_END : parseTimeToMinutes(slot.to);

			return { from: from + offset, to: to + offset };
		});
		return available;
	} catch (error) {
		if (
			axios.isAxiosError(error) &&
			(error.response?.data.status === 404 ||
				error.response?.data.title === 'Coworking already closed' ||
				error.response?.data.status === 403 ||
				error.response?.data.title === 'The date is out of range')
		) {
			return [];
		}
		return [{ from: offset, to: DAY_END + offset }];
	}
}

export async function loadAvailableSlots(coWorkingId: number, roomId: number, selected: string) {
	const now = moment();
	const selectedDate = moment(selected, DATE_FORMAT).format('YYYY-MM-DD');

	const from = moment(selected + '00:00', DATE_TIME_FORMAT).utc(false);
	const to = moment(selected + '23:59', DATE_TIME_FORMAT).utc(false);

	const fromDate = from.clone().format('YYYY-MM-DD');
	const toDate = to.clone().format('YYYY-MM-DD');
	const isSameDay = fromDate === toDate;

	const isFromSelected = selectedDate === fromDate;

	const offset = now.utcOffset();
	const fromOffset = isFromSelected ? offset : offset - DAY_END;
	const toOffset = isFromSelected ? offset + DAY_END : offset;

	const promises: Promise<MinutesTimeRange[]>[] = [];
	promises.push(getRoomAvailability(coWorkingId, roomId, fromDate, fromOffset));
	if (!isSameDay) {
		promises.push(getRoomAvailability(coWorkingId, roomId, toDate, toOffset));
	}
	const responses = await Promise.all(promises);
	const available = responses.flat(1).sort((a, b) => a.from - b.from);

	return available;
}
