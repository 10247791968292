import classNames from 'classnames';

import styles from './CancelButton.module.scss';
import { ReactNode } from 'react';

type CancelButtonProps = Omit<JSX.IntrinsicElements['p'], 'ref' | 'children'> & {
	text: ReactNode;
};

export function CancelButton({ text, className, ...pProps }: CancelButtonProps) {
	return (
		<p {...pProps} className={classNames(styles.cancelButton, className)}>
			{text}
		</p>
	);
}
