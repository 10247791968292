/* eslint-disable @typescript-eslint/ban-types */
import {
	ICoWorking,
	ICoWorkingRoom,
	IPaginatedResponse,
	IPaginationParams,
	IReservation,
	TimeRange,
} from '../network/types';

export type Endpoint<L, P, R> = L & { __params__: P; __response__: R };
export type EndpointParams<E extends string | Endpoint<string, any, any>> = E extends {
	__params__: infer P;
}
	? P
	: object | void;
export type EndpointResult<E extends string | Endpoint<string, any, any>> = E extends {
	__response__: infer R;
}
	? R
	: unknown;
const endpoint =
	<P, T>() =>
	<L extends string>(endpoint: L) =>
		endpoint as Endpoint<L, P, T>;

// AUTH
export const LOGIN = '/auth/sign-in';
export const SIGN_UP = '/auth/sign-up';
export const FORGOT_PASSWORD = '/auth/forgotPassword';
export const VERIFY_EMAIL = endpoint<{ token: string }, { data: { access_token: string } }>()(
	'/auth/verifyEmail'
);
export const LOG_OUT = '/auth/logout';
export const REFRESH = '/auth/refresh';
export const RESEND_EMAIL = '/auth/sign-up/resend-email';

export const LINKEDIN_LOGIN = '/auth/linkedin-login';
export const GOOGLE_LOGIN = '/auth/google-login';
export const FACEBOOK_LOGIN = '/auth/facebook-login';

// COWORKING
export const COWORKINGS_LIST = endpoint<IPaginationParams, IPaginatedResponse<ICoWorking>>()(
	'/coworkings'
);
export const COWORKING = (coWorkingId: string | number) =>
	endpoint<void, { data: ICoWorking }>()(`${COWORKINGS_LIST}/${String(coWorkingId)}`);
export const COWORKING_ROOM = (coWorkingId: string | number, roomId: string | number) =>
	endpoint<void, ICoWorkingRoom>()(`${COWORKING(coWorkingId)}/rooms/${roomId}`);
export const GET_ROOM_RESERVATIONS = (coWorkingId: number, roomId: number) =>
	endpoint<{ date: string }, { data: TimeRange[] }>()(
		`${COWORKING_ROOM(coWorkingId, roomId)}/availability`
	);
export const BOOK_COWORKING_ROOM = (coWorkingId: number, roomId: number) =>
	endpoint<
		{
			from: string;
			to: string;
			timezoneOffset: number;
		},
		{ data: IReservation } | undefined
	>()(`${COWORKING_ROOM(coWorkingId, roomId)}/book`);

// RESERVATION
export const RESERVATIONS_LIST = endpoint<
	{
		type?: 'active' | 'passed';
		sortOrder?: 'ASC' | 'DESC';
	} & IPaginationParams,
	IPaginatedResponse<IReservation>
>()('/orders');
export const RESERVATION = (orderId: string) =>
	endpoint<void, { data: IReservation }>()(`${RESERVATIONS_LIST}/${orderId}`);
export const CANCEL_RESERVATION = (orderId: string) => `${RESERVATION(orderId)}/cancel`;
export const FINISH_RESERVATION = (orderId: string) => `${RESERVATION(orderId)}/finish`;

// SESSION
export const START_SESSION = (coWorkingId: number, roomId: number) =>
	`${COWORKING_ROOM(coWorkingId, roomId)}/startSession`;
export const END_SESSION = (coWorkingId: number, roomId: number) =>
	`${COWORKING_ROOM(coWorkingId, roomId)}/endSession`;
export const OPEN_ROOM_DOOR = (coWorkingId: number, roomId: number) =>
	`${COWORKING_ROOM(coWorkingId, roomId)}/open`;
export const CLOSE_ROOM_DOOR = (coWorkingId: number, roomId: number) =>
	`${COWORKING_ROOM(coWorkingId, roomId)}/close`;
export const PROLONGATE = (orderId: string) => `/orders/${orderId}/prolongate`;

// USER
export const USER_INFO = '/users/me';
export const USER_EMAIL_CHANGE = '/users/me/changeEmail';
export const USER_PASSWORD_CHANGE = '/users/me/changePassword';
export const CONNECT_ACCOUNT = (social: string) => `/users/me/connect-account?service=${social}`;
