import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { ReactComponent as LeftArrow } from '../../assets/datepicker/left.svg';
import { ReactComponent as RightArrow } from '../../assets/datepicker/right.svg';
import { ReactComponent as BottomArrow } from '../../assets/datepicker/bottom.svg';
import css from './DateSelect.module.scss';

import 'react-datepicker/dist/react-datepicker.css';
import { DATE_FORMAT } from '../../config/constants';

interface IProps {
	selectedDate: string;
	onChange: (date: string) => void;
}

export const DateSelect: FC<IProps> = ({ selectedDate, onChange }) => {
	const date = useMemo(() => moment(selectedDate, DATE_FORMAT), [selectedDate]);

	const setSelectedDate = useCallback(
		(next: moment.Moment) => {
			onChange?.(next.format(DATE_FORMAT));
		},
		[onChange]
	);

	const selectedDateFormat = date.format('MMM YYYY').split(' ');

	const onPrevClick = () => {
		const dateToSet = date.clone().subtract(1, 'month');
		if (!dateToSet.isBefore(date, 'day')) {
			setSelectedDate(dateToSet);
		} else {
			setSelectedDate(moment());
		}
	};

	const days = useMemo(() => {
		const daysBefore = Array.from({ length: 3 }).map((_, i) => {
			const instance = date.clone().subtract(3 - i, 'd');

			const day = instance.format('ddd DD');
			const parts = day.split(' ');

			return {
				day: parts[0],
				instance,
				disabled: instance.isBefore(moment(), 'day'),
				selected: false,
				number: parts[1],
			};
		});
		const daysAfter = Array.from({ length: 3 }).map((_, i) => {
			const instance = date.clone().add(i + 1, 'd');
			const day = instance.format('ddd DD');
			const parts = day.split(' ');

			return {
				day: parts[0],
				instance,
				disabled: false,
				selected: false,
				number: parts[1],
			};
		});

		const todayInstance = date.clone();
		const today = todayInstance.format('ddd DD').split(' ');

		return [
			...daysBefore,
			{
				selected: true,
				day: today[0],
				disabled: false,
				instance: todayInstance,
				number: today[1],
			},
			...daysAfter,
		];
	}, [selectedDate]);

	return (
		<>
			<div className={css.header}>
				<LeftArrow onClick={onPrevClick} />
				<div className={css.header__title}>
					<DatePicker
						showYearDropdown
						minDate={new Date()}
						selected={date.toDate()}
						onChange={date => setSelectedDate(moment(date))}
						customInput={
							<div>
								<span>{selectedDateFormat.join(' ')}</span>
								<BottomArrow />
							</div>
						}
					/>
				</div>
				<RightArrow onClick={() => setSelectedDate(date.clone().add(1, 'month'))} />
			</div>
			<div className={css.dayList}>
				{days.map(day => (
					<div
						key={day.day}
						className={cn(
							css.dayList__day,
							day.selected && css['dayList__day--selected'],
							day.disabled && css['dayList__day--disabled']
						)}
						onClick={() => setSelectedDate(moment(day.instance))}
					>
						<span>{day.day}</span>
						<span>{day.number}</span>
					</div>
				))}
			</div>
		</>
	);
};
