import classNames from 'classnames';

import styles from './CloseButton.module.scss';
import { ReactComponent as Close } from '../../../assets/close.svg';

type CloseButtonProps = Omit<JSX.IntrinsicElements['button'], 'ref' | 'children'>;

export function CloseButton({ className, ...buttonProps }: CloseButtonProps) {
	return (
		<button {...buttonProps} className={classNames(styles.closeButton, className)}>
			<Close />
		</button>
	);
}
