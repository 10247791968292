import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

import InputContainer, { InputContainerProps } from './InputContainer';
import { useInputId } from './useInputId';

type InputProps = Omit<InputContainerProps, 'className' | 'children'> &
	Omit<JSX.IntrinsicElements['input'], 'className' | 'children'> & {
		label?: ReactNode;
		className?:
			| string
			| {
					wrapper?: string;
					container?: string;
					label?: string;
					input?: string;
			  };
		before?: ReactNode;
		after?: ReactNode;
	};

function Input({
	id: propId,
	label,
	error,
	info,
	className: propClassName,
	before,
	after,
	hideErrorContainer,
	...inputProps
}: InputProps) {
	const id = useInputId(propId);
	const className =
		typeof propClassName === 'string' ? { wrapper: propClassName } : propClassName;

	return (
		<InputContainer
			info={info}
			error={error}
			className={{
				container: classNames(styles.container, className?.container),
				wrapper: className?.wrapper,
			}}
			hideErrorContainer={hideErrorContainer}
		>
			{before}
			<input {...inputProps} className={className?.input} id={id} />
			<label htmlFor={id} className={className?.label}>
				{label}
			</label>
			{after}
		</InputContainer>
	);
}

export default Input;
