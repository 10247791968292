import { animated, useSpring } from '@react-spring/web';
import ReactDOM from 'react-dom';
import styles from './PrivacyModal.module.scss';
import { ReactComponent as Arrow } from '../../../assets/back_arrow.svg';
import { ReactComponent as Logo } from '../../../assets/logo_full_colored.svg';
import Wrapper from '../Wrapper/Wrapper';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';

const PrivacyModal = ({ modalVisible, onClick }) => {
	const { opacity, transform, ...rest } = useSpring({
		from: { opacity: 0, transform: `translateX(100%)` },
		to: {
			opacity: modalVisible ? 1 : 0,
			transform: modalVisible ? `translateX(0%)` : `translateX(100%)`,
		},
	});

	return ReactDOM.createPortal(
		<animated.div
			style={{ ...rest, opacity: opacity, transform: transform }}
			className={styles.modal}
		>
			<Wrapper>
				<div className={styles.modal__header}>
					<div className={styles.arrow}>
						<Arrow onClick={onClick} />
					</div>
					<Link to={ROUTES.CO_WORKINGS}>
						<Logo />
					</Link>
				</div>
				<h1 className={styles.modal__title}>
					Mentions légales et politique de confidentialité
				</h1>
				<div className={styles.modal__content}>
					<p>
						La soci&eacute;t&eacute;&nbsp;Work&amp;Go,&nbsp;soucieuse&nbsp;des droits
						des individus, notamment au regard des traitements
						automatis&eacute;s&nbsp;et dans une volont&eacute; de transparence avec ses
						clients, a mis en place une politique reprenant l&rsquo;ensemble de ces
						traitements, des finalit&eacute;s poursuivies par ces derniers ainsi que des
						moyens d&rsquo;actions &agrave; la disposition des individus afin
						qu&rsquo;ils puissent au mieux exercer leurs droits.&nbsp;
					</p>

					<p>
						Pour toute information compl&eacute;mentaire sur la protection des
						donn&eacute;es personnelles, nous vous invitons &agrave; consulter le
						site&nbsp;:&nbsp;<a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
					</p>

					<p>
						La poursuite de la navigation sur ce site vaut acceptation sans
						r&eacute;serve des dispositions et conditions d&#39;utilisation qui
						suivent.&nbsp;
					</p>

					<p>
						La version actuellement en ligne de ces conditions d&#39;utilisation est la
						seule opposable pendant toute la dur&eacute;e d&#39;utilisation du site et
						jusqu&#39;&agrave; ce qu&#39;une nouvelle version la remplace.
					</p>
					<br />
					<p>
						<strong>Article 1 - Mentions&nbsp;l&eacute;gales</strong>
					</p>
					<p>
						1.1&nbsp;Site (ci-apr&egrave;s &laquo;&nbsp;le
						site&nbsp;&raquo;)&nbsp;:&nbsp;
					</p>

					<p>
						<a href="https://reserve.workandgo.eu/">https://reserve.workandgo.eu/</a>
					</p>

					<p>
						1.2&nbsp;&Eacute;diteur (ci-apr&egrave;s
						&laquo;&nbsp;l&#39;&eacute;diteur&nbsp;&raquo;)&nbsp;:&nbsp;
					</p>

					<p>Work&amp;Go&nbsp;SAS&nbsp;au capital de&nbsp;1 000&nbsp;&euro;</p>

					<p>
						dont le si&egrave;ge social est situ&eacute; :&nbsp;40 rue Alexandre Dumas
						&nbsp;75011 Paris &nbsp;
					</p>

					<p>
						repr&eacute;sent&eacute;e par&nbsp;EFIVOS SARL, en sa qualit&eacute;
						de&nbsp;Pr&eacute;sident
					</p>

					<p>immatricul&eacute;e au RCS de&nbsp;PARIS 918312018</p>

					<p>n&deg; de t&eacute;l&eacute;phone&nbsp;:&nbsp;0627742620</p>

					<p>
						adresse mail :&nbsp;<a href="mailto:info@workandgo.eu">info@workandgo.eu</a>
					</p>

					<p>
						1.3&nbsp;H&eacute;bergeur (ci-apr&egrave;s &laquo; l&#39;h&eacute;bergeur
						&raquo;)&nbsp;:&nbsp;
					</p>

					<p>
						<a href="https://reserve.workandgo.eu/">https://reserve.workandgo.eu/</a>
						&nbsp;est h&eacute;berg&eacute; par&nbsp;Amazon technologies Inc, dont le
						si&egrave;ge social est situ&eacute;&nbsp;Amazon HQ, Burlington Rd&nbsp;D04
						HH21Dublin 4.
						<br />
						&nbsp;
					</p>

					<p>
						<strong>Article 2 -&nbsp;Acc&egrave;s au site</strong>
					</p>
					<p>
						L&#39;acc&egrave;s au site et son utilisation sont r&eacute;serv&eacute;s
						&agrave; un usage strictement personnel. Vous vous engagez &agrave; ne pas
						utiliser ce site et les informations ou donn&eacute;es qui y figurent
						&agrave; des fins commerciales, politiques, publicitaires et pour toute
						forme de sollicitation commerciale et notamment l&#39;envoi de courriers
						&eacute;lectroniques non sollicit&eacute;s.
					</p>
					<br />
					<p>
						<strong>Article 3 -&nbsp;Contenu du site</strong>
					</p>
					<p>
						Toutes les marques, photographies, textes, commentaires, illustrations,
						images anim&eacute;es ou non, s&eacute;quences vid&eacute;o, sons, ainsi que
						toutes les applications informatiques qui pourraient &ecirc;tre
						utilis&eacute;es pour faire fonctionner ce site et plus
						g&eacute;n&eacute;ralement tous les &eacute;l&eacute;ments reproduits ou
						utilis&eacute;s sur le site sont prot&eacute;g&eacute;s par les lois en
						vigueur au titre de la propri&eacute;t&eacute; intellectuelle.
					</p>

					<p>
						Ils sont la propri&eacute;t&eacute; pleine et enti&egrave;re de
						l&#39;&eacute;diteur ou de ses partenaires. Toute reproduction,
						repr&eacute;sentation, utilisation ou adaptation, sous quelque forme que ce
						soit, de tout ou partie de ces &eacute;l&eacute;ments, y compris les
						applications informatiques, sans l&#39;accord pr&eacute;alable et
						&eacute;crit de l&#39;&eacute;diteur, sont strictement interdites. Le fait
						pour l&#39;&eacute;diteur de ne pas engager de proc&eacute;dure d&egrave;s
						la prise de connaissance de ces utilisations non autoris&eacute;es ne vaut
						pas acceptation desdites utilisations et renonciation aux poursuites.
					</p>
					<br />
					<p>
						<strong>Article 4 -&nbsp;Gestion du site</strong>
					</p>
					<p>
						Pour la bonne gestion du site, l&#39;&eacute;diteur pourra &agrave; tout
						moment&nbsp;:
					</p>

					<p>
						-&nbsp;&nbsp;suspendre, interrompre ou limiter l&#39;acc&egrave;s &agrave;
						tout ou partie du site, r&eacute;server l&#39;acc&egrave;s au site, ou
						&agrave; certaines parties du site, &agrave; une cat&eacute;gorie
						d&eacute;termin&eacute;e d&#39;internautes ;
					</p>

					<p>
						-&nbsp;&nbsp;supprimer toute information pouvant en perturber le
						fonctionnement ou entrant en contravention avec les lois nationales ou
						internationales ;
					</p>

					<p>
						-&nbsp;&nbsp;suspendre le site afin de proc&eacute;der &agrave; des mises
						&agrave; jour.
					</p>
					<br />
					<p>
						<strong>Article 5 -&nbsp;Responsabilit&eacute;s</strong>
					</p>
					<p>
						La responsabilit&eacute; de l&#39;&eacute;diteur ne peut &ecirc;tre
						engag&eacute;e en cas de d&eacute;faillance, panne, difficult&eacute; ou
						interruption de fonctionnement, emp&ecirc;chant l&#39;acc&egrave;s au site
						ou &agrave; une de ses fonctionnalit&eacute;s.
					</p>

					<p>
						Le mat&eacute;riel de connexion au site que vous utilisez est sous votre
						enti&egrave;re responsabilit&eacute;. Vous devez prendre toutes les mesures
						appropri&eacute;es pour prot&eacute;ger votre mat&eacute;riel et vos propres
						donn&eacute;es notamment d&#39;attaques virales par Internet. Vous
						&ecirc;tes par ailleurs&nbsp;seul responsable des sites et donn&eacute;es
						que vous consultez.
					</p>

					<p>
						L&#39;&eacute;diteur ne pourra &ecirc;tre tenu responsable en cas de
						poursuites judiciaires &agrave; votre encontre :
					</p>

					<p>
						-&nbsp;&nbsp;du fait de l&#39;usage du site ou de tout service
						accessible&nbsp;via&nbsp;Internet&nbsp;;
					</p>

					<p>
						-&nbsp;&nbsp;du fait du non-respect par vous des pr&eacute;sentes conditions
						g&eacute;n&eacute;rales.
					</p>

					<p>
						L&#39;&eacute;diteur n&#39;est pas responsable des dommages caus&eacute;s
						&agrave; vous-m&ecirc;me, &agrave; des tiers et/ou &agrave; votre
						&eacute;quipement du fait de votre connexion ou de votre utilisation du site
						et vous renoncez &agrave; toute action contre lui de ce fait.
					</p>

					<p>
						Si l&#39;&eacute;diteur venait &agrave; faire l&#39;objet d&#39;une
						proc&eacute;dure amiable ou judiciaire en&nbsp;raison de votre utilisation
						du site, il pourra se retourner contre vous pour obtenir l&#39;indemnisation
						de tous les pr&eacute;judices, sommes, condamnations et frais qui pourraient
						d&eacute;couler de cette proc&eacute;dure.
					</p>

					<p>
						<br />
						<strong>Article 6 -&nbsp;Liens hypertextes</strong>
					</p>

					<p>
						La mise en place par les utilisateurs&nbsp;de tous liens hypertextes vers
						tout ou partie du site&nbsp;est autoris&eacute;e par l&#39;&eacute;diteur.
						Tout lien devra &ecirc;tre retir&eacute; sur simple demande de
						l&#39;&eacute;diteur.&nbsp;
					</p>

					<p>
						Toute information accessible&nbsp;via&nbsp;un lien vers d&#39;autres sites
						n&#39;est pas publi&eacute;e par l&#39;&eacute;diteur. L&#39;&eacute;diteur
						ne dispose d&#39;aucun droit sur le contenu pr&eacute;sent dans ledit
						lien.&nbsp;
					</p>

					<p>
						<br />
						<strong>
							Article 7 -&nbsp;Collecte et protection&nbsp;des donn&eacute;es
						</strong>
					</p>

					<p>
						Vos donn&eacute;es sont collect&eacute;es par&nbsp;la
						soci&eacute;t&eacute;&nbsp;Work&amp;Go.
					</p>

					<p>
						Une donn&eacute;e &agrave; caract&egrave;re personnel d&eacute;signe toute
						information concernant une personne physique identifi&eacute;e ou
						identifiable (personne concern&eacute;e) ; est
						r&eacute;put&eacute;e&nbsp;identifiable une personne qui peut &ecirc;tre
						identifi&eacute;e, directement ou indirectement, notamment par
						r&eacute;f&eacute;rence &agrave; un nom, un num&eacute;ro
						d&#39;identification ou &agrave; un ou plusieurs &eacute;l&eacute;ments
						sp&eacute;cifiques, propres &agrave; son identit&eacute; physique,
						physiologique, g&eacute;n&eacute;tique, psychique, &eacute;conomique,
						culturelle ou sociale.
					</p>

					<p>
						Les informations personnelles pouvant &ecirc;tre recueillies sur le site
						sont principalement utilis&eacute;es par l&#39;&eacute;diteur pour la
						gestion des relations avec vous, et le cas &eacute;ch&eacute;ant pour le
						traitement de vos commandes.&nbsp;
					</p>

					<p>Les donn&eacute;es personnelles collect&eacute;es sont les suivantes :</p>

					<p>- nom et pr&eacute;nom</p>

					<p>- adresse</p>

					<p>- adresse mail</p>
					<br />
					<p>
						<strong>
							Article 8 - Droit d&rsquo;acc&egrave;s, de rectification et de
							d&eacute;r&eacute;f&eacute;rencement de vos donn&eacute;es
						</strong>
					</p>

					<p>
						En application de la r&eacute;glementation applicable aux donn&eacute;es
						&agrave; caract&egrave;re personnel, les utilisateurs disposent des droits
						suivants&nbsp;:
					</p>

					<ul>
						<li>
							le droit d&rsquo;acc&egrave;s&nbsp;: ils peuvent exercer leur droit
							d&#39;acc&egrave;s, pour conna&icirc;tre les donn&eacute;es personnelles
							les concernant, en &eacute;crivant &agrave; l&#39;adresse
							&eacute;lectronique ci-dessous mentionn&eacute;e. Dans ce cas, avant la
							mise en &oelig;uvre de ce droit, la Plateforme peut demander une preuve
							de l&#39;identit&eacute; de l&#39;utilisateur afin d&#39;en
							v&eacute;rifier l&#39;exactitude ;
						</li>
						<li>
							le droit de rectification&nbsp;: si les donn&eacute;es &agrave;
							caract&egrave;re personnel d&eacute;tenues par la Plateforme sont
							inexactes, ils peuvent demander la mise &agrave; jour des informations ;
						</li>
						<li>
							le droit de suppression des donn&eacute;es&nbsp;: les utilisateurs
							peuvent demander la suppression de leurs donn&eacute;es &agrave;
							caract&egrave;re personnel, conform&eacute;ment aux lois applicables en
							mati&egrave;re de protection des donn&eacute;es ;
						</li>
						<li>
							le droit &agrave; la limitation du traitement&nbsp;: les utilisateurs
							peuvent de demander &agrave; la Plateforme de limiter le traitement des
							donn&eacute;es personnelles conform&eacute;ment aux hypoth&egrave;ses
							pr&eacute;vues par le RGPD ;
						</li>
						<li>
							le droit de s&rsquo;opposer au traitement des donn&eacute;es&nbsp;: les
							utilisateurs peuvent s&rsquo;opposer &agrave; ce que
							leurs&nbsp;donn&eacute;es soient trait&eacute;es conform&eacute;ment aux
							hypoth&egrave;ses pr&eacute;vues par le RGPD ;
						</li>
						<li>
							le droit &agrave; la portabilit&eacute; : ils peuvent r&eacute;clamer
							que la Plateforme leur remette les donn&eacute;es personnelles
							qu&#39;ils&nbsp;ont fournies&nbsp;pour les transmettre &agrave; une
							nouvelle Plateforme.
						</li>
					</ul>

					<p>
						Vous pouvez exercer ce droit en nous contactant, &agrave; l&rsquo;adresse
						suivante&nbsp;:
					</p>

					<p>40 rue Alexandre Dumas &nbsp;75011 Paris &nbsp;&nbsp;.</p>

					<p>Ou par email, &agrave; l&rsquo;adresse&nbsp;:</p>

					<p>
						<a href="mailto:info@workandgo.eu">info@workandgo.eu</a>&nbsp; &nbsp;&nbsp;
					</p>

					<p>
						Toute demande doit &ecirc;tre accompagn&eacute;e de la photocopie d&rsquo;un
						titre d&rsquo;identit&eacute; en cours de validit&eacute; sign&eacute; et
						faire mention de l&rsquo;adresse &agrave; laquelle l&#39;&eacute;diteur
						pourra contacter le demandeur. La r&eacute;ponse sera adress&eacute;e dans
						le mois suivant la r&eacute;ception de la demande. Ce&nbsp;d&eacute;lai
						d&#39;un mois peut &ecirc;tre prolong&eacute; de deux mois si la
						complexit&eacute; de la demande et/ou le nombre de demandes l&#39;exigent.
					</p>

					<p>
						De plus, et depuis la loi n&deg;2016-1321 du 7 octobre 2016, les personnes
						qui le souhaitent, ont la possibilit&eacute; d&rsquo;organiser le sort de
						leurs donn&eacute;es apr&egrave;s leur d&eacute;c&egrave;s. Pour plus
						d&rsquo;information sur le sujet, vous pouvez consulter le site Internet de
						la CNIL&nbsp;:&nbsp;https://www.cnil.fr/.
					</p>

					<p>
						Les utilisateurs peuvent aussi introduire une r&eacute;clamation
						aupr&egrave;s de la CNIL sur le site de la CNIL&nbsp;:&nbsp;
						<a href="https://www.cnil.fr">https://www.cnil.fr</a>.&nbsp;
					</p>

					<p>
						Nous vous recommandons&nbsp;de nous contacter&nbsp;dans un premier
						temps&nbsp;avant de d&eacute;poser une r&eacute;clamation aupr&egrave;s de
						la CNIL, car nous sommes &agrave; votre enti&egrave;re disposition pour
						r&eacute;gler votre probl&egrave;me.&nbsp;
					</p>
					<br />
					<p>
						<strong>Article 9 - Utilisation des donn&eacute;es</strong>
					</p>

					<p>
						Les donn&eacute;es personnelles collect&eacute;es aupr&egrave;s des
						utilisateurs ont pour objectif la mise &agrave; disposition des services de
						la Plateforme, leur am&eacute;lioration et le maintien d&#39;un
						environnement s&eacute;curis&eacute;. La base l&eacute;gale des traitements
						est l&rsquo;ex&eacute;cution du contrat entre l&rsquo;utilisateur et la
						Plateforme. Plus pr&eacute;cis&eacute;ment, les utilisations sont les
						suivantes&nbsp;:
					</p>

					<p>
						- acc&egrave;s et utilisation de la Plateforme par l&#39;utilisateur&nbsp;;
					</p>

					<p>- gestion du fonctionnement et optimisation de la Plateforme&nbsp;;</p>

					<p>- mise en &oelig;uvre d&#39;une assistance utilisateurs&nbsp;;</p>

					<p>
						- v&eacute;rification, identification et authentification des donn&eacute;es
						transmises par l&#39;utilisateur&nbsp;;
					</p>

					<p>
						- personnalisation des services en affichant des publicit&eacute;s en
						fonction de l&#39;historique de navigation de l&#39;utilisateur, selon ses
						pr&eacute;f&eacute;rences&nbsp;;
					</p>

					<p>
						- pr&eacute;vention et d&eacute;tection des fraudes, malwares (malicious
						softwares ou logiciels malveillants) et gestion des incidents de
						s&eacute;curit&eacute;&nbsp;;
					</p>

					<p>- gestion des &eacute;ventuels litiges avec les utilisateurs&nbsp;;</p>

					<p>
						- envoi d&#39;informations commerciales et publicitaires, en fonction des
						pr&eacute;f&eacute;rences de l&#39;utilisateur ;
					</p>

					<br />

					<p>
						<strong>Article 10 - Politique de conservation des donn&eacute;es</strong>
					</p>

					<p>
						La Plateforme conserve vos donn&eacute;es pour la dur&eacute;e
						n&eacute;cessaire pour vous fournir ses services ou
						son&nbsp;assistance.&nbsp;
					</p>

					<p>
						Dans la mesure raisonnablement n&eacute;cessaire ou requise pour satisfaire
						aux obligations l&eacute;gales ou r&eacute;glementaires, r&eacute;gler des
						litiges, emp&ecirc;cher les fraudes et abus ou appliquer nos
						modalit&eacute;s et conditions, nous pouvons &eacute;galement conserver
						certaines de vos informations si n&eacute;cessaire, m&ecirc;me apr&egrave;s
						que vous ayez ferm&eacute; votre compte ou que nous n&#39;ayons&nbsp;plus
						besoin pour vous fournir nos&nbsp;services.
					</p>

					<p>&nbsp;</p>

					<p>
						<strong>
							Article 11 - Partage des donn&eacute;es personnelles avec des tiers
						</strong>
					</p>

					<p>
						Les donn&eacute;es personnelles peuvent &ecirc;tre partag&eacute;es avec des
						soci&eacute;t&eacute;s tierces exclusivement dans l&rsquo;Union
						europ&eacute;enne, dans les cas suivants&nbsp;:
					</p>

					<p>
						- lorsque l&#39;utilisateur publie, dans les zones de commentaires libres de
						la Plateforme, des informations accessibles au public&nbsp;;
					</p>

					<p>
						- quand l&#39;utilisateur autorise le site web d&#39;un tiers &agrave;
						acc&eacute;der &agrave; ses donn&eacute;es&nbsp;;
					</p>

					<p>
						- quand la Plateforme recourt aux services de prestataires pour fournir
						l&#39;assistance utilisateurs, la publicit&eacute; et les services de
						paiement. Ces prestataires disposent d&#39;un acc&egrave;s limit&eacute; aux
						donn&eacute;es de l&#39;utilisateur, dans le cadre de l&#39;ex&eacute;cution
						de ces prestations, et ont l&#39;obligation contractuelle de les utiliser en
						conformit&eacute; avec les dispositions de la r&eacute;glementation
						applicable en mati&egrave;re protection des donn&eacute;es &agrave;
						caract&egrave;re personnel&nbsp;;
					</p>

					<p>
						- si la loi l&#39;exige, la Plateforme peut effectuer la transmission de
						donn&eacute;es pour donner suite aux r&eacute;clamations
						pr&eacute;sent&eacute;es contre la Plateforme et se conformer aux
						proc&eacute;dures administratives et judiciaires.
					</p>

					<p>&nbsp;</p>

					<p>
						<strong>Article 12&nbsp;- Offres commerciales</strong>
					</p>

					<p>
						Vous &ecirc;tes susceptible de recevoir des offres commerciales de
						l&#39;&eacute;diteur. Si vous ne le souhaitez pas, veuillez cliquer sur le
						lien suivant&nbsp;:&nbsp;
						<a href="mailto:info@workandgo.eu">info@workandgo.eu</a>.
					</p>

					<p>
						Vos donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre utilis&eacute;es par
						les partenaires de l&#39;&eacute;diteur &agrave; des fins de prospection
						commerciale, si vous ne le souhaitez pas, veuillez cliquer sur le lien
						suivant&nbsp;:&nbsp;<a href="mailto:info@workandgo.eu">info@workandgo.eu</a>
						.
					</p>

					<p>
						Si, lors de la consultation du site, vous acc&eacute;dez &agrave; des
						donn&eacute;es &agrave; caract&egrave;re personnel, vous devez vous abstenir
						de toute collecte, de toute utilisation non autoris&eacute;e et de tout acte
						pouvant constituer une atteinte &agrave; la vie priv&eacute;e ou &agrave; la
						r&eacute;putation des personnes. L&#39;&eacute;diteur d&eacute;cline toute
						responsabilit&eacute; &agrave; cet &eacute;gard.
					</p>

					<p>
						Les donn&eacute;es sont conserv&eacute;es et utilis&eacute;es pour une
						dur&eacute;e conforme &agrave; la l&eacute;gislation en vigueur.
					</p>
					<br />
					<p>
						<strong>Article 13&nbsp;-&nbsp;Cookies</strong>&nbsp;
					</p>

					<p>Qu&rsquo;est-ce qu&rsquo;un &laquo;&nbsp;cookie&nbsp;&raquo;&nbsp;?</p>

					<p>
						Un &laquo;&nbsp;Cookie&nbsp;&raquo; ou traceur est un fichier
						&eacute;lectronique d&eacute;pos&eacute; sur un terminal (ordinateur,
						tablette, smartphone,&hellip;) et lu par exemple lors de la consultation
						d&#39;un site internet, de la lecture d&#39;un courrier &eacute;lectronique,
						de l&#39;installation ou de l&#39;utilisation d&#39;un logiciel ou d&#39;une
						application mobile et ce, quel que soit le type de terminal
						utilis&eacute;&nbsp;(source&nbsp;:&nbsp;
						<a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi">
							https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi
						</a>
						).
					</p>

					<p>
						Le site peut collecter automatiquement des informations standards. Toutes
						les informations collect&eacute;es indirectement ne seront utilis&eacute;es
						que pour suivre le volume, le type et la configuration du trafic utilisant
						ce site, pour en d&eacute;velopper la conception et l&#39;agencement et
						&agrave; d&#39;autres fins administratives et de planification et plus
						g&eacute;n&eacute;ralement pour am&eacute;liorer le service que nous vous
						offrons.
					</p>

					<p>
						Le cas &eacute;ch&eacute;ant, des &laquo;&nbsp;cookies&nbsp;&raquo;
						&eacute;manant de l&#39;&eacute;diteur du site&nbsp;et/ou des
						soci&eacute;t&eacute;s tiers pourront &ecirc;tre d&eacute;pos&eacute;s sur
						votre terminal, avec votre accord. Dans ce cas, lors de la premi&egrave;re
						navigation sur ce site, une banni&egrave;re explicative sur
						l&rsquo;utilisation des &laquo;&nbsp;cookies&nbsp;&raquo; appara&icirc;tra.
						Avant de poursuivre la navigation, le client et/ou le prospect devra
						accepter ou refuser&nbsp;l&rsquo;utilisation desdits
						&laquo;&nbsp;cookies&nbsp;&raquo;. Le consentement donn&eacute; sera valable
						pour une p&eacute;riode de treize (13) mois.&nbsp;L&#39;utilisateur a la
						possibilit&eacute; de d&eacute;sactiver les cookies &agrave; tout moment.
					</p>

					<p>Les cookies suivants sont pr&eacute;sents sur ce site :&nbsp;</p>

					<p>
						<strong>Cookies Google :</strong>
					</p>

					<p>
						- Google analytics : permet de mesurer l&#39;audience du site ;<br />
						- Google tag manager : facilite l&rsquo;impl&eacute;mentation des tags sur
						les pages et permet&nbsp;de g&eacute;rer les balises Google ;<br />
						- Google Adsense : r&eacute;gie publicitaire de Google utilisant les sites
						web ou les vid&eacute;os YouTube comme support pour ses annonces ;&nbsp;
						<br />
						- Google Dynamic Remarketing : permet de vous proposer de la
						publicit&eacute; dynamique en fonction des pr&eacute;c&eacute;dentes
						recherches ;&nbsp;
						<br />
						- Google Adwords Conversion : outil de suivi des campagnes publicitaires
						adwords ;&nbsp;
						<br />- DoubleClick : cookies publicitaires de Google pour diffuser des
						banni&egrave;res.
					</p>

					<p>La&nbsp;dur&eacute;e de vie de ces cookies est de treize mois.</p>
					<br />
					<p>
						<strong>
							Article 14&nbsp;-&nbsp;Photographies et repr&eacute;sentation des
							produits
						</strong>
					</p>
					<p>
						Les photographies de produits, accompagnant leur description, ne sont pas
						contractuelles et n&#39;engagent pas l&#39;&eacute;diteur.
					</p>

					<br />
					<p>
						<strong>Article 15&nbsp;-&nbsp;Loi applicable</strong>{' '}
					</p>
					<p>
						Les pr&eacute;sentes conditions d&#39;utilisation du site sont r&eacute;gies
						par la loi fran&ccedil;aise et soumises &agrave; la comp&eacute;tence des
						tribunaux du si&egrave;ge social de l&#39;&eacute;diteur, sous
						r&eacute;serve d&#39;une attribution de comp&eacute;tence sp&eacute;cifique
						d&eacute;coulant d&#39;un texte de loi ou r&eacute;glementaire particulier.
					</p>

					<p>
						<br />
						<strong>Article 16&nbsp;-&nbsp;Contactez-nous</strong>
					</p>
					<p>
						Pour toute question, information sur les produits pr&eacute;sent&eacute;s
						sur le site, ou concernant le site lui-m&ecirc;me, vous pouvez laisser un
						message &agrave; l&#39;adresse suivante&nbsp;:&nbsp;
						<a href="mailto:info@workandgo.eu">info@workandgo.eu</a>.&nbsp; &nbsp;
						&nbsp;
					</p>

					<p>&nbsp;</p>

					<p>&nbsp;</p>
				</div>
			</Wrapper>
		</animated.div>,
		document.getElementById(`root`)
	);
};

export default PrivacyModal;
