import { Routes, RoutesProps, useLocation } from 'react-router-dom';
import { useTransition, animated } from '@react-spring/web';

import styles from './AnimatedRoutes.module.scss';

import { useLocationAnimationConfig } from './useLocationAnimationConfig';
import Wrapper from '../common/Wrapper/Wrapper';

function AnimatedRoutes(routesProps: RoutesProps) {
	const location = useLocation();
	const config = useLocationAnimationConfig(location);

	const transitions = useTransition(location, {
		keys: location => location.pathname,
		initial: { opacity: 1, transform: 'translateX(0px)' },
		from: () => ({
			opacity: 0,
			transform:
				config.current.direction !== 'forward' ? 'translateX(0px)' : 'translateX(100%)',
		}),
		enter: { opacity: 1, transform: 'translateX(0px)' },
		leave: () => ({
			opacity: 0,
			transform: config.current.direction === 'back' ? 'translateX(100%)' : 'translateX(0px)',
		}),
		immediate: config.current.immediate,
	});

	return (
		<div className={styles.container}>
			{transitions((style, location) => (
				<animated.div style={style} className={styles.locationItem}>
					<Wrapper>
						<Routes key={location.pathname} {...routesProps} location={location} />
					</Wrapper>
				</animated.div>
			))}
		</div>
	);
}

export default AnimatedRoutes;
