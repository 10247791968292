import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import './index.css';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import Preloader from './components/common/Preloader/Preloader';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor} loading={<Preloader id="persist" />}>
			<App />
		</PersistGate>
	</Provider>
);
