import classNames from 'classnames';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ReservationItem.module.scss';

import { ReactComponent as GoButton } from '../../assets/go_button.svg';
import { ReactComponent as Bird } from '../../assets/reservations/bird.svg';
import { ReactComponent as Calendar } from '../../assets/reservations/calendar.svg';
import { ReactComponent as Clock } from '../../assets/reservations/clock.svg';

import moment from 'moment';
import { DATE_FORMAT } from '../../config/constants';
import { ROUTES } from '../../config/routes';
import { IReservation } from '../../network/types';
import { formatServerTimeRange, parseDuration } from '../../utils/formatTime';
import { setZeroBefore } from '../../utils/setZeroBefore';
import AddressLink from '../common/AddressLink/AddressLink';
import { Image } from '../common/Image/Image';

type ReservationProps = {
	reservation: IReservation;
	archived: boolean;
	className?: string;
};

function ReservationItem({ reservation, archived, className }: ReservationProps) {
	const navigate = useNavigate();
	const duration = useMemo(() => parseDuration(reservation), [reservation]);

	return (
		<div
			className={classNames(styles.order, { [styles.archived]: archived }, className)}
			onClick={() => navigate(ROUTES.RESERVATION(reservation.id))}
		>
			<div className={styles.order__image}>
				<Image src={reservation.room.photos?.[0]} />
				{!archived ? <GoButton className={styles.order__goButton} /> : null}
			</div>
			<div className={styles.order__header}>
				<p className={styles.order__name}>{reservation.room.title}</p>
				<AddressLink
					className={styles.order__address}
					coWorkingId={reservation.coworking.id}
					link={reservation.coworking.addressLink}
					address={reservation.coworking.address}
				/>
			</div>
			<div className={styles.order__info}>
				<div className={styles.order__reservation}>
					<Bird />
					<div className={styles.order__reservation_info}>
						<p>Votre réservation</p>
						<p>{reservation.room.title}</p>
					</div>
				</div>
				<div className={styles.order__time}>
					<div className={styles.order__date}>
						<Calendar />
						<div className={styles.order__date_info}>
							<p className={styles.title}>Date</p>
							<p className={styles.date}>
								{moment(reservation.from).format(DATE_FORMAT)}
							</p>
						</div>
					</div>
					<div className={styles.order__divider} />
					<div className={styles.order__date}>
						<Clock />
						<div className={styles.order__date_info}>
							<p className={styles.title}>Horaires</p>
							<p className={styles.date}>{formatServerTimeRange(reservation)}</p>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.order__total}>
				{reservation.price > 0 ? (
					<p className={styles.order__total_cost}>{reservation.price} €</p>
				) : (
					<p
						className={classNames(
							styles.order__total_cost,
							styles.order__total_cost_free
						)}
					>
						Gratuit
					</p>
				)}
				<div className={styles.order__total_divider}></div>
				<p className={styles.order__total_time}>
					<span>{setZeroBefore(duration.hours)}</span>
					h. <span>{duration.minutes}</span> min.
				</p>
			</div>
		</div>
	);
}

export default ReservationItem;
