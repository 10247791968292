import { AsyncState, useAsyncState } from './useAsyncState';
import { useStableCallback } from './useStableCallback';

type Action<T, As extends unknown[]> = (...args: As) => Promise<T>;

export function useCancelableActionCallback_UNSAFE<T, As extends unknown[]>(
	action: (signal: AbortSignal) => Action<T, As>
) {
	const [state, update] = useAsyncState<T>();

	const runAction = useStableCallback(async (...args: As): Promise<T> => {
		const controller = new AbortController();

		const promise = new Promise<T>((resolve, reject) => {
			action(controller.signal)(...args).then(resolve, reject);
			controller.signal.addEventListener('abort', () => reject(controller.signal.reason));
		});
		update({ status: 'pending', promise, cleanup: () => controller.abort() });
		await promise
			.then(
				value => ({ status: 'resolved', value } satisfies AsyncState<T>),
				error => ({ status: 'rejected', error } satisfies AsyncState<T>)
			)
			.then(update);

		return promise;
	});

	return [runAction, state, update] as const;
}

export function useCancelableActionCallback<T, As extends unknown[]>(
	action: (signal: AbortSignal) => Action<T, As>
) {
	const [runAction, state, update] = useCancelableActionCallback_UNSAFE<T, As>(action);

	const safeRunAction = useStableCallback(async (...args: As) => {
		if (state.status === 'pending') {
			throw new Error('Throttled: previous request is still running');
		}
		return runAction(...args);
	});

	return [safeRunAction, state, update] as const;
}

export function useActionCallback<T, As extends unknown[]>(action: Action<T, As>) {
	return useCancelableActionCallback<T, As>(() => action);
}
