import { animated, useSpring } from '@react-spring/web';
import ReactDOM from 'react-dom';
import styles from './BottomModal.module.scss';
import Button from '../../../common/Button/Button';

const BottomModal = ({ modalVisible, setModalVisible }) => {
	const { opacity, transform, ...rest } = useSpring({
		from: { opacity: 0, transform: `translateY(100%)` },
		to: {
			opacity: modalVisible ? 1 : 0,
			transform: modalVisible ? `translateY(0%)` : `translateY(100%)`,
		},
	});

	return ReactDOM.createPortal(
		<animated.div
			style={{ ...rest, opacity: opacity, transform: transform }}
			className={styles.modal}
		>
			<p className={styles.modal__title}>Nous vous avons envoyé un mail</p>
			<Button text="J'ai compris" onClick={() => setModalVisible(false)} />
		</animated.div>,
		document.getElementById('root')
	);
};

export default BottomModal;
