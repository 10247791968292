import { useState } from 'react';

import { getHolding } from '../../redux/middlewares/bookHoldingMiddleware';
import { BottomModal } from '../common/BottomModal/BottomModal';

export function HoldInfoModal() {
	const holding = getHolding();
	const [visible, setVisible] = useState(true);

	return (
		<BottomModal
			modalVisible={holding && visible}
			title="Votre créneau est temporairement réservé."
		>
			<BottomModal.CloseButton onClick={() => setVisible(false)} />
			<p>
				Nous bloquons temporairement ce créneau pour vous pendant 5 minutes, après cela, il
				sera de nouveau disponible à la réservation.
			</p>
		</BottomModal>
	);
}
