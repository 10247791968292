import { ComponentProps, useCallback, useState } from 'react';

import { ReactComponent as EyeClosed } from '../../../assets/eye.svg';
import { ReactComponent as EyeOpen } from '../../../assets/eye_open.svg';

import Input from './Input';

type PasswordInputProps = Omit<ComponentProps<typeof Input>, 'type'>;

function PasswordInput({ after, ...inputProps }: PasswordInputProps) {
	const [seeThrough, setSeeThrough] = useState(false);
	const toggle = useCallback(() => setSeeThrough(current => !current), []);

	return (
		<Input
			{...inputProps}
			type={seeThrough ? 'text' : 'password'}
			after={
				<>
					{seeThrough ? <EyeOpen onClick={toggle} /> : <EyeClosed onClick={toggle} />}
					{after}
				</>
			}
		/>
	);
}

export default PasswordInput;
