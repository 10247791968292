import axios from 'axios';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './SignUp.module.scss';

import { useNavigate } from 'react-router-dom';
import { SIGN_UP } from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { useCancelableActionCallback } from '../../hooks/useActionCallback';
import { FormValidatorScheme, useForm } from '../../hooks/useForm';
import { Method, request } from '../../network/request';
import { setUserEmail } from '../../redux/slice/userSlice';
import {
	checkedValidator,
	emailValidator,
	nameValidator,
	passwordValidator,
} from '../../utils/validators';
import ActivityIndicator from '../common/ActivityIndicator/ActivityIndicator';
import Button from '../common/Button/Button';
import CheckboxInput from '../common/Input/CheckboxInput';
import Input from '../common/Input/Input';
import PasswordInput from '../common/Input/PasswordInput';
import LoginSocials from '../common/LoginSocials/LoginSocials';

type SignUpFormValues = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	acceptance: boolean;
};
const signUpFormValidator: FormValidatorScheme<SignUpFormValues> = {
	firstName: name =>
		(nameValidator(name) && name.length >= 2) || 'Merci de remplir les champs demandés',
	lastName: name =>
		(nameValidator(name) && name.length >= 2) || 'Merci de remplir les champs demandés',
	email: email =>
		(emailValidator(email) && email.length >= 2) ||
		'Merci d’indiquer une adresse e-mail valide',
	password: password =>
		passwordValidator(password) ||
		'Veuillez respecter les exigences minimales pour le mot de passe : minimum 8 symboles, y compris des lettres minuscules et majuscules, un chiffre et un caractère spécial.',
	acceptance: checkedValidator,
};

type SignUpProps = {
	setShowTermsModal: Dispatch<SetStateAction<boolean>>;
	setShowPrivacyModal: Dispatch<SetStateAction<boolean>>;
};

const SignUp = ({ setShowTermsModal, setShowPrivacyModal }: SignUpProps) => {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const [handleSignUp, submit, updateSubmit] = useCancelableActionCallback(
		signal => async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			await request(SIGN_UP, { method: Method.POST, data, signal });
			dispatch(setUserEmail(data.get('email')));
			navigate(ROUTES.SIGNUP_VERIFICATION);
		}
	);
	const {
		values,
		renderError,
		handleInputChange,
		handleInputFocus,
		handleInputBlur,
		handleCheckboxChange,
		disableForm,
		disableSubmit,
	} = useForm<SignUpFormValues>({
		initialValues: { firstName: '', lastName: '', email: '', password: '', acceptance: false },
		validator: signUpFormValidator,
		onChange: useCallback(() => updateSubmit({ status: 'empty' }), [updateSubmit]),
	});

	const errorMessage = useMemo(() => {
		if (submit.status !== 'rejected') return undefined;
		if (axios.isAxiosError(submit.error) && submit.error.response.data?.detail) {
			return submit.error.response.data?.detail;
		}
		if (submit.error instanceof Error) return submit.error.message;
		return 'Internal server error, try again later';
	}, [submit]);

	return (
		<div className={styles.root}>
			<div className={styles.signUp__socials}>
				<p className={styles.signUp__socials_title}>S`inscrire avec</p>
				<LoginSocials />
			</div>
			<form className={styles.signUp} onSubmit={handleSignUp}>
				<Input
					type="text"
					autoComplete="given-name"
					label="Prénom"
					placeholder="Prénom"
					className={styles.signUp__input}
					name="firstName"
					value={values.firstName}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('firstName')}
				/>
				<Input
					type="text"
					autoComplete="family-name"
					label="Nom"
					placeholder="Nom"
					className={styles.signUp__input}
					name="lastName"
					value={values.lastName}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('lastName')}
				/>
				<Input
					type="email"
					autoComplete="username"
					label="E-mail"
					placeholder="Votre e-mail"
					className={styles.signUp__input}
					name="email"
					value={values.email}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('email') || errorMessage}
				/>
				<PasswordInput
					autoComplete="new-password"
					label="Mot de passe"
					placeholder="Écrivez votre mot de passe"
					className={styles.signUp__input}
					name="password"
					value={values.password}
					onChange={handleInputChange}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disableForm}
					error={renderError('password')}
				/>
				<CheckboxInput
					tabIndex={0}
					label={
						<>
							En créant votre compte vous acceptez les{' '}
							<span
								className={styles.signUp__conditions_link}
								onClick={() => setShowTermsModal(true)}
							>
								conditions générales d`utilisation
							</span>{' '}
							et la{' '}
							<span
								className={styles.signUp__conditions_link}
								onClick={() => setShowPrivacyModal(true)}
							>
								politique de confidentialité
							</span>
						</>
					}
					className={{
						wrapper: styles.signUp__conditions,
						label: styles.signUp__conditions_label,
					}}
					name="acceptance"
					checked={values.acceptance}
					onChange={handleCheckboxChange}
				/>
				<div className={styles.signUp__submit}>
					<Button
						tabIndex={0}
						type="submit"
						disabled={disableSubmit}
						text={
							submit.status === 'pending' ? (
								<ActivityIndicator contrast />
							) : (
								'Créer un compte'
							)
						}
					/>
				</div>
			</form>
		</div>
	);
};

export default SignUp;
