import { Component, ReactNode } from 'react';

type ErrorBoundaryProps = {
	children: ReactNode;
	fallback?: ReactNode | { (error: unknown, reset: () => void): ReactNode };
};
type ErrorBoundaryState = {
	error: { reason: unknown } | null;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	static getDerivedStateFromError(reason: Error) {
		return { error: { reason } };
	}

	componentDidCatch(reason, info) {
		if (process.env.NODE_ENV === 'development') {
			console.error('Error occurred during render:', reason);
			console.error('Error info:', info);
		}
	}

	componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
		const { error } = this.state;

		if (error && prevState.error !== null) {
			this.reset();
		}
	}

	componentWillUnmount(): void {
		this.preReset();
	}

	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { error: null };
	}

	preReset() {
		if (
			this.state.error &&
			typeof this.state.error.reason === 'object' &&
			this.state.error.reason &&
			'onReset' in this.state.error.reason &&
			typeof this.state.error.reason.onReset === 'function'
		) {
			this.state.error.reason.onReset();
		}
	}
	reset = () => {
		this.preReset();
		this.setState({ error: null });
	};

	render(): ReactNode {
		const { children, fallback } = this.props;
		const { error } = this.state;

		if (!error) return <>{children}</>;
		if (typeof fallback === 'function') return <>{fallback(error.reason, this.reset)}</>;
		if (fallback === undefined) return <>{error.reason}</>;
		return <>{fallback}</>;
	}
}
