import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RestorePassword from '../../components/restorePassword/RestorePassword';
import { FORGOT_PASSWORD } from '../../config/endpoints';
import { ROUTES } from '../../config/routes';
import { useCancelableActionCallback } from '../../hooks/useActionCallback';
import { useLocationToken } from '../../hooks/useLocationToken';
import { Method, request } from '../../network/request';

const RestorePasswordPage = () => {
	const parseToken = useLocationToken(ROUTES.RESTORE_PASSWORD());
	const [password, setPassword] = useState('');

	const navigate = useNavigate();
	const [recoverPassword, recoverState, updateRecoverState] = useCancelableActionCallback(
		signal => async () => {
			const token = parseToken();
			await request(FORGOT_PASSWORD, {
				method: Method.PATCH,
				data: { token, password },
				signal,
			});
			navigate(ROUTES.LOGIN);
		}
	);
	const error = () => {
		if (recoverState.status !== 'rejected') return undefined;
		if (axios.isAxiosError(recoverState.error) && recoverState.error.response?.data.detail)
			return recoverState.error.response.data.detail;
		if (error instanceof Error) return error.message;
		return 'Failed to restore password! Please try again later!';
	};

	return (
		<RestorePassword
			password={password}
			passwordError={error()}
			onResetError={() => updateRecoverState({ status: 'empty' })}
			setPassword={setPassword}
			onSetNewPassword={recoverPassword}
			loading={recoverState.status === 'pending'}
		/>
	);
};

export default RestorePasswordPage;
