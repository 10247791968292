import { Middleware } from '@reduxjs/toolkit';
import { setToken } from '../slice/userSlice';
import { ROUTES } from '../../config/routes';
import { Method, request } from '../../network/request';
import { BOOK_COWORKING_ROOM } from '../../config/endpoints';
import { handleReservation } from '../slice/reservationsSlice';

export type Holding = {
	coWorkingId: number;
	roomId: number;
	data: {
		from: string;
		to: string;
		timezoneOffset: number;
	};
};

export function storeHolding(coWorkingId: number, roomId: number, data: Holding['data']) {
	localStorage.setItem('holding', JSON.stringify({ coWorkingId, roomId, data }));
}
export function getHolding(): Holding {
	const json = localStorage.getItem('holding');
	return JSON.parse(json);
}

export const bookHoldingMiddleware: Middleware = store => next => async action => {
	if (!setToken.match(action) || !action.meta?.navigate) {
		return next(action);
	}

	const { navigate, from = ROUTES.CO_WORKINGS } = action.meta;
	delete action.meta;

	const holding = getHolding();
	if (!holding) {
		navigate(from);
		return next(action);
	}

	const result = next(action);

	try {
		localStorage.removeItem('holding');
		const response = await request(BOOK_COWORKING_ROOM(holding.coWorkingId, holding.roomId), {
			method: Method.POST,
			data: holding.data,
		});
		store.dispatch(handleReservation(response.data));
		navigate(ROUTES.CO_WORKING_BOOK_SUCCESS(response.data.id));

		return result;
	} catch (error) {
		navigate(from);
		return result;
	}
};
