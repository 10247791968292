import styles from './ReservationsEmpty.module.scss';
import { ReactComponent as Cloud } from '../../assets/reservations/cloud.svg';

export function ReservationsEmpty() {
	return (
		<div className={styles.reservationsEmpty}>
			<Cloud />
			<h1 className={styles.reservationsEmpty__title}>Pas de réservation</h1>
			<p className={styles.reservationsEmpty__content}>
				Vous n`avez pas encore de réservation. Venez découvrir nos lieux avec l`onglet
				espaces.
			</p>
		</div>
	);
}
