import { Suspense } from 'react';

import styles from './CoWorkings.module.scss';

import Header from '../../components/common/Header/Header';
import CoWorkingItem from '../../components/coworkings/CoWorkingItem/CoWorkingItem';
import { DataScrollView } from '../../components/DataScrollView/DataScrollView';
import { FetchMoreIndicator } from '../../components/DataScrollView/FetchMoreIndicator';
import { useCoWorkingsLoader } from '../../hooks/loaders/useCoWorkingsLoader';
import ActivityIndicator from '../../components/common/ActivityIndicator/ActivityIndicator';

const CoWorkings = () => {
	const { data, canFetchMore, onFetchMore } = useCoWorkingsLoader(1);

	return (
		<DataScrollView
			className={styles.spaces}
			fetchIndicator={<FetchMoreIndicator />}
			canFetchMore={canFetchMore}
			onFetchMore={onFetchMore}
		>
			{data.items?.map(coWorking => (
				<CoWorkingItem key={coWorking.id} coWorking={coWorking} />
			))}
		</DataScrollView>
	);
};

const CoWorkingsPage = () => {
	return (
		<div className={styles.container}>
			<Header />
			<h1 className={styles.title}>Nos espaces</h1>
			<Suspense fallback={<ActivityIndicator absolute />}>
				<CoWorkings />
			</Suspense>
		</div>
	);
};

export default CoWorkingsPage;
