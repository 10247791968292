import styles from './Faq.module.scss';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';

import 'react-accessible-accordion/dist/fancy-example.css';
import TermsModal from '../../common/TermsModal/TermsModal';
import PrivacyModal from '../../common/PrivacyModal/PrivacyModal';
import { useState } from 'react';
import { BottomModal } from '../../../components/common/BottomModal/BottomModal';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../../redux/selectors/userSelector';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import Header from '../../common/Header/Header';
import Button from '../../common/Button/Button';

export const EMAIL_ADDRESS = 'hello@workandgo.eu';

const Faq = () => {
	const navigate = useNavigate();
	const token = useSelector(tokenSelector);
	const isAuthenticated = token && token.length !== 0;
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [showPrivacyModal, setShowPrivacyModal] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showCopyModal, setShowCopyModal] = useState(false);

	const hanldleCopyEmail = () => {
		navigator.clipboard.writeText(EMAIL_ADDRESS);
		setShowCopyModal(true);
	};

	return (
		<div className={styles.faq}>
			<Header />
			<div className={styles.faq__questions}>
				<h2 className={styles.faq__title}>FAQ</h2>
				<p className={styles.faq__content}>
					Vous trouverez ici les réponses aux questions les plus fréquemment posées
				</p>
				<Accordion className={styles.accordion}>
					<AccordionItem uuid={1} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Comment fonctionne Work & Go ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Work & Go est un coworking ultra-flexible. Dans chaque Work & Go
								vous trouverez une zone open-space et des cabines fermées &#129323;
								. L’ensemble des places est en libre-accès et vous pouvez réserver
								nos cabines en avance pour vos visios et RDVs importants.
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={2} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Quels sont vos tarifs ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Nos premiers espaces sont <strong>entièrement gratuits</strong> !
								&#127881;
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={3} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Quels services proposez-vous ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Dans chaque Work & Go vous trouverez des places de travail équipées
								avec la connectique pour se recharger, des cabines pour vos visios
								confidentielles, un réseau Wi-Fi (parfois venu de l’espace &#128752;
								), du café et une offre restauration de la part de nos partenaires.
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={4} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Est-il obligatoire de réserver ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								<b>Non !</b> Mais on vous le conseille si vous avez un RDV important
								et voulez vous assurer un accès à nos cabines confidentielles
								&#127911;
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={5} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Est ce que je peux modifier ma réservation ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Pas encore, mais cette fonctionnalité arrivera très vite &#127950; !
								En revanche, vous pouvez annuler et faire une nouvelle réservation.
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={6} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Comment annuler ma réservation ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Vous pouvez le faire en un seul clique. Cliquez sur “Réservations”,
								choisissez celle que vous voulez annuler et vous verrez un bouton
								d’annulation. Sachiez que l’on regrette de ne pas vous voir... 😔
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={7} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Comment accéder à une cabine ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Il vous suffit juste de scanner son QR-code ! Si la cabine n’est pas
								réservée, vous pourrez y accéder en 30 secondes ! Si vous avez
								réservé en avance, vous pourrez également l’ouvrir depuis votre
								smartphone.
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={8} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Comment clôturer ma session dans la cabine ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Quand vous avez fini d’utiliser la cabine, merci de 1&#65039;&#8419;
								Quitter la cabine en récupérant vos affaires, 2&#65039;&#8419;
								Fermer ses portes, 3&#65039;&#8419; Scanner le QR-code sur sa
								devanture et confirmer la fin de votre session, 4&#65039;&#8419;
								Passer une excellente fin de la journée &#128522;
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={9} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Comment me connecter à votre Wi-Fi ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Rien de plus simple (sauf si vous avez un Nokia 3310 &#128579; ),
								Connectez-vous au réseau “Work & Go” et acceptez nos conditions
								générales !
							</p>
						</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem uuid={10} className={styles.accordion__item}>
						<AccordionItemHeading className={styles.accordion__header}>
							<AccordionItemButton className={styles.accordion__header_button}>
								Comment supprimer mon compte ?
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className={styles.accordion__panel}>
							<p>
								Pour supprimer votre compte appuyez sur le bouton &quot;Supprimer le
								compte&quot; ci-dessus, confirmez à l’aide de votre mail.
							</p>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
			</div>
			<p className={styles.faq__content}>
				Pour toutes questions ou retours concernant cette application, merci de contacter
				l’équipe à l’adresse
			</p>
			<div className={styles.faq__email}>
				<a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
				<div className={styles.copy} onClick={hanldleCopyEmail}>
					<CopyIcon />
				</div>
			</div>
			<div className={styles.faq__footer}>
				<p className={styles.info} onClick={() => setShowTermsModal(true)}>
					Conditions générales d`utilisation
				</p>
				<p className={styles.info} onClick={() => setShowPrivacyModal(true)}>
					Politique de confidentialité
				</p>
				{isAuthenticated && (
					<p className={styles.logout} onClick={() => setShowConfirmModal(true)}>
						Supprimer le compte
					</p>
				)}
			</div>

			<TermsModal modalVisible={showTermsModal} onClick={() => setShowTermsModal(false)} />
			<PrivacyModal
				modalVisible={showPrivacyModal}
				onClick={() => setShowPrivacyModal(false)}
			/>
			<BottomModal
				modalVisible={showConfirmModal}
				title="Êtes-vous sûr de vouloir supprimer votre compte ?"
			>
				<p className={styles.modal__info}>
					Cela effacera définitivement tout le contenu de votre compte ainsi que vos
					informations personnelles, et vous ne pourrez plus continuer à utiliser
					l`application. Vous trouverez plus d`informations sur la politique de
					suppression de compte dans l`onglet FAQ.
				</p>
				<Button
					text="supprimer définitivement"
					attention
					onClick={() => navigate(ROUTES.DELETE)}
				/>
				<BottomModal.CancelButton
					text="Annuler"
					onClick={() => setShowConfirmModal(false)}
				/>
			</BottomModal>
			<BottomModal modalVisible={showCopyModal} title="E-mail copié dans le presse-papiers">
				<BottomModal.CancelButton
					text="Il est clair"
					onClick={() => setShowCopyModal(false)}
				/>
			</BottomModal>
		</div>
	);
};

export default Faq;
