import isEqual from 'lodash.isequal';
import { useRef } from 'react';

export function useStableValue<T>(value: T) {
	const cache = useRef(value);
	if (!isEqual(value, cache.current)) {
		cache.current = value;
	}

	return cache.current;
}
