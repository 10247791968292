import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../../assets/logo_full_colored.svg';
import QrReaderButton from '../QrReaderButton/QrReaderButton';
import { ROUTES } from '../../../config/routes';

const Header = ({ className, ...headerProps }: JSX.IntrinsicElements['header']) => {
	return (
		<header {...headerProps} className={classNames(styles.header, className)}>
			<Link className={styles.header__logo} to={ROUTES.CO_WORKINGS}>
				<Logo />
			</Link>
			<QrReaderButton />
		</header>
	);
};

export default Header;
