import cn from 'classnames';
import styles from './Button.module.scss';
import { ReactNode } from 'react';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'children'> & {
	text: ReactNode;
	attention?: boolean;
	disabled?: boolean;
};

const Button = ({
	text = '',
	attention = false,
	disabled = false,
	className,
	...buttonProps
}: ButtonProps) => {
	return (
		<button
			type="button"
			{...buttonProps}
			disabled={disabled}
			className={cn(
				styles.button,
				{
					[styles.disabled]: disabled,
					[styles.attention]: attention,
				},
				className
			)}
		>
			{text}
		</button>
	);
};

export default Button;
