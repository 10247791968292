import styles from './Preloader.module.scss';
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web';
import { Player } from '@lottiefiles/react-lottie-player';
import { ReactComponent as Logo } from '../../../assets/logo_full.svg';

import { CSSProperties, useEffect, useState } from 'react';

const items: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
	// ({ style }) => <animated.li style={{ ...style }}>chargement, juste un instant</animated.li>,
	({ style }) => <animated.li style={{ ...style }}>Chargement en cours …</animated.li>,
	// ({ style }) => <animated.li style={{ ...style }}>Tu es presque là</animated.li>,
	// ({ style }) => <animated.li style={{ ...style }}>Nous commençons déjà</animated.li>,
	({ style }) => <animated.li style={{ ...style }}>3,2,1… c’est parti</animated.li>,
];

const Preloader = ({ id }: { id?: string }) => {
	const [index, setIndex] = useState(0);
	const transRef = useSpringRef();

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIndex((index + 1) % items.length);
		}, 1500);
		transRef.start();
		return () => {
			clearTimeout(timeout);
			transRef.stop();
		};
	}, [index, transRef]);

	const transitions = useTransition(index, {
		ref: transRef,
		keys: null,
		from: { opacity: 0, transform: 'translate(-50%,100%)' },
		enter: { opacity: 1, transform: 'translate(-50%,0%)' },
		leave: { opacity: 0, transform: 'translate(-50%,-50%)' },
	});

	return (
		<div id={id} className={styles.loader}>
			<div className={styles.loader__content}>
				<div className={styles.loader__logo}>
					<Logo />
				</div>
				<p className={styles.loader__title}>Le Coworking des petites et moyennes villes</p>
			</div>
			<div className={styles.loader__footer}>
				<div className={styles.loader__footer_animation}>
					<Player
						autoplay
						loop
						src="https://lottie.host/9d7d54de-f39c-43c4-a5e4-a46ef9fc04ad/jKUcIHNxOJ.json"
						style={{ height: '35px', width: '188px' }}
					></Player>
				</div>
				<ul className={styles.loader__footer_list}>
					{transitions((style, i) => {
						const Item = items[i];
						return <Item style={style} />;
					})}
				</ul>
			</div>
			<div className={styles.loader__bird} />
		</div>
	);
};

export default Preloader;
