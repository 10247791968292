import { useRef } from 'react';
import { Location } from 'react-router-dom';

export function useLocationAnimationConfig(location: Location) {
	const locations = useRef<[Location | null, Location]>([null, location]);
	if (location.key !== locations.current[1].key) {
		locations.current = [locations.current[1], location];
	}

	const [prev, next] = locations.current;
	const config = useRef<{
		direction: 'forward' | 'back' | 'sibling';
		immediate: boolean;
	}>({
		direction: 'forward',
		immediate: true,
	});

	config.current.direction = 'sibling';
	if (prev) {
		if (prev.pathname.startsWith(next.pathname)) {
			config.current.direction = 'back';
		} else if (next.pathname.startsWith(prev.pathname)) {
			config.current.direction = 'forward';
		}
	}
	config.current.immediate = next.state?.immediate;

	return config;
}
