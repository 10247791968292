import { Outlet } from 'react-router-dom';
import { ReactNode } from 'react';

import styles from './MainLayout.module.scss';

import BottomMenu from '../../components/common/BottomMenu/BottomMenu';

type MainLayoutProps = {
	children?: ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
	return (
		<div className={styles.layout}>
			<div className={styles.layout__wrapper}>{children ?? <Outlet />}</div>
			<BottomMenu className={styles.menu} />
		</div>
	);
};

export default MainLayout;
