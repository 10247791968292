import { AxiosRequestConfig } from 'axios';

import { getAuthToken } from './getAuthToken';

export const getRequestHeader = (headers: AxiosRequestConfig['headers'] = {}) => {
	const token = getAuthToken();

	if (!headers['Content-Type']) {
		headers['Content-Type'] = 'application/json';
	}
	if (!headers['Accept'] && headers['Content-Type'] === 'application/json') {
		headers['Accept'] = 'application/json';
	}
	if (!headers.Authorization && token) {
		headers.Authorization = `Bearer ${token}`;
	}

	return headers;
};
