import { useCallback, useRef } from 'react';

export function useStableCallback<As extends any[], R>(cb: (...args: As) => R): (...args: As) => R;
export function useStableCallback<CB extends (...args: any[]) => any>(
	cb: CB
): (...args: Parameters<CB>) => ReturnType<CB>;
export function useStableCallback<As extends any[], R>(cb: (...args: As) => R) {
	const ref = useRef(cb);
	ref.current = cb;
	return useCallback((...args: As) => ref.current(...args), []);
}
