import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'react-redux';
import moment from 'moment';

import styles from './CoWorkingRoom.module.scss';
import { ReactComponent as Arrow } from '../../../../assets/back_arrow.svg';
import { ReactComponent as Light } from '../../../../assets/spaces/light.svg';

import { setZeroBefore } from '../../../../utils/setZeroBefore';
import { DATE_TIME_FORMAT } from '../../../../config/constants';
import { MinutesTimeRange } from '../../../../network/types';
import { Method, refreshToken, request } from '../../../../network/request';
import { BOOK_COWORKING_ROOM } from '../../../../config/endpoints';
import { ROUTES } from '../../../../config/routes';
import { AvailabilitySelect } from '../../../../components/availability-select';
import Select from '../../../../components/common/Select/Select';
import AddressLink from '../../../../components/common/AddressLink/AddressLink';
import {
	formatMinutesAsTime,
	formatTimeRange,
	formatWorkingHours,
} from '../../../../utils/formatTime';
import { ActionButton } from '../../../../components/common/Button/ActionButton';
import { useCoWorkingLoader } from '../../../../hooks/loaders/useCoWorkingLoader';
import { handleReservation } from '../../../../redux/slice/reservationsSlice';
import { tokenSelector } from '../../../../redux/selectors/userSelector';
import { storeHolding } from '../../../../redux/middlewares/bookHoldingMiddleware';
import { Image } from '../../../../components/common/Image/Image';

function CoWorkingRoomPage() {
	const { hash } = useLocation();
	const { coWorkingId, roomId } = useParams();
	const { data: coWorking } = useCoWorkingLoader(coWorkingId);
	const room = coWorking.rooms.find(room => room.id === +roomId);

	const [dateModalVisible, setDateModalVisible] = useState(hash === '#time');
	const [timeSlot, setTimeSlot] = useState<{ date: string; from: string; to: string } | null>(
		null
	);
	const [error, setError] = useState<unknown>(null);

	const total = useMemo(() => {
		if (!timeSlot) return undefined;

		const diff = moment.duration(
			moment(timeSlot.to, 'H:mm').diff(moment(timeSlot.from, 'H:mm'))
		);
		const timeSlots = Math.floor(diff.asMinutes() / 15);
		const price = room.price * timeSlots;
		const formattedTime = (
			<>
				<span>{setZeroBefore(Math.trunc(diff.asHours()))}</span> h.{' '}
				<span>{setZeroBefore(diff.minutes())}</span> m.
			</>
		);

		return { diff, timeSlots, price, formattedTime };
	}, [room.price, timeSlot]);

	const handleTimeSlotSelected = useCallback((date: string, { from, to }: MinutesTimeRange) => {
		setTimeSlot({ date, from: formatMinutesAsTime(from), to: formatMinutesAsTime(to) });
		setDateModalVisible(false);
		setError(null);
	}, []);

	const roomCapacity = (capacity: number) => `Cabine pour ${capacity} personnes`;

	const navigate = useNavigate();
	const store = useStore();
	const handleSubmit = useCallback(async () => {
		const from = moment(`${timeSlot.date} ${timeSlot.from}`, DATE_TIME_FORMAT);
		const to = moment(`${timeSlot.date} ${timeSlot.to}`, DATE_TIME_FORMAT).utcOffset(0, false);
		if (to.hours() === 0) {
			to.subtract(1, 'minute');
		}

		const timezoneOffset = from.utcOffset();

		const tryToBook = async () => {
			try {
				const data = { from: from.toISOString(), to: to.toISOString(), timezoneOffset };
				const response = await request(BOOK_COWORKING_ROOM(coWorking.id, room.id), {
					method: Method.POST,
					data,
				});
				if (response?.data) {
					store.dispatch(handleReservation(response.data));
					navigate(ROUTES.CO_WORKING_BOOK_SUCCESS(response.data.id));
				} else {
					const token = tokenSelector(store.getState() as never);
					if (token) {
						await refreshToken();
						await tryToBook();
					} else {
						storeHolding(coWorking.id, room.id, data);
						navigate(ROUTES.LOGIN);
					}
				}
			} catch (error) {
				setError(error);
			}
		};
		await tryToBook();
	}, [timeSlot, coWorking.id, room.id, store, navigate]);

	return (
		<div className={styles.container}>
			<AvailabilitySelect
				coWorkingId={coWorking.id}
				roomId={room.id}
				workHours={coWorking.workHours}
				onSelect={handleTimeSlotSelected}
				modalVisible={dateModalVisible}
				onCloseModal={() => setDateModalVisible(false)}
			/>

			<button className={styles.arrow}>
				<Arrow onClick={() => navigate(ROUTES.CO_WORKING(coWorkingId))} />
			</button>
			<h1 className={styles.title}>Réservation</h1>
			<div className={styles.select}>
				<Select
					label="Sélectionner votre espace"
					options={coWorking.rooms.map(room => roomCapacity(room.capacity))}
					selectedOption={roomCapacity(room.capacity)}
					onSelect={(_, index) => {
						const next = coWorking.rooms[index];
						navigate(ROUTES.CO_WORKING_ROOM(coWorking.id, next.id), {
							preventScrollReset: true,
							replace: true,
						});
						setError(null);
					}}
				/>
			</div>
			<div className={styles.coworking}>
				<div className={styles.content}>
					<div className={styles.image}>
						<Image src={room.photos?.[0]} />
					</div>
					<div className={styles.info}>
						<p className={styles.info__name}>{room.title}</p>
						<div className={styles.info__total}>
							{room.price > 0 ? (
								<p className={styles.info__total_cost}>{room.price}</p>
							) : (
								<p className={styles.info__total_cost_free}>Gratuit</p>
							)}
						</div>
					</div>
				</div>
				<div className={styles.about}>
					<p className={styles.about__title}>Limours</p>
					<AddressLink
						className={styles.about__address}
						coWorkingId={coWorking.id}
						link={coWorking.addressLink}
						address={coWorking.address}
					/>
					<div className={styles.about__availability}>
						<div className={styles.about__availability__item}>
							<Light />
							<p>
								<span>Horaires d&apos;ouverture: </span>
								{formatWorkingHours(coWorking.workHours)}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.divider} />
			<div className={styles.timepicker}>
				<div className={styles.time} onClick={() => setDateModalVisible(true)}>
					<div className={styles.time__container}>
						<p className={styles.title}>Date</p>
						<p className={styles.date}>{timeSlot?.date ?? '-- / -- / ----'}</p>
					</div>
					<div className={styles.time__divider} />
					<div className={styles.time__container}>
						<p className={styles.title}>Horaires</p>
						<p className={classNames(styles.date, !!error && styles.error)}>
							{formatTimeRange(timeSlot)}
						</p>
					</div>
				</div>
				<button
					className={styles.button}
					type="button"
					onClick={() => setDateModalVisible(true)}
				>
					Choisir une date et une heure
				</button>
				{error && (
					<p className={classNames(styles.info, styles.error)}>
						Oops... Il semblerai que cet espace soit déjà réservé par une autre
						personne. Essayez une autre plage horaire.
					</p>
				)}
			</div>
			<div className={styles.footer}>
				{total ? (
					<div className={styles.summary}>
						{total.price > 0 ? (
							<p className={styles.summary__cost}>{total.price} €</p>
						) : (
							<p
								className={classNames(
									styles.summary__cost,
									styles.summary__cost__free
								)}
							>
								Gratuit
							</p>
						)}
						<div className={styles.summary__divider} />
						<div className={styles.summary__time}>{total.formattedTime}</div>
					</div>
				) : (
					<div className={styles.summary}>
						<div className={styles.summary__cost}>---- €</div>
						<div className={styles.summary__divider} />
						<div className={styles.summary__time}>
							<span> -- </span> h
						</div>
					</div>
				)}
				<div className={styles.reserve}>
					<ActionButton text="Réserver" action={handleSubmit} disabled={!timeSlot} />
				</div>
			</div>
		</div>
	);
}

export default CoWorkingRoomPage;
