import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './InputContainer.module.scss';

export type InputContainerProps = {
	error?: boolean | string;
	info?: ReactNode;
	className?:
		| string
		| {
				wrapper?: string;
				container?: string;
		  };
	hideErrorContainer?: boolean;
	children?: ReactNode;
};

function InputContainer({
	error,
	info,
	className: propClassName,
	hideErrorContainer = false,
	children,
}: InputContainerProps) {
	const className =
		typeof propClassName === 'string' ? { wrapper: propClassName } : propClassName;

	return (
		<div className={classNames(styles.wrapper, error && styles.error, className?.wrapper)}>
			<div className={classNames(styles.container, className?.container)}>{children}</div>
			{error && typeof error === 'string' ? <p className={styles.error}>{error}</p> : null}
			{info ? <p>{info}</p> : null}
			{(error && typeof error === 'string') || info || hideErrorContainer ? null : <p> </p>}
		</div>
	);
}

export default InputContainer;
