import { FocusEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useCancelableActionCallback } from './useActionCallback';

export type FormValidator<Values extends object> = (values: Partial<Values>) => {
	[N in keyof Values]?: unknown;
};
export type FormValidatorScheme<Values extends object> = {
	[N in keyof Values]?: (value: Values[N] | undefined) => unknown;
};

export type FormErrors<Values extends object> = Array<[keyof Values, unknown]> & {
	[N in keyof Values]?: unknown;
};

export type FormConfig<Values extends object> = {
	initialValues?: Partial<Values>;
	validator?: FormValidator<Values> | FormValidatorScheme<Values>;
	onChange?: (name: keyof Values, value: Values[keyof Values]) => void;
	onTouched?: (name: keyof Values) => void;
	onFocus?: (name: keyof Values, event: FocusEvent<HTMLInputElement, Element>) => void;
	onBlur?: (name: keyof Values, event: FocusEvent<HTMLInputElement, Element>) => void;
	onSubmit?: (
		data: FormData,
		signal: AbortSignal,
		event?: FormEvent<HTMLFormElement>
	) => Promise<void>;
	clearSubmitErrorOnChange?: boolean;
};

export function useForm<Values extends object>({
	initialValues = {},
	validator,
	onChange,
	onTouched,
	onFocus,
	onBlur,
	onSubmit,
	clearSubmitErrorOnChange = true,
}: FormConfig<Values>) {
	const [_submit, submitState, updateSubmit] = useCancelableActionCallback(
		signal => async (data: FormData, event?: React.FormEvent<HTMLFormElement>) => {
			await onSubmit(data, signal, event);
		}
	);
	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			_submit(data);
		},
		[_submit]
	);

	const [values, setValues] = useState(initialValues);
	const submit = useCallback(() => {
		const data = new FormData();
		for (const [key, value] of Object.entries(values)) {
			data.append(key, value as string);
		}
		_submit(data);
	}, [_submit, values]);

	const [touched, setTouched] = useState<{ [N in keyof Values]?: boolean }>({});
	const [focused, setFocused] = useState<keyof Values | undefined>(undefined);
	const [shownErrors, setShownErrors] = useState<FormErrors<Values>>([]);
	const currentErrors = useMemo(() => {
		if (typeof validator === 'function') {
			const entries = Object.entries(validator(values));
			return entries.reduce((errors, [name, error]) => {
				if (focused === name) return errors;
				errors.push([name as keyof Values, error]);
				errors[name] = error;
				return errors;
			}, [] as FormErrors<Values>);
		}
		const validators =
			Object.entries<(value: Values[keyof Values] | undefined) => unknown>(validator);
		return validators.reduce((errors, [name, validator]) => {
			if (focused === name) return errors;
			let error: unknown;
			try {
				const result = validator(values[name]);
				if (result === false) {
					error = 'invalid';
				} else if (result !== true && result) {
					error = result;
				}
			} catch (e) {
				error = e;
			}
			if (error) {
				errors.push([name as keyof Values, error]);
				errors[name] = error;
			}
			return errors;
		}, [] as FormErrors<Values>);
	}, [focused, validator, values]);
	useEffect(() => {
		setShownErrors(currentErrors);
	}, [currentErrors]);

	const setValue = useCallback(
		<K extends keyof Values>(name: K, value: Values[K]) => {
			setValues(current => ({ ...current, [name]: value }));
			onChange?.(name, value);
			if (clearSubmitErrorOnChange) {
				updateSubmit({ status: 'empty' });
			}
			setShownErrors(current => current.filter(([errorName]) => errorName !== name));
			setTouched(current => {
				if (!current[name]) {
					onTouched?.(name);
				}
				return { ...current, [name]: true };
			});
		},
		[clearSubmitErrorOnChange, onChange, onTouched, updateSubmit]
	);
	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setValue(name as keyof Values, value as Values[keyof Values]);
		},
		[setValue]
	);
	const handleCheckboxChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, checked } = event.target;
			setValue(name as keyof Values, checked as Values[keyof Values]);
		},
		[setValue]
	);

	const handleInputFocus = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { name }: any = event.target;
			setFocused(name);
			onFocus?.(name, event);
		},
		[onFocus]
	);
	const handleInputBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { name }: any = event.target;
			setFocused(current => (name === current ? null : current));
			onBlur?.(name, event);
		},
		[onBlur]
	);

	type StringValuesNames = {
		[N in keyof Values]: Values[N] extends string ? N : never;
	}[keyof Values];
	const renderError = (name: StringValuesNames) => touched[name] && (shownErrors[name] as string);

	const disableForm = submitState.status === 'pending';
	const disableSubmit = disableForm || currentErrors.length > 0;

	return {
		values,
		currentErrors,
		shownErrors,
		touched,
		focused,
		renderError,
		setValue,
		handleInputChange,
		handleCheckboxChange,
		handleInputFocus,
		handleInputBlur,
		handleSubmit,
		submit,
		submitAction: [submitState, updateSubmit],
		disableForm,
		disableSubmit,
	} as const;
}
