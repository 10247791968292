import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Html5Qrcode } from 'html5-qrcode';

import { ReactComponent as Arrow } from '../../assets/back_arrow.svg';
import { ReactComponent as Info } from '../../assets/info.svg';

import styles from './QrReaderPage.module.scss';
import { ROUTES } from '../../config/routes';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { BottomModal } from '../../components/common/BottomModal/BottomModal';

function QrReaderPage() {
	const navigate = useNavigate();
	const [showError, setShowError] = useState<boolean>(false);

	const handleResult = useCallback(
		(decodedText: string) => {
			const { protocol, host } = window.location;
			const url = new URL(decodedText);

			if (protocol !== url.protocol || host !== url.host) {
				setShowError(true);
				return;
			}

			{
				const parser = /^\/q\/(?<coWorkingId>[0-9]+)\/(?<roomId>[0-9]+)\/?$/;
				const match = parser.exec(url.pathname);
				if (match && match.groups.coWorkingId && match.groups.roomId) {
					navigate(ROUTES.QR(match.groups.coWorkingId, match.groups.roomId));
					return;
				}
			}

			{
				const parser = /^\/coworkings\/(?<coWorkingId>[0-9]+)\/room\/(?<roomId>[0-9]+)\/?$/;
				const match = parser.exec(url.pathname);
				if (match && match.groups.coWorkingId && match.groups.roomId) {
					navigate(ROUTES.QR(match.groups.coWorkingId, match.groups.roomId));
					return;
				}
			}

			window.location.assign(decodedText);
		},
		[navigate]
	);

	const previewId = useId();
	useEffect(() => {
		const html5QrcodeScanner = new Html5Qrcode(previewId);
		let started = false;
		html5QrcodeScanner
			.start({ facingMode: 'environment' }, { fps: 10 }, handleResult, undefined)
			.then(() => (started = true))
			.catch(() => {
				setShowError(true);
				navigate(-1);
			});
		return () => {
			if (!started) return;
			html5QrcodeScanner.stop();
		};
	}, [previewId, handleResult, navigate]);

	const overlayRef = useRef();
	useOnClickOutside(
		overlayRef,
		useCallback(() => setShowError(false), [])
	);

	return (
		<div className={styles.container}>
			<div id={previewId} className={styles.preview} />
			<div className={styles.overlay} ref={overlayRef}>
				<div className={styles.focusContainer}>
					<div className={styles.focus}>
						<div />
						<div />
						<div />
						<div />
					</div>
				</div>
				<div className={styles.header}>
					<button className={styles.button} onClick={() => navigate(-1)}>
						<Arrow />
					</button>
					<button className={styles.button} onClick={() => setShowError(v => !v)}>
						<Info />
					</button>
				</div>
				<div className={styles.screenName}>scanner le code qr</div>
			</div>
			<BottomModal title="Scannez le QR Code pour entrer" modalVisible={showError}>
				<BottomModal.CloseButton onClick={() => setShowError(false)} />
				<p>
					Concentrez la caméra sur le code QR, essayez de le placer au centre de
					l&apos;image. Attendez que votre appareil reconnaisse automatiquement le code
					QR. Cela ne prend généralement que quelques secondes.
				</p>
			</BottomModal>
		</div>
	);
}

export default QrReaderPage;
