import { Link } from 'react-router-dom';

import styles from './ReservationsThanks.module.scss';
import { ReactComponent as Logo } from '../../assets/logo_full.svg';
import { ReactComponent as Thanks } from '../../assets/success/thanks.svg';

import { ROUTES } from '../../config/routes';
import { Navigate } from '../../components/common/Navigate/Navigate';

const ReservationsThanksPage = () => {
	return (
		<div className={styles.container}>
			<Navigate to={ROUTES.CO_WORKINGS} timeout={3000} />
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<Logo />
				</div>
				<div className={styles.content}>
					<Thanks />
					<h1 className={styles.title}>
						<span>À</span> très vite dans nos espaces
					</h1>
					<p className={styles.info}>
						Nous espérons que vous avez passé un bon moment dans notre espace.
						N&apos;hésitez pas à nous laisser votre avis sur votre réservation,
						c&apos;est précieux pour nous !
					</p>
					<p className={styles.merci}>Merci de nous avoir choisis !</p>
				</div>
				<div className={styles.background} />
				<Link to={ROUTES.CO_WORKINGS} className={styles.footer}>
					Retour à la page principale
				</Link>
			</div>
		</div>
	);
};

export default ReservationsThanksPage;
