import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Rnd, Props } from 'react-rnd';
import classNames from 'classnames';

import styles from './TimeSlots.module.scss';

import { DAY_END, QUARTER_HEIGHT } from './config';
import { isTimeSlotDisabled } from './lib';
import { setZeroBefore } from '../../utils/setZeroBefore';
import { MinutesTimeRange } from '../../network/types';
import { formatMinutesRangeAsTime } from '../../utils/formatTime';
import ActivityIndicator from '../common/ActivityIndicator/ActivityIndicator';

export type TimeSlotsProps = {
	className?: string;
	timeSlot?: MinutesTimeRange;
	loading?: boolean;
	disabled: boolean;
	availableSlots: MinutesTimeRange[];
	onSelect: (timeSlot: MinutesTimeRange) => void;
};

const SLOTS = Array.from({ length: 24 }).reduce<{ offset: number; time: string; hour: boolean }[]>(
	(list, _, hourIndex) => {
		const hourOff = hourIndex * 60;
		const hourStr = setZeroBefore(hourIndex);
		list.push({ offset: hourOff + 0, time: `${hourStr}:00`, hour: true });
		list.push({ offset: hourOff + 15, time: `${hourStr}:15`, hour: false });
		list.push({ offset: hourOff + 30, time: `${hourStr}:30`, hour: false });
		list.push({ offset: hourOff + 45, time: `${hourStr}:45`, hour: false });
		return list;
	},
	[]
);

export const TimeSlots = ({
	className,
	timeSlot,
	loading = false,
	disabled = false,
	availableSlots,
	onSelect,
}: TimeSlotsProps) => {
	const timeSlotRef = useRef<MinutesTimeRange>();
	timeSlotRef.current = timeSlot;

	const slots = useMemo(() => {
		return SLOTS.map(slot => ({
			...slot,
			disabled:
				loading ||
				isTimeSlotDisabled({ from: slot.offset, to: slot.offset + 15 }, availableSlots),
		}));
	}, [availableSlots, loading]);

	const onDragStop = useCallback<Props['onDragStop']>(
		(event, drag) => {
			const height = timeSlotRef.current.to - timeSlotRef.current.from;
			const from = Math.round(drag.lastY / QUARTER_HEIGHT) * 15;

			onSelect({ from, to: from + height });
		},
		[onSelect]
	);
	const onResizeStop = useCallback<Props['onResize']>(
		(event, direction, node, delta, position) => {
			const from = Math.round(position.y / QUARTER_HEIGHT) * 15;
			const height =
				timeSlotRef.current.to -
				timeSlotRef.current.from +
				(delta.height / QUARTER_HEIGHT) * 15;

			onSelect({ from, to: from + height });
		},
		[onSelect]
	);

	const rndRef = useRef<Rnd>(null);
	useEffect(() => {
		rndRef.current?.getSelfElement().scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
	}, [timeSlot]);

	return (
		<div className={classNames(styles.root, className)}>
			<div className={styles.root__scroll}>
				<div className={styles.root__inner}>
					<div className={styles.list}>
						{slots.map(slot => (
							<div
								key={slot.time}
								className={classNames(
									styles.item,
									slot.hour && styles['item__hour'],
									slot.disabled && styles['disabled-time']
								)}
								onClick={() => {
									if (slot.disabled) return;
									const height = timeSlot.to - timeSlot.from;
									onSelect({
										from: slot.offset,
										to: Math.min(slot.offset + height, DAY_END),
									});
								}}
							>
								<div className={styles.item__time}>{slot.time}</div>
							</div>
						))}
						<div className={styles.item_last}>
							<div className={styles.item__time}>24:00</div>
						</div>
					</div>
					<Rnd
						ref={rndRef}
						dragAxis="y"
						resizeGrid={[1, QUARTER_HEIGHT]}
						dragGrid={[1, QUARTER_HEIGHT]}
						position={{ x: 0, y: (timeSlot.from / 15) * QUARTER_HEIGHT }}
						size={{
							width: '100%',
							height: ((timeSlot.to - timeSlot.from) / 15) * QUARTER_HEIGHT,
						}}
						onDragStop={onDragStop}
						onResizeStop={onResizeStop}
						minWidth="100%"
						resizeHandleWrapperClass={styles.resizeHandleWrapper}
						className={classNames(
							styles.selection_container,
							loading && styles.selection_container_loading
						)}
						minHeight={QUARTER_HEIGHT}
						bounds="parent"
						enableResizing={{ top: true, bottom: true }}
					>
						<div
							className={classNames(
								styles.selection,
								disabled && styles['selection--disabled']
							)}
						>
							<div className={styles.selection__time}>
								Créneau sélectionné &nbsp;|&nbsp;
								{formatMinutesRangeAsTime(timeSlot)}
							</div>
						</div>
					</Rnd>
				</div>
			</div>
			{loading ? <ActivityIndicator absolute background /> : null}
		</div>
	);
};
