import styles from './Wrapper.module.scss';

type WrapperProps = {
	children: JSX.Element[] | JSX.Element;
};

const Wrapper = ({ children }: WrapperProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>{children}</div>
		</div>
	);
};

export default Wrapper;
