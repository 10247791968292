import FacebookLogin from '@greatsumini/react-facebook-login';
import { ReactComponent as Facebook } from '../../../assets/socials/facebook.svg';
import { ReactComponent as Google } from '../../../assets/socials/google.svg';
import { ReactComponent as Linkedin } from '../../../assets/socials/linkedin.svg';
import styles from './LoginSocials.module.scss';
import { ROUTES } from '../../../config/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { Method, request } from '../../../network/request';
import { FACEBOOK_LOGIN } from '../../../config/endpoints';
import { useDispatch } from 'react-redux';
import { setToken } from '../../../redux/slice/userSlice';

const LoginSocials = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const from = location.state?.from;

	const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
	const linkedinClientId = process.env.REACT_APP_LINKEDIN_APP_ID;
	const googleClientId = process.env.REACT_APP_GOOGLE_APP_ID;

	const GOOGLE_AUTH = `https://accounts.google.com/o/oauth2/auth?client_id=${googleClientId}&redirect_uri=${window.location.origin}${ROUTES.GOOGLE_CALLBACK_PAGE}&scope=openid+profile+email&response_type=code`;
	const LINKEDIN_AUTH = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedinClientId}&redirect_uri=${window.location.origin}${ROUTES.LINKEDIN_CALLBACK_PAGE}&scope=openid,profile,email`;

	return (
		<>
			<div className={styles.login__buttons}>
				<FacebookLogin
					appId={facebookAppId}
					scope="public_profile,email"
					onSuccess={response => {
						const token = response.accessToken;
						request(FACEBOOK_LOGIN, { method: Method.POST, data: { token } })
							.then(res => {
								if (res.data.access_token) {
									dispatch(setToken(res.data.access_token, { navigate, from }));
								}
							})
							.catch(e => {
								if (e?.response?.unfilledData.includes('email')) {
									localStorage.setItem('fbToken', token);
									navigate(ROUTES.LOGIN_EMAIL, { state: { from } });
								}
							});
					}}
					onFail={() => {
						navigate(ROUTES.CO_WORKINGS);
					}}
					render={({ onClick }) => (
						<button type="button" onClick={onClick}>
							<Facebook />
						</button>
					)}
				/>
				<button
					type="button"
					onClick={() => {
						localStorage.setItem('from', from);
						window.location.replace(GOOGLE_AUTH);
					}}
				>
					<Google />
				</button>
				<button
					type="button"
					onClick={() => {
						localStorage.setItem('from', from);
						window.location.replace(LINKEDIN_AUTH);
					}}
				>
					<Linkedin />
				</button>
			</div>
		</>
	);
};

export default LoginSocials;
