import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './ButtonIcon.module.scss';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'children'> & {
	text: ReactNode;
	icon?: ReactNode;
	iconProps?: JSX.IntrinsicElements['div'];
};

const ButtonIcon = ({
	text = '',
	icon = null,
	iconProps,
	className,
	...buttonProps
}: ButtonProps) => {
	return (
		<button
			type="button"
			{...buttonProps}
			className={cn(styles.button, buttonProps.disabled && styles.disabled, className)}
		>
			<div {...iconProps} className={cn(styles.icon, iconProps?.className)}>
				{icon}
			</div>
			{text}
		</button>
	);
};

export default ButtonIcon;
