export type SimpleEvent<NT extends object> = {
	nativeEvent: NT;
	preventDefault(): void;
	isDefaultPrevented(): boolean;
};

export default function createEvent<NT extends object>(nativeEvent: NT): SimpleEvent<NT> {
	let prevented = false;
	return {
		nativeEvent,
		isDefaultPrevented: () => prevented,
		preventDefault: () => {
			prevented = true;
		},
	};
}
