import { RefObject, UIEventHandler, useCallback, useEffect, useMemo, useRef } from 'react';

import createEvent, { SimpleEvent } from '../utils/createEvent';
import { useResizeObserver } from './useResizeObserver';
import { useStableCallback } from './useStableCallback';

export type EndReachedEvent<E extends HTMLElement> = SimpleEvent<{
	currentTarget: E;
	scrollTop: number;
	scrollDirection: 'top' | 'bottom';
}>;
export type EndReachedHandler<E extends HTMLElement> = (event: EndReachedEvent<E>) => void;

export function useEndReached<E extends HTMLElement>(
	ref: RefObject<E | null>,
	onEndReached: EndReachedHandler<E>,
	endThreshold = 0.5,
	direction: 'top' | 'bottom' = 'bottom'
) {
	const lastScrollTop = useRef(0);
	const detectElementEnd = useCallback(
		(target: E) => {
			const { scrollTop, scrollHeight, clientHeight } = target;
			const endCoefficient = (scrollHeight - scrollTop - clientHeight * 2) / clientHeight;
			const scrollDirection =
				scrollTop > lastScrollTop.current
					? 'bottom'
					: scrollTop < lastScrollTop.current
					? 'top'
					: undefined;
			lastScrollTop.current = scrollTop;

			if (endCoefficient > endThreshold) {
				return undefined;
			}
			if (scrollDirection !== undefined && scrollDirection !== direction) {
				return undefined;
			}

			const endEvent: EndReachedEvent<E> = createEvent({
				currentTarget: target,
				scrollTop,
				scrollDirection,
			});

			return endEvent;
		},
		[direction, endThreshold]
	);
	const stableOnEndReached = useStableCallback(onEndReached);
	const detectElementEndThrottled = useMemo<typeof detectElementEnd>(() => {
		let timeout: ReturnType<typeof setTimeout> | undefined;
		const throttle = () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				timeout = undefined;
			}, 100);
		};
		return target => {
			const endEvent = detectElementEnd(target);
			if (!endEvent) return undefined;
			if (timeout === undefined) {
				stableOnEndReached(endEvent);
			}
			throttle();
			return endEvent;
		};
	}, [detectElementEnd, stableOnEndReached]);

	const onScroll = useCallback<UIEventHandler<E>>(
		event => {
			detectElementEndThrottled(event.currentTarget);
		},
		[detectElementEndThrottled]
	);

	useResizeObserver()<E>(ref, entry => {
		detectElementEndThrottled(entry.target);
	});

	return { onScroll };
}
