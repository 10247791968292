import { ComponentProps, useCallback, useRef, useState } from 'react';

import { ConfirmationModal } from './ConfirmationModal';

type ConfirmationModalProps = ComponentProps<typeof ConfirmationModal>;
type ControlledProps = Pick<ConfirmationModalProps, 'modalVisible' | 'onConfirm' | 'onCancel'>;

export function useConfirmationModal<As extends any[]>(
	action: (...args: As) => Promise<void>,
	modalPropsOrRender:
		| Omit<ConfirmationModalProps, keyof ControlledProps>
		| { (props: ControlledProps): JSX.Element }
) {
	const [modalVisible, setModalVisible] = useState(false);
	const deferred = useRef<{
		args: As;
		resolve: () => void;
		reject: (error: unknown) => void;
	} | null>(null);

	const handleClick = useCallback((...args: As) => {
		setModalVisible(true);
		return new Promise<void>((resolve, reject) => {
			deferred.current = { args, resolve, reject };
		});
	}, []);

	const handleConfirm = useCallback(async () => {
		try {
			setModalVisible(false);
			await action(...deferred.current.args);
		} finally {
			deferred.current.resolve();
		}
	}, [action]);
	const handleCancel = useCallback(async () => {
		deferred.current.reject(new Error('CanceledError: this action was canceled'));
		setModalVisible(false);
	}, []);

	const modal =
		typeof modalPropsOrRender === 'function' ? (
			modalPropsOrRender({ modalVisible, onConfirm: handleConfirm, onCancel: handleCancel })
		) : (
			<ConfirmationModal
				{...modalPropsOrRender}
				modalVisible={modalVisible}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		);

	return [{ onClick: handleClick }, modal] as const;
}
