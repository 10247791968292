import { LoginProps, LoginSteps } from '../../pages/login/types';
import { LoginForm } from './LoginForm';
import { RecoveryForm } from './RecoveryForm';

const Login = ({ loginStep, setLoginStep }: LoginProps) => {
	const loginSteps = () => {
		switch (loginStep) {
			case LoginSteps.LOGIN:
				return (
					<LoginForm onRecoverPassword={() => setLoginStep(LoginSteps.FORGOT_PASSWORD)} />
				);
			case LoginSteps.FORGOT_PASSWORD:
				return <RecoveryForm />;
		}
	};

	return loginSteps();
};

export default Login;
