import classNames from 'classnames';
import { useCallback, useState } from 'react';

import styles from './Image.module.scss';

type ImageProps = JSX.IntrinsicElements['img'];

export function Image({ onError, children, ...imgProps }: ImageProps) {
	const [hasError, setHasError] = useState(false);
	const handleError = useCallback<typeof onError>(
		event => {
			onError?.(event);
			if (event.isDefaultPrevented()) {
				return;
			}
			setHasError(true);
		},
		[onError]
	);

	if (hasError) {
		return (
			<img
				{...imgProps}
				{...{ src: undefined, srcSet: undefined }}
				style={{ ...imgProps.style, width: imgProps.width, height: imgProps.height }}
				className={classNames(imgProps.className, styles.error)}
			/>
		);
	}
	return (
		<img {...imgProps} onError={handleError}>
			{children}
		</img>
	);
}
