import { ReactNode, useCallback } from 'react';

import { useCancelableActionCallback } from '../../../hooks/useActionCallback';
import { AsyncState } from '../../../hooks/useAsyncState';

export type ActionCb<T> = (signal: AbortSignal) => Promise<T>;

export type ActionProps<T, E extends { isDefaultPrevented(): boolean }> = {
	action?: ActionCb<T>;
	onClick?: (event: E) => void;
	onResolved?: (value: T) => void;
	onRejected?: (error: unknown) => void;
	children: (state: AsyncState<T>, onClick: (event: E) => void) => ReactNode;
};

export function Action<T, E extends { isDefaultPrevented(): boolean }>({
	action,
	onClick,
	onResolved,
	onRejected,
	children,
}: ActionProps<T, E>) {
	const [runAction, actionState] = useCancelableActionCallback(signal => () => action(signal));

	const handleClick = useCallback(
		(event: E) => {
			onClick?.(event);
			if (event.isDefaultPrevented()) return;
			runAction().then(onResolved, onRejected);
		},
		[onClick, onRejected, onResolved, runAction]
	);

	return <>{children(actionState, handleClick)}</>;
}
