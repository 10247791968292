import { useEffect } from 'react';

import Preloader from '../components/common/Preloader/Preloader';
import { ROUTES } from '../config/routes';

export const ErrorPage = () => {
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			window.location.replace(ROUTES.FAQ);
		}, 1000);
		return () => clearTimeout(timeoutId);
	}, []);
	return <Preloader />;
};
